import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import useAxios from 'axios-hooks'
import Popup from "reactjs-popup"
import { useFormik } from 'formik'
import * as Yup from 'yup'

var md5 = require('md5')


const validationSchema = Yup.object( {
  first: Yup.string()
    .min(3)
    .max(20)
    .required('Required'),
  last: Yup.string()
    .min(3)
    .max(20)
    .required('Required'),
  uname: Yup.string()
    .min(3)
    .max(20)
    .required('Required'),
  email: Yup.string()
    .email('Invalid email format')
    .required('Required'),
  password: Yup.string()
    .min(3)
    .max(20)
    .required('Required'),
  favorite: Yup.string().required('Required'),
} )

const Profile = ( { userInfo } ) => {

  //console.log("userInfo", userInfo)

  const [schools, setSchools] = useState([])
  const [users, setUsers] = useState([])
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      first: userInfo.ufirst,
      last: userInfo.ulast,
      uname: userInfo.uname,
      email: userInfo.uemail,
      password: '',
      favorite: userInfo.ufavorite
    },
    onSubmit: values => {
      //console.log("submitted", values)
    
      // First make sure username isn't taken already
      var taken=false
      for(var i = 0; i < users.length; i++) {
        //console.log("users match", users, users[i].user_id, userInfo.uid)
        if(values.uname === users[i].user_name && users[i].user_id !== userInfo.uid) {
          //console.log("users match", users[i].username)
          taken=true

        } else {
          //console.log("not taken")
        }
      }

      if (taken===false) {

        let codepass = md5(values.password)
          
        async function getPost() {
          try {
            const response = await getUserInfo( { params: { first: values.first, last: values.last, uname: values.uname, email: values.email, favorite: values.favorite, password: codepass, uid: userInfo.uid } })
            //console.log("returned response", response)
            //console.log("newID", newID)
            setMessage("Profile updated successfully")
            setAdded(true)
          } catch (e) {
            console.log("error in getPost Profile", e)
          }
        }    
        getPost()
        
      } else {
        setMessage("User Name is unavailable")
        setWarning(true)
      }
      
    },
    validationSchema
  })

  const [added, setAdded] = useState(false);
  const [warning, setWarning] = useState(false);
  const [message, setMessage] = useState('');

  const Warning = () => {
    return(
      <Popup
        open={warning}
        className="addplayer-popup"
        onClose={() => setWarning(false)} 
        modal
      >
        {close => (
          <div className="fcmodal">
            <button className="close" onClick={close}>
              X
            </button>
            <div className="header cRed bold">ERROR</div>
            <div className="content">
              {' '}
              <span className="nooutline cyellow">{message}</span>
            </div>
          </div>
        )}
      </Popup>
    )
  }

  const Added = () => {
    return(
      <Popup
        open={added}
        className="addplayer-popup"
        onClose={() => navigate("/#/")} 
        modal
      >
        {close => (
          <div className="fcmodal">
            <button className="close" onClick={close}>
              &times;
            </button>
            <div className="header cdeepskyblue bold">UPDATED</div>
            <div className="content cdeepskyblue">
              {' '}
              <span className="nooutline">{message}</span>
            </div>
          </div>
        )}
      </Popup>
    )
  }

  const [{ data: usernameData, loading: isPending3, error: error3 }, getNames] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/usernameCheck`
    },
    { 
      manual: true,
      userCache: false
    }
  )

  const getUsernames = () => {
    async function getPost() {
      try {
        const response = await getNames()
        //console.log("username response", response.data)
        setUsers(response.data)

      } catch (e) {
        console.log("error in username of profile", error)
      }
    }    
    getPost()

    async function getSchoolData() {
      try {
        const response = await getSchools()
        //console.log("response", response)
        setSchools(response.data)

      } catch (e) {
        console.log("getSchools error called", error)
      }
    }    
    getSchoolData()
  }

  const [{ data: userData, loading: isPending, error: error }, getUserInfo] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/user/updateprofile`,
      method: 'POST'
    },
    { 
      manual: true,
      userCache: false
    }
  )

  const [{ data: schoolData, loading: getLoading, error: getError }, getSchools] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/getschools`,
    },
    {
      manual: true,
      useCache:false
    }
  )


  useEffect(() => {
    getUsernames()
  }, []);

  if(schools.length < 1) {
    return(<div>LOADING...</div>)
  } else {
    return (

      <div>
        <Warning/>
        <Added/>

        <form id="loginform" className="row g-3 needs-validation" onSubmit={formik.handleSubmit}>
         
          <div className="column">
            <div className="form-control">
              <label htmlFor="floatingInput">First Name</label>
              <input
                type="text"
                className="form-control"
                id="floatingInput"
                value={formik.values.first}
                placeholder="First Name"
                name="first"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.first && formik.errors.first ? <div className='errors'>{formik.errors.first}</div> : null}
            </div>
            <div className="form-control">
              <label htmlFor="floatingInput">Password</label>
              <input
                type="text"
                className="form-control"
                id="floatingInput"
                value={formik.values.password}
                placeholder="Password"
                name="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.password && formik.errors.password ? <div className='errors'>{formik.errors.password}</div> : null}
            </div>
          </div>
          

          <div className="column">
            <div className="form-control">
              <label htmlFor="floatingInput">Last Name</label>
              <input
                type="text"
                className="form-control"
                id="floatingInput"
                value={formik.values.last}
                placeholder="Last Name"
                name="last"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.last && formik.errors.last ? <div className='errors'>{formik.errors.last}</div> : null}
            </div>
            <div className="form-control">
              <label htmlFor="floatingInput">Email</label>
              <input
                type="email"
                className="form-control"
                id="floatingInput"
                value={formik.values.email}
                placeholder="email@email.com"
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.email && formik.errors.email ? <div className='errors'>{formik.errors.email}</div> : null}
            </div>
          </div>

          <div className="column">
            <div className="form-control">
              <label htmlFor="floatingInput">User Name</label>
              <input 
                type="text"
                className="form-control"
                id="floatingInput"
                value={formik.values.uname}
                placeholder="User Name"
                name="uname"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.uname && formik.errors.uname ? <div className='errors'>{formik.errors.uname}</div> : null}
            </div>
            <div className="form-control">
              <label htmlFor="floatingInput">Favorite Team</label>
              <select 
                className="form-control"
                name="favorite"
                value={formik.values.favorite}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                {schools.map(({school}, index) => (
                <option key={index} value={index}>{school}</option>
                ))}
              </select>
              {formik.touched.favorite && formik.errors.favorite ? <div className='errors'>{formik.errors.favorite}</div> : null}
            </div>
          </div>
          
          
          <div className="col-12 d-grid">
            <button className="btn btn-primary" type="submit">Submit</button>
          </div>
        </form>
      </div>
    )
  }
}

export default Profile