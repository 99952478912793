import React, { useState, useEffect } from 'react'
import useAxios from "axios-hooks";
import PredictionTable from "./PredictionTable"
import Popup from 'reactjs-popup'

const Predictions = ({serverInfo}) => {

  const server=serverInfo.server
  const week=serverInfo.week
  const reveal=serverInfo.reveal
  const season=serverInfo.season

  //console.log("serverinfo", serverInfo, reveal, season)

  const [matchups, setMatchupsData] = useState([]);
  const [images, setImagesData] = useState([]);
  const [submitted, setSubmittedData] = useState([]);
  const [predictions, setPredictionsData] = useState([]);


  const [{ data: matchupsData, loading: mPending, error: mError }, getMatchups] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/findmatchups`,
      method: 'POST',
      params: { 
        reveal: reveal,
        week: week,
        season: season
      }
    },
    {
      manual: true,
      useCache:false
    }
  )

  const [{ data: submittedData, loading: isPending2, error: sError }, getSubmitted] = useAxios( {
    url: `${process.env.REACT_APP_HOST}/submitters`,
    method: 'POST'
    },
    {
      manual: true,
      useCache:false
    }
  )

  const [ { data: predictionsData, loading: isPending, error: pError }, getPredictions ] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/prediction`,
      method: 'POST',
      params: { 
        reveal: reveal,
        week: week,
        season: season
      }
    },
    {
      manual: true,
      useCache: false
    }
  )

  const [{ data: imagesData, loading: iPending, error: iError }, getImages] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/images`,
    },
    {
      manual: true,
      useCache:false
    }
  )

  const setup = () => {

    async function getPost1() {
      try {
        const response = await getMatchups()
        //console.log("response", response.data)
        setMatchupsData(response.data)

        async function getPost2() {
          try {
            const response2 = await getImages()
            //console.log("response2", response2.data)
            setImagesData(response2.data)

            async function getPost3() {
              try {
                const response3 = await getPredictions()
                //console.log("response3", response3.data)
                setPredictionsData(response3.data)

                async function getPost4() {
                  try {
                    const response4 = await getSubmitted()
                    //console.log("response4", response4.data)
                    setSubmittedData(response4.data)
            
                  } catch (e) {
                    console.log("error in predictions4", sError)
                  }
                }
                getPost4()

              } catch (e) {
                console.log("error in predictions3", pError)
              }
            }
            getPost3()
    
          } catch (e) {
            console.log("error in predictions2", iError)
          }
        }
        getPost2()

      } catch (e) {
        console.log("error in predictions1", mError)
      }
    }
    getPost1()

  }

  useEffect(() => {
    setup()
  }, [serverInfo]);


  const ToolTip = ({team, src}) => {
    //console.log("team name and logo", team, src)
    return (
      <Popup
        trigger={
          <img className="pimg" src={src} alt='Logo' />
        }
        className="team-popup"
        position='bottom center'
        on={['click']}
        //arrow={position !== 'center center'}
      ><span>{team}</span>
      </Popup>
    );
  }

  const columnsLogo = React.useMemo(
    () => [
      {
        Header: '',
        accessor: 'user_name',
        minWidth: 65,
        width: 84,
        style: {
          textAlign: 'right',
          fontWeight: 500,
        },
      },
      {
        Header: (props) => {
          //console.log("matchups", props.idata, props.mdata)
          return(
            props.mdata.length===0 || props.idata.length===0 ?
              <>Gm 1</>
              :
              <>
                <div><img className="pimgSM" src={process.env.PUBLIC_URL + props.idata[props.mdata[0].teamA].imgID} alt='Logo' /></div>
                <div className="tcenter">vs</div>
                <div><img className="pimgSM" src={process.env.PUBLIC_URL + props.idata[props.mdata[0].teamH].imgID} alt='Logo' /></div>
              </>

          )
        },
        accessor: 'img0',
        minWidth: 28,
        width: 28,
        Cell: props => {
          //console.log("props", props.row.original)
          return(
            <ToolTip team={props.row.original.pick0} src={process.env.PUBLIC_URL + props.row.original.img0}/>
          )
        }
      },
      {
        Header: (props) => {
          return(
            props.mdata.length===0 || props.idata.length===0 ?
              <>Gm 2</>
              :
              <>
                <div><img className="pimgSM" src={process.env.PUBLIC_URL + props.idata[props.mdata[1].teamA].imgID} alt='Logo' /></div>
                <div className="tcenter">vs</div>
                <div><img className="pimgSM" src={process.env.PUBLIC_URL + props.idata[props.mdata[1].teamH].imgID} alt='Logo' /></div>
              </>

          )
        },
        accessor: 'img1',
        minWidth: 28,
        width: 28,
        Cell: props => {
          return(
            <ToolTip team={props.row.original.pick1} src={process.env.PUBLIC_URL + props.row.original.img1} alt='Logo' />
          )
        }
      },
      {
        Header: (props) => {
          return(
            props.mdata.length===0 || props.idata.length===0 ?
              <>Gm 3</>
              :
              <>
                <div><img className="pimgSM" src={process.env.PUBLIC_URL + props.idata[props.mdata[2].teamA].imgID} alt='Logo' /></div>
                <div className="tcenter">vs</div>
                <div><img className="pimgSM" src={process.env.PUBLIC_URL + props.idata[props.mdata[2].teamH].imgID} alt='Logo' /></div>
              </>

          )
        },
        accessor: 'img2',
        minWidth: 28,
        width: 28,
        Cell: props => {
          return(
            <ToolTip team={props.row.original.pick2} src={process.env.PUBLIC_URL + props.row.original.img2} alt='Logo' />
          )
        }
      },
      {
        Header: (props) => {
          return(
            props.mdata.length===0 || props.idata.length===0 ?
              <>Gm 4</>
              :
              <>
                <div><img className="pimgSM" src={process.env.PUBLIC_URL + props.idata[props.mdata[3].teamA].imgID} alt='Logo' /></div>
                <div className="tcenter">vs</div>
                <div><img className="pimgSM" src={process.env.PUBLIC_URL + props.idata[props.mdata[3].teamH].imgID} alt='Logo' /></div>
              </>

          )
        },
        accessor: 'img3',
        minWidth: 28,
        width: 28,
        Cell: props => {
          return(
            <ToolTip team={props.row.original.pick3} src={process.env.PUBLIC_URL + props.row.original.img3} alt='Logo' />
          )
        }
      },
      {
        Header: (props) => {
          return(
            props.mdata.length===0 || props.idata.length===0 ?
              <>Gm 5</>
              :
              <>
                <div><img className="pimgSM" src={process.env.PUBLIC_URL + props.idata[props.mdata[4].teamA].imgID} alt='Logo' /></div>
                <div className="tcenter">vs</div>
                <div><img className="pimgSM" src={process.env.PUBLIC_URL + props.idata[props.mdata[4].teamH].imgID} alt='Logo' /></div>
              </>

          )
        },
        accessor: 'img4',
        minWidth: 28,
        width: 28,
        Cell: props => {
          return(
            <ToolTip team={props.row.original.pick4} src={process.env.PUBLIC_URL + props.row.original.img4} alt='Logo' />
          )
        }
      },
      {
        Header: (props) => {
          return(
            props.mdata.length===0 || props.idata.length===0 ?
              <>Gm 6</>
              :
              <>
                <div><img className="pimgSM" src={process.env.PUBLIC_URL + props.idata[props.mdata[5].teamA].imgID} alt='Logo' /></div>
                <div className="tcenter">vs</div>
                <div><img className="pimgSM" src={process.env.PUBLIC_URL + props.idata[props.mdata[5].teamH].imgID} alt='Logo' /></div>
              </>

          )
        },
        accessor: 'img5',
        minWidth: 28,
        width: 28,
        Cell: props => {
          return(
            <ToolTip team={props.row.original.pick5} src={process.env.PUBLIC_URL + props.row.original.img5} alt='Logo' />
          )
        }
      },
      {
        Header: (props) => {
          return(
            props.mdata.length===0 || props.idata.length===0 ?
              <>Gm 7</>
              :
              <>
                <div><img className="pimgSM" src={process.env.PUBLIC_URL + props.idata[props.mdata[6].teamA].imgID} alt='Logo' /></div>
                <div className="tcenter">vs</div>
                <div><img className="pimgSM" src={process.env.PUBLIC_URL + props.idata[props.mdata[6].teamH].imgID} alt='Logo' /></div>
              </>

          )
        },
        accessor: 'img6',
        minWidth: 28,
        width: 28,
        Cell: props => {
          return(
            <ToolTip team={props.row.original.pick6} src={process.env.PUBLIC_URL + props.row.original.img6} alt='Logo' />
          )
        }
      },
      {
        Header: (props) => {
          return(
            props.mdata.length===0 || props.idata.length===0 ?
              <>Gm 8</>
              :
              <>
                <div><img className="pimgSM" src={process.env.PUBLIC_URL + props.idata[props.mdata[7].teamA].imgID} alt='Logo' /></div>
                <div className="tcenter">vs</div>
                <div><img className="pimgSM" src={process.env.PUBLIC_URL + props.idata[props.mdata[7].teamH].imgID} alt='Logo' /></div>
              </>

          )
        },
        accessor: 'img7',
        minWidth: 28,
        width: 28,
        Cell: props => {
          return(
            <ToolTip team={props.row.original.pick7} src={process.env.PUBLIC_URL + props.row.original.img7} alt='Logo' />
          )
        }
      },
      {
        Header: (props) => {
          return(
            props.mdata.length===0 || props.idata.length===0 ?
              <>Gm 9</>
              :
              <>
                <div><img className="pimgSM" src={process.env.PUBLIC_URL + props.idata[props.mdata[8].teamA].imgID} alt='Logo' /></div>
                <div className="tcenter">vs</div>
                <div><img className="pimgSM" src={process.env.PUBLIC_URL + props.idata[props.mdata[8].teamH].imgID} alt='Logo' /></div>
              </>

          )
        },
        accessor: 'img8',
        minWidth: 28,
        width: 28,
        Cell: props => {
          return(
            <ToolTip team={props.row.original.pick8} src={process.env.PUBLIC_URL + props.row.original.img8} alt='Logo' />
          )
        }
          
      },
      {
        Header: (props) => {
          return(
            props.mdata.length===0 || props.idata.length===0 ?
              <>Gm 10</>
              :
              <>
                <div><img className="pimgSM" src={process.env.PUBLIC_URL + props.idata[props.mdata[9].teamA].imgID} alt='Logo' /></div>
                <div className="tcenter">vs</div>
                <div><img className="pimgSM" src={process.env.PUBLIC_URL + props.idata[props.mdata[9].teamH].imgID} alt='Logo' /></div>
              </>

          )
        },
        accessor: 'img9',
        minWidth: 28,
        width: 28,
        Cell: props => {
          return(
            <ToolTip team={props.row.original.pick9} src={process.env.PUBLIC_URL + props.row.original.img9} alt='Logo' />
          )
        }
          
      }
    ],
    []
  )

  if(week===0) {
    console.log("week", {week})
    return (
      <div>Predictions will be shown shortly after prediction deadline expires.</div>
    )
  } else {
    if(server==="LOCKED") {
      return (
        <div>Predictions will be available shortly</div>
      )
    } else if(server==="CLOSED"  || server==="OPEN") {

      return (
        <div className="predictionTable">
          <div className="tableText">*Games in <span style={{color: "red", backgroundColor: "rgba(255,0,0,.15)"}}>RED</span> denote confidence picks.</div>

          <div className="Logos">
            {/* { error && <div>{ error }</div> } */}
            {/* {console.log("data",matchups, images, submitted, predictions)} */}
            { matchups.length === 0 || images.length === 0 || predictions.length === 0 ? <div>Loading...</div> :
              predictions && < PredictionTable
              columns={columnsLogo}
              data={predictions}
              submitters={submitted}
              idata={images}
              mdata={matchups}
              server={server}   
            /> }
          </div>
        </div>
      )
    }
  }
} 

export default Predictions