import React from 'react'
import useAxios from 'axios-hooks'
import WinnersTable from "./WinnersTable"

const Winners = () => {

  const [{ data: matchups, loading: isPending, error }] = useAxios( {
    url: `${process.env.REACT_APP_HOST}/matchup`
    },
    {
      useCache:false
    }
  )

  const columns = React.useMemo(
    () => [
      {
        Header: "This Week's Games",
        hstyle: {
          fontSize: '20px',
          textAlign: 'center',
          height: '40px',
          backgroundColor: 'black',
          color: 'white',
        },
        columns: [
          {
            Header: '',
            columnId: 1,
            hstyle: {
              height: '0px',
              backgroundColor: 'black',
            },
            accessor: 'teamA',
            Cell: props => {
              return(
                <button id="buttonB" className="submitAway" value={props.row.original.teamA} >
                  <img className="imgA pright2" src={process.env.PUBLIC_URL + props.row.original.imgA} height='14px' alt='Logo' />
                  <span className="teamA">{props.row.original.teamAn}</span>
                </button>
              )
            }
          },
          {
            Header: '',
            hstyle: {
              height: '0px',
              backgroundColor: 'black',
            },
            accessor: 'location',
          },
          {
            Header: '',
            columnId: 2,
            hstyle: {
              height: '0px',
              backgroundColor: 'black',
            },
            accessor: 'teamH',
            Cell: props => {
              return(
                <button id="buttonB" className="submitHome" value={props.row.original.teamH} >
                  <img className="imgH pright2" src={process.env.PUBLIC_URL + props.row.original.imgH} height='14px' alt='Logo' />
                  <span className="teamH">{props.row.original.teamHn}</span>
                </button>
              )
            }
          }
        ],
      },
    ],
    []
  )

  


  return (
    <div className="MatchupsTable">
      { error && <div>{ error }</div> }
      { isPending ? <div>Loading...</div> : <WinnersTable
        columns={columns}
        data={matchups}
      /> }
    </div>
  )
}

export default Winners