import React from 'react'

function CFDGameStatsTable({ data }) {

  var tstat = [{
    conference: data[0].teams[0].conference,
    homeAway: data[0].teams[0].homeAway,
    points: data[0].teams[0].points,
    school: data[0].teams[0].school,
    yardsPerPass: 0,
    rushingYards: 0,
    netPassingYards: 0,
    totalYards: 0,
    completionAttempts: 0,
    totalPenaltiesYards: 0,
    firstDowns: 0,
    possessionTime: 0,
    yardsPerRushAttempt: 0,
    turnovers: 0,
    rushingAttempts: 0,
    fumblesLost: 0,
    interceptions: 0,
    thirdDownEff: 0,
    fourthDownEff: 0,
    passesDeflected: 0,
    qbHurries: 0,
    sacks: 0,
    tackles: 0,
    defensiveTDs: 0,
    tacklesForLoss: 0,
    fumblesRecovered: 0,
    passesIntercepted: 0,
    interceptionTDs: 0,
    interceptionYards: 0,
    kickingPoints: 0,
    kickReturns: 0,
    kickReturnTDs: 0,
    kickReturnYards: 0,
    passingTDs: 0,
    passingTDs: 0,
    puntReturnTDs: 0,
    puntReturnYards: 0,
    rushingTDs: 0
  },
  {
    conference: data[0].teams[1].conference,
    homeAway: data[0].teams[1].homeAway,
    points: data[0].teams[1].points,
    school: data[0].teams[1].school,
    yardsPerPass: 0,
    rushingYards: 0,
    netPassingYards: 0,
    totalYards: 0,
    completionAttempts: 0,
    totalPenaltiesYards: 0,
    firstDowns: 0,
    possessionTime: 0,
    yardsPerRushAttempt: 0,
    turnovers: 0,
    rushingAttempts: 0,
    fumblesLost: 0,
    interceptions: 0,
    thirdDownEff: 0,
    fourthDownEff: 0,
    passesDeflected: 0,
    qbHurries: 0,
    sacks: 0,
    tackles: 0,
    defensiveTDs: 0,
    tacklesForLoss: 0,
    fumblesRecovered: 0,
    passesIntercepted: 0,
    interceptionTDs: 0,
    interceptionYards: 0,
    kickingPoints: 0,
    kickReturns: 0,
    kickReturnTDs: 0,
    kickReturnYards: 0,
    passingTDs: 0,
    passingTDs: 0,
    puntReturnTDs: 0,
    puntReturnYards: 0,
    rushingTDs: 0
  }]

  //console.log("data: ", data)

  // Render the UI for your table
  return (
    <div id="gameStats">
      {data[0].teams[0].stats.map((team1) => {
        switch(team1.category) {
          case 'yardsPerPass':
            tstat[0].yardsPerPass=team1.stat
            return
          case 'rushingYards':
            tstat[0].rushingYards=team1.stat
            return
          case 'netPassingYards':
            tstat[0].netPassingYards=team1.stat
            return
          case 'totalYards':
            tstat[0].totalYards=team1.stat
            return
          case 'completionAttempts':
            tstat[0].completionAttempts=team1.stat
            return
          case 'totalPenaltiesYards':
            tstat[0].totalPenaltiesYards=team1.stat
            return
          case 'firstDowns':
            tstat[0].firstDowns=team1.stat
            return
          case 'possessionTime':
            tstat[0].possessionTime=team1.stat
            return
          case 'yardsPerRushAttempt':
            tstat[0].yardsPerRushAttempt=team1.stat
            return
          case 'turnovers':
            tstat[0].turnovers=team1.stat
            return
          case 'rushingAttempts':
            tstat[0].rushingAttempts=team1.stat
            return
          case 'fumblesLost':
            tstat[0].fumblesLost=team1.stat
            return
          case 'interceptions':
            tstat[0].interceptions=team1.stat
            return
          case 'thirdDownEff':
            tstat[0].thirdDownEff=team1.stat
            return
          case 'fourthDownEff':
            tstat[0].fourthDownEff=team1.stat
            return
          case 'passesDeflected':
            tstat[0].passesDeflected=team1.stat
            return
          case 'qbHurries':
            tstat[0].qbHurries=team1.stat
            return
          case 'sacks':
            tstat[0].sacks=team1.stat
            return
          case 'tackles':
            tstat[0].tackles=team1.stat
            return
          case 'defensiveTDs':
            tstat[0].defensiveTDs=team1.stat
            return
          case 'tacklesForLoss':
            tstat[0].tacklesForLoss=team1.stat
            return
          case 'fumblesRecovered':
            tstat[0].fumblesRecovered=team1.stat
            return
          case 'passesIntercepted':
            tstat[0].passesIntercepted=team1.stat
            return
          case 'interceptionTDs':
            tstat[0].interceptionTDs=team1.stat
            return
          case 'interceptionYards':
            tstat[0].interceptionYards=team1.stat
            return
          case 'kickingPoints':
            tstat[0].kickingPoints=team1.stat
            return
          case 'kickReturns':
            tstat[0].kickReturns=team1.stat
            return
          case 'kickReturnTDs':
            tstat[0].kickReturnTDs=team1.stat
            return
          case 'kickReturnYards':
            tstat[0].kickReturnYards=team1.stat
            return
          case 'passingTDs':
            tstat[0].passingTDs=team1.stat
            return
          case 'puntReturns':
            tstat[0].passingTDs=team1.stat
            return
          case 'puntReturnTDs':
            tstat[0].puntReturnTDs=team1.stat
            return
          case 'puntReturnYards':
            tstat[0].puntReturnYards=team1.stat
            return
          case 'rushingTDs':
            tstat[0].rushingTDs=team1.stat
            return
          default:
            return
        }
      })}
      
      {data[0].teams[1].stats.map((team2) => {
        switch(team2.category) {
          case 'yardsPerPass':
            tstat[1].yardsPerPass=team2.stat
            return
          case 'rushingYards':
            tstat[1].rushingYards=team2.stat
            return
          case 'netPassingYards':
            tstat[1].netPassingYards=team2.stat
            return
          case 'totalYards':
            tstat[1].totalYards=team2.stat
            return
          case 'completionAttempts':
            tstat[1].completionAttempts=team2.stat
            return
          case 'totalPenaltiesYards':
            tstat[1].totalPenaltiesYards=team2.stat
            return
          case 'firstDowns':
            tstat[1].firstDowns=team2.stat
            return
          case 'possessionTime':
            tstat[1].possessionTime=team2.stat
            return
          case 'yardsPerRushAttempt':
            tstat[1].yardsPerRushAttempt=team2.stat
            return
          case 'turnovers':
            tstat[1].turnovers=team2.stat
            return
          case 'rushingAttempts':
            tstat[1].rushingAttempts=team2.stat
            return
          case 'fumblesLost':
            tstat[1].fumblesLost=team2.stat
            return
          case 'interceptions':
            tstat[1].interceptions=team2.stat
            return
          case 'thirdDownEff':
            tstat[1].thirdDownEff=team2.stat
            return
          case 'fourthDownEff':
            tstat[1].fourthDownEff=team2.stat
            return
          case 'passesDeflected':
            tstat[1].passesDeflected=team2.stat
            return
          case 'qbHurries':
            tstat[1].qbHurries=team2.stat
            return
          case 'sacks':
            tstat[1].sacks=team2.stat
            return
          case 'tackles':
            tstat[1].tackles=team2.stat
            return
          case 'defensiveTDs':
            tstat[1].defensiveTDs=team2.stat
            return
          case 'tacklesForLoss':
            tstat[1].tacklesForLoss=team2.stat
            return
          case 'fumblesRecovered':
            tstat[1].fumblesRecovered=team2.stat
            return
          case 'passesIntercepted':
            tstat[1].passesIntercepted=team2.stat
            return
          case 'interceptionTDs':
            tstat[1].interceptionTDs=team2.stat
            return
          case 'interceptionYards':
            tstat[1].interceptionYards=team2.stat
            return
          case 'kickingPoints':
            tstat[1].kickingPoints=team2.stat
            return
          case 'kickReturns':
            tstat[1].kickReturns=team2.stat
            return
          case 'kickReturnTDs':
            tstat[1].kickReturnTDs=team2.stat
            return
          case 'kickReturnYards':
            tstat[1].kickReturnYards=team2.stat
            return
          case 'passingTDs':
            tstat[1].passingTDs=team2.stat
            return
          case 'puntReturns':
            tstat[1].passingTDs=team2.stat
            return
          case 'puntReturnTDs':
            tstat[1].puntReturnTDs=team2.stat
            return
          case 'puntReturnYards':
            tstat[1].puntReturnYards=team2.stat
            return
          case 'rushingTDs':
            tstat[1].rushingTDs=team2.stat
            return
          default:
            return
        }
      })}
      {/* {console.log("tstat",tstat)} */}
      <table className="gameStatTable">
        <thead>
          <tr className="gameHeader">
            <th className="bold8 w180">Matchup</th>
            <th className="bold8 tright w80">{ tstat[0].school }</th>
            <th className="bold8 tright w80">{ tstat[1].school }</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="bold tleft ">Score</td>
            <td className="bold tright">{tstat[0].points}</td>
            <td className="bold tright">{tstat[1].points}</td>
          </tr>
          <tr>
            <td className="tright">Defensive TDs</td>
            <td className="tright">{ tstat[0].defensiveTDs }</td>
            <td className="tright">{ tstat[1].defensiveTDs }</td>
          </tr>
          <tr>
            <td className="tright">Kicking points</td>
            <td className="tright">{ tstat[0].kickingPoints }</td>
            <td className="tright">{ tstat[1].kickingPoints }</td>
          </tr>
          <tr>
            <td className="bold tleft">1st Downs</td>
            <td className="bold tright">{ tstat[0].firstDowns }</td>
            <td className="bold tright">{ tstat[1].firstDowns }</td>
          </tr>
          <tr>
            <td className="tright">3rd down efficiency</td>
            <td className="tright">{ tstat[0].thirdDownEff }</td>
            <td className="tright">{ tstat[1].thirdDownEff }</td>
          </tr>
          <tr>
            <td className="tright">4th down efficiency</td>
            <td className="tright">{ tstat[0].fourthDownEff }</td>
            <td className="tright">{ tstat[1].fourthDownEff }</td>
          </tr>
          <tr>
            <td className="bold tleft">Total Yards</td>
            <td className="bold tright">{ tstat[0].totalYards }</td>
            <td className="bold tright">{ tstat[1].totalYards }</td>
          </tr>
          <tr>
            <td className="bold tleft">Passing</td>
            <td className="bold tright">{ tstat[0].netPassingYards }</td>
            <td className="bold tright">{ tstat[1].netPassingYards }</td>
          </tr>
          <tr>
            <td className="tright">Comp-Att</td>
            <td className="tright">{ tstat[0].completionAttempts }</td>
            <td className="tright">{ tstat[1].completionAttempts }</td>
          </tr>
          <tr>
            <td className="tright">Yards per Pass</td>
            <td className="tright">{ tstat[0].yardsPerPass }</td>
            <td className="tright">{ tstat[1].yardsPerPass }</td>
          </tr>
          <tr>
            <td className="tright">Interceptions thrown</td>
            <td className="tright">{ tstat[0].interceptions }</td>
            <td className="tright">{ tstat[1].interceptions }</td>
          </tr>
          <tr>
            <td className="tright">Passing TDs</td>
            <td className="tright">{ tstat[0].passingTDs }</td>
            <td className="tright">{ tstat[1].passingTDs }</td>
          </tr>
          <tr>
            <td className="bold tleft">Rushing</td>
            <td className="bold tright">{ tstat[0].rushingYards }</td>
            <td className="bold tright">{ tstat[1].rushingYards }</td>
          </tr>
          <tr>
            <td className="tright">Rushing Attempts</td>
            <td className="tright">{ tstat[0].rushingAttempts }</td>
            <td className="tright">{ tstat[1].rushingAttempts }</td>
          </tr>
          <tr>
            <td className="tright">Yards per rush</td>
            <td className="tright">{ tstat[0].yardsPerRushAttempt }</td>
            <td className="tright">{ tstat[1].yardsPerRushAttempt }</td>
          </tr>
          <tr>
            <td className="tright">Rushing TDs</td>
            <td className="tright">{ tstat[0].rushingTDs }</td>
            <td className="tright">{ tstat[1].rushingTDs }</td>
          </tr>
          <tr>
            <td className="bold tleft">Penalities</td>
            <td className="bold tright">{ tstat[0].totalPenaltiesYards }</td>
            <td className="bold tright">{ tstat[1].totalPenaltiesYards }</td>
          </tr>
          <tr>
            <td className="bold tleft">Turnovers</td>
            <td className="bold tright">{ tstat[0].turnovers }</td>
            <td className="bold tright">{ tstat[1].turnovers }</td>
          </tr>
          <tr>
            <td className="tright">Fumbles lost</td>
            <td className="tright">{ tstat[0].fumblesLost }</td>
            <td className="tright">{ tstat[1].fumblesLost }</td>
          </tr>
          <tr>
            <td className="tright">Interceptions thrown</td>
            <td className="tright">{ tstat[0].interceptions }</td>
            <td className="tright">{ tstat[1].interceptions }</td>
          </tr>
          <tr>
            <td className="bold tleft">Possession</td>
            <td className="bold tright">{ tstat[0].possessionTime }</td>
            <td className="bold tright">{ tstat[1].possessionTime }</td>
          </tr>
          <tr>
            <td className="bold tleft">Tackles</td>
            <td className="tright">{ tstat[0].tackles }</td>
            <td className="tright">{ tstat[1].tackles }</td>
          </tr>
          <tr>
            <td className="tright">Tackles for loss</td>
            <td className="tright">{ tstat[0].tacklesForLoss }</td>
            <td className="tright">{ tstat[1].tacklesForLoss }</td>
          </tr>
          <tr>
            <td className="bold tleft">Pass Defense</td>
            <td className="bold tright"></td>
            <td className="bold tright"></td>
          </tr>
          <tr>
            <td className="tright">QB hurries</td>
            <td className="tright">{ tstat[0].qbHurries }</td>
            <td className="tright">{ tstat[1].qbHurries }</td>
          </tr>
          <tr>
            <td className="tright">sacks</td>
            <td className="tright">{ tstat[0].sacks }</td>
            <td className="tright">{ tstat[1].sacks }</td>
          </tr>
          <tr>
            <td className="tright">Passes deflected</td>
            <td className="tright">{ tstat[0].passesDeflected }</td>
            <td className="tright">{ tstat[1].passesDeflected }</td>
          </tr>
          <tr>
            <td className="tright">Passes intercepted</td>
            <td className="tright">{ tstat[0].passesIntercepted }</td>
            <td className="tright">{ tstat[1].passesIntercepted }</td>
          </tr>
          <tr>
            <td className="bold tleft">Return yards</td>
            <td className="bold tright"></td>
            <td className="bold tright"></td>
          </tr>
          <tr>
            <td className="tright">Interception return yards</td>
            <td className="tright">{ tstat[0].interceptionYards }</td>
            <td className="tright">{ tstat[1].interceptionYards }</td>
          </tr>
          <tr>
            <td className="tright">Interception TDs</td>
            <td className="tright">{ tstat[0].interceptionTDs }</td>
            <td className="tright">{ tstat[1].interceptionTDs }</td>
          </tr>
          <tr>
            <td className="tright">Punt return yards</td>
            <td className="tright">{ tstat[0].puntReturnYards }</td>
            <td className="tright">{ tstat[1].puntReturnYards }</td>
          </tr>
          <tr>
            <td className="tright">Punt return TDs</td>
            <td className="tright">{ tstat[0].puntReturnTDs }</td>
            <td className="tright">{ tstat[1].puntReturnTDs }</td>
          </tr>
          <tr>
            <td className="tright">Kick return yards</td>
            <td className="tright">{ tstat[0].kickReturnYards }</td>
            <td className="tright">{ tstat[1].kickReturnYards }</td>
          </tr>
          <tr>
            <td className="tright">Kick return TDs</td>
            <td className="tright">{ tstat[0].kickReturnTDs }</td>
            <td className="tright">{ tstat[1].kickReturnTDs }</td>
          </tr>

        </tbody>
      </table>
    </div>
  )
}

export default CFDGameStatsTable