import React from 'react'
import FCSortTable from "./FCSortTable"
import useAxios from 'axios-hooks'

const Standings = ( { serverInfo }) => {

  const [{ data: standings, loading: isPending, error }] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/standing`,
      params: {
        season: serverInfo.season
      },
    },
    {
      useCache:false
    }
  )

  const columns = React.useMemo(
    () => [
      {
        Header: "STANDINGS",
        hstyle: {
          fontSize: '20px',
          textAlign: 'center',
          borderRight: '1px solid black',
        },
        columns: [
          {
            Header: '',
            accessor: 'user_name',
            minWidth: 65,
            width: 110,
            hclass: "th blackHeader noElip",
            className: "td tright",
          },
          {
            Header: 'Ranking',
            accessor: 'ranking',
            minWidth: 40,
            width: 49,
            hclass: "th blackHeader noElip",
            className: "td",
          },
          {
            Header: 'Total Points',
            accessor: 'total_points',
            minWidth: 35,
            width: 40,
            hclass: "th blackHeader noElip",
            className: "td",
          },
          {
            Header: 'Weekly Points',
            accessor: 'weekly_points',
            minWidth: 30,
            width: 43,
            hclass: "th blackHeader noElip",
            className: "td",
          },
          {
            Header: 'Accuracy',
            accessor: 'accuracy',
            minWidth: 30,
            width: 49,
            hclass: "th blackHeader noElip",
            className: "td",
          },
          {
            Header: 'Wins',
            accessor: 'wins',
            minWidth: 20,
            width: 38,
            hclass: "th blackHeader noElip",
            className: "td",
          },
          {
            Header: 'Weeks Played',
            accessor: 'weeks_played',
            minWidth: 20,
            width: 45,
            hclass: "th blackHeader noElip",
            className: "td",
          }
        ]
      }
    ],
    []
  )

  return (
    <div className="standingTable">
      { error && <div>{ error }</div> }
      { isPending && <div>Loading...</div> }
      { standings && <FCSortTable
        columns={columns}
        data={standings}
        getCellProps={cellInfo => ({
          style: {
            backgroundColor: `hsl(${120 * ((120 - cellInfo.value) / 120) * -1 +
              120}, 100%, 67%)`,
          },
        })}
        getRowProps={row => ({
          style: {
            background: row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
          },
        })}
      /> }
    </div>
  )
}

export default Standings