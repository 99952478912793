import React, { useState, useEffect } from 'react'
import useAxios from 'axios-hooks'
import SubmitTable from "./SubmitTable"
import { useNavigate } from "react-router-dom";
import Popup from 'reactjs-popup'


const Submit = ( { userInfo, serverInfo } ) => {

  let history = useNavigate();
  const [pending, setPending] = useState(true)

  const [{ data: submittedData, loading: isPending2, error: error2 }, getSubmitted] = useAxios( {
    url: `${process.env.REACT_APP_HOST}/alreadypicked`,
    method: 'POST'
    },
    {
      manual: true,
      useCache: false
    }
  )

  const [{ data: statData, loading: isPending, error: error }, getMatchups] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/matchup`,
    },
    {
      manual: true,
      useCache:false
    }
  )

  useEffect(() => {
    submittedCheck()
  }, []);

  const submittedCheck = () => {

    if(serverInfo.server === 'OPEN') {

      async function getSubmittedData() {
        try {
          const response = await getSubmitted( { params: { uid: userInfo.uid } } )
          //console.log("submitted data in sumbit", response.data)

          if(response.data==="SUBMITTED") {
            /* history.push("/AlreadySubmitted") */
            setHeader('ALREADY SUBMITTED')
            setMessage("Um, this is embarrassing ... but you already submitted your predictions for this week!")
            setWarning(true)
          } else {
            //console.log("not submitted")
            async function getMatchupData() {
              try {
                const response = await getMatchups()
                //console.log("response", response)
        
                //console.log("matchups data in sumbit", response)
                setPending(false)
        
              } catch (e) {
                console.log("getMatchups error called", error)
              }
            }    
            getMatchupData()

          }

        } catch (e) {
          console.log("getSubmitters error called", error)
        }
      }    
      getSubmittedData()
    } else {
      setHeader('SERVER CLOSED')
      setMessage('Not accepting predictions at this time')
      setWarning(true)
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "This Week's Games",
        hstyle: {
          fontSize: '20px',
          textAlign: 'center',
          height: '40px',
          backgroundColor: 'black',
          color: 'white',
        },
        columns: [
          {
            Header: '',
            columnId: 1,
            hstyle: {
              height: '0px',
              backgroundColor: 'black',
            },
            accessor: 'teamA',
            Cell: props => {
              return(
                <button id="buttonB" className="submitAway" value={props.row.original.teamA} >
                  <img className="imgA pright2" src={process.env.PUBLIC_URL + props.row.original.imgA} height='14px' alt='Logo' />
                  <span className="teamA">{props.row.original.teamAn}</span>
                </button>
              )
            }
          },
          {
            Header: '',
            hstyle: {
              height: '0px',
              backgroundColor: 'black',
            },
            accessor: 'location',
          },
          {
            Header: '',
            columnId: 2,
            hstyle: {
              height: '0px',
              backgroundColor: 'black',
            },
            accessor: 'teamH',
            Cell: props => {
              return(
                <button id="buttonB" className="submitHome" value={props.row.original.teamH} >
                  <img className="imgH pright2" src={process.env.PUBLIC_URL + props.row.original.imgH} height='14px' alt='Logo' />
                  <span className="teamH">{props.row.original.teamHn}</span>
                </button>
              )
            }
          }
        ],
      },
    ],
    []
  )

  const [headertitle, setHeader] = useState(false);
  const [warning, setWarning] = useState(false);
  const [message, setMessage] = useState('');

  const Warning = () => {
    return(
      <Popup
        open={warning}
        className="w250-popup"
        onClose={() => history.push("/#/")} 
        modal
      >
        {close => (
          <div className="fcmodal">
            <button className="close" onClick={close}>
              X
            </button>
            <div className="header cRed bold">{headertitle}</div>
            <div className="content">
              {' '}
              <span className="nooutline cyellow">{message}</span>
            </div>
          </div>
        )}
      </Popup>
    )
  }


  return (
    <div className="MatchupsTable">
      <Warning/>
      { error && <div>{ error }</div>}
      { (pending && serverInfo.server ==='OPEN') && <div>Loading...</div> }
      { (!pending && serverInfo.server ==='OPEN') && <SubmitTable 
          userInfo={userInfo}
          serverInfo={serverInfo}
          columns={columns}
          data={statData}
        /> }
    </div>
  )
  
}

export default Submit