import React from 'react';
import MatchupsTable from "./MatchupsTable";
import useAxios from 'axios-hooks';

const Matchups = () => {

  const [{ data: matchups, loading: isPending, error: getError }] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/matchup`,
    },
    {
      useCache:false
    }
  )

  const columns = React.useMemo(
    () => [
      {
        Header: "This Week's Games",
        hstyle: {
          fontSize: '20px',
          textAlign: 'center',
          height: '40px',
          backgroundColor: 'black',
          color: 'white',
        },
        columns: [
          {
            Header: '',
            hstyle: {
              height: '0px',
              backgroundColor: 'black',
            },
            accessor: 'teamA',
            Cell: props => {
              return(
                <div className="matchupsAway">
                  <img className="imgA pright2" src={process.env.PUBLIC_URL + props.row.original.imgA} height='14px' alt='Logo' />
                  <span className="teamA">{props.row.original.teamAn}</span>
                </div>
              )
            }
          },
          {
            Header: '',
            hstyle: {
              height: '0px',
              backgroundColor: 'black',
            },
            accessor: 'location',
          },
          {
            Header: '',
            hstyle: {
              height: '0px',
              backgroundColor: 'black',
            },
            accessor: 'teamH',
            Cell: props => {
              return(
                <div className="matchupsHome">
                  <img className="imgH pright2" src={process.env.PUBLIC_URL + props.row.original.imgH} height='14px' alt='Logo' />
                  <span className="teamH">{props.row.original.teamHn}</span>
                </div>
              )
            }
          }
        ],
      },
    ],
    []
  )

  return (
    <div className="MatchupsTable">
      { getError && <div>{ getError }</div> }
      { isPending ? <div>Loading...</div> : <MatchupsTable
        columns={columns}
        data={matchups}
      /> }
    </div>
  )
}

export default Matchups