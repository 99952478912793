import React, { useState } from 'react'
import { useTable } from 'react-table'
import { useNavigate } from "react-router-dom";
import useAxios from 'axios-hooks'

const defaultPropGetter = () => ({})

function WinnersTable ({ 
  columns,
  data,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
 }) {

  //const [isPending, setIsPending] = useState('true')
  //const [error, setError] = useState('')
  
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  const navigate = useNavigate();

  const [{ data: statData, loading: isPending, error }, getWinnerInfo] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/winners`,
      method: 'POST'
    },
    { 
      manual: true,
      useCache: false
    }
  )

  const handleSubmit = (e) => {
    e.preventDefault();
    //console.log("data: ", data)

    async function getPost() {
      try {
        const response = await getWinnerInfo( 
          { 
            params: 
            { 
              game0,
              game1,
              game2,
              game3,
              game4,
              game5,
              game6,
              game7,
              game8,
              game9
            }
          }
        )

        //console.log("response", response.data)
        navigate("/#/");

      } catch (e) {
        //console.log("error in useEffect Login", e)
      }
    }    
    getPost()
  }

  const [game0, setGame0] = useState(-1);
  const [game1, setGame1] = useState(-1);
  const [game2, setGame2] = useState(-1);
  const [game3, setGame3] = useState(-1);
  const [game4, setGame4] = useState(-1);
  const [game5, setGame5] = useState(-1);
  const [game6, setGame6] = useState(-1);
  const [game7, setGame7] = useState(-1);
  const [game8, setGame8] = useState(-1);
  const [game9, setGame9] = useState(-1);


  const handleBG = (e) => {
    //console.log("event ", e)
    
    if(e.target.tagName ==="BUTTON"  || e.target.tagName === "SPAN" || e.target.tagName === "IMG") {  // make sure it is column zero or two so as to prevent vs/at (column 1)
      
      let value = null
      const row = parseInt(e.target.offsetParent.attributes.rower.value)
      const team = parseInt(e.target.offsetParent.attributes.id.value)
      if(e.target.tagName==="BUTTON") {
        value = e.target.value
      } else {
        value = parseInt(e.target.parentElement.value)
      }
      //console.log("game: ", row, " team: ", team, " value: ", value)

      switch(row) {
        case 0:
          
            setGame0(value)
            e.target.offsetParent.style.backgroundColor = "yellow"
            if(team === 0) {
              e.target.offsetParent.nextSibling.nextSibling.style.backgroundColor = "white"
            } else {
              e.target.offsetParent.previousSibling.previousSibling.style.backgroundColor = "white"
            }
          
          break;
        case 1:
          
            setGame1(value)
            e.target.offsetParent.style.backgroundColor = "yellow"
            if(team === 0) {
              e.target.offsetParent.nextSibling.nextSibling.style.backgroundColor = "white"
            } else {
              e.target.offsetParent.previousSibling.previousSibling.style.backgroundColor = "white"
            }
          
          break;
        case 2:
          
            setGame2(value)
            e.target.offsetParent.style.backgroundColor = "yellow"
            if(team === 0) {
              e.target.offsetParent.nextSibling.nextSibling.style.backgroundColor = "white"
            } else {
              e.target.offsetParent.previousSibling.previousSibling.style.backgroundColor = "white"
            }
          
          break;
        case 3:
          
            setGame3(value)
            e.target.offsetParent.style.backgroundColor = "yellow"
            if(team === 0) {
              e.target.offsetParent.nextSibling.nextSibling.style.backgroundColor = "white"
            } else {
              e.target.offsetParent.previousSibling.previousSibling.style.backgroundColor = "white"
            }
      
          break;
        case 4:
          
            setGame4(value)
            e.target.offsetParent.style.backgroundColor = "yellow"
            if(team === 0) {
              e.target.offsetParent.nextSibling.nextSibling.style.backgroundColor = "white"
            } else {
              e.target.offsetParent.previousSibling.previousSibling.style.backgroundColor = "white"
            }
        
          break;
        case 5:
          
            setGame5(value)
            e.target.offsetParent.style.backgroundColor = "yellow"
            if(team === 0) {
              e.target.offsetParent.nextSibling.nextSibling.style.backgroundColor = "white"
            } else {
              e.target.offsetParent.previousSibling.previousSibling.style.backgroundColor = "white"
            }
       
          break;
        case 6:
          
            setGame6(value)
            e.target.offsetParent.style.backgroundColor = "yellow"
            if(team === 0) {
              e.target.offsetParent.nextSibling.nextSibling.style.backgroundColor = "white"
            } else {
              e.target.offsetParent.previousSibling.previousSibling.style.backgroundColor = "white"
            }
        
          break;
        case 7:
          
            setGame7(value)
            e.target.offsetParent.style.backgroundColor = "yellow"
            if(team === 0) {
              e.target.offsetParent.nextSibling.nextSibling.style.backgroundColor = "white"
            } else {
              e.target.offsetParent.previousSibling.previousSibling.style.backgroundColor = "white"
            }
          break;
        case 8:
          
            setGame8(value)
            e.target.offsetParent.style.backgroundColor = "yellow"
            if(team === 0) {
              e.target.offsetParent.nextSibling.nextSibling.style.backgroundColor = "white"
            } else {
              e.target.offsetParent.previousSibling.previousSibling.style.backgroundColor = "white"
            }
          
          break;
        case 9:
          
            setGame9(value)
            e.target.offsetParent.style.backgroundColor = "yellow"
            if(team === 0) {
              e.target.offsetParent.nextSibling.nextSibling.style.backgroundColor = "white"
            } else {
              e.target.offsetParent.previousSibling.previousSibling.style.backgroundColor = "white"
            }
          break;
        default:
          break;
      }

    } else {
      return
    }
  }


  // Render the UI for your table
  return (
    <div className="fcolumn">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps([
                  {
                    className: column.hclassName,
                    style: column.hstyle,
                  },
                  getColumnProps(column),
                  getHeaderProps(column),
                ])}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, m) => {
                  return <td id={m} rower={i} onClick={handleBG} {...cell.getCellProps([
                    {
                      className: 'cell.column.className',
                      style: cell.column.style,
                    },
                    getColumnProps(cell.column),
                    getCellProps(cell),
                    
                  ])}
                  >{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>

      <div id="pickBottom">
        <button id="btn-pick" type="submit" className="btn btn-dark btn-sm" onClick={handleSubmit}>Submit</button>
      </div>
      
    </div>
  )
}

export default WinnersTable