import React from 'react'
import dateFormat from 'dateformat'

var ordinal = require('ordinal')

function CFDMatchupHistoryTable({ data }) {

  //console.log("data: ", data)

  // Render the UI for your table
  return (
    <div>
      <div className="explain">Home team is on the bottom</div>
      { data.length > 0 &&
        <div className="boxscores">

          {data.map((gData, index) => {
            return(
              <div className="smallbox">

                <div className="scoresheader">
                  <div className="scorescolumn">
                    <div className="cyellow mleft5 f13">{ gData.winner }</div>
                  </div>
                  <div className="scorescolumnr">
                    <div className="scorestv">{ gData.season }</div>
                  </div>
                </div>
                
                <div className="scoresrow lefty">
                  <div className="scorescolumn">
                    <div className="scoresTeamL">{ gData.awayTeam }</div>
                  </div>
                  <div className="scorescolumn scoresPoints f22">{ gData.awayScore }</div>
                </div>

                <div className="scoresrow lefty">
                  <div className="scorescolumn">
                    <div className="scoresTeamL">{ gData.homeTeam }</div>
                  </div>
                  <div className="scorescolumn scoresPoints f22">{ gData.homeScore }</div>
                </div>

                <div className="scoresbottom">
                  <div className="scorescolumn">
                    <div className="scoresvenue">{ dateFormat((gData.startDate), "ddd, mmm d, yyyy h:MM TT Z") }</div>
                    <div className="scoresvenue">{ gData.venue }</div>
                  </div>
                  <div className="scorescolumnr">
                    <div className="scoresweatherR">Week: { gData.week }</div>
                    <div className="scoresweatherR">{ gData.seasonType }&nbsp;Season</div>
                  </div>
                </div>

              </div>
            )
          })}
        </div>
      }
    </div>
  )
}

export default CFDMatchupHistoryTable