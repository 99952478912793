import React, { useState, useEffect } from 'react'
import useAxios from 'axios-hooks'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import FCSortTable from './FCSortTable'

const CFDRoster = () => {

  const [schools, setSchools] = useState([]);
  const [gameData, setGameData] = useState([])

  const validationSchema = Yup.object( {
    year: Yup.number()
      .required('Required'),
    team: Yup.string()
      .required('Required')
  } )

  const formik = useFormik({
    initialValues: {
      year: '',
      team: ''
    },
    onSubmit: values => {

      async function getPost() {
        try {
          const response = await getInfo( { params: { year: values.year, team: values.team } })
          //console.log("response", response.data)
          setGameData(response.data)

        } catch (e) {
          console.log("error in CFDRoster", error)
        }
      }    
      getPost()
    },
    validationSchema
  })

  const [{ data, loading: isPending, error }, getInfo] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/cfd/roster`
    },
    {
      manual: true,
      useCache:false
    }
  )

  const columns = React.useMemo(
    () => [
      {
        Header: "Roster",
        className: 'title',
        hstyle: {
          fontSize: '20px',
          textAlign: 'center',
          borderRight: '1px solid black',
        },
        columns: [
          {
            Header: 'First Name',
            accessor: 'firstName',
            minWidth: 50,
            width: 50,
            hclass: "th blackHeader noElip",
            className: "td",
          },
          {
            Header: 'Last Name',
            accessor: 'lastName',
            minWidth: 50,
            width: 50,
            hclass: "th blackHeader noElip",
            className: "td",
          },
          {
            Header: 'Team',
            accessor: 'team',
            minWidth: 50,
            width: 50,
            hclass: "th blackHeader noElip",
            className: "td",
          },
          {
            Header: 'Weight',
            accessor: 'weight',
            minWidth: 25,
            width: 25,
            hclass: "th blackHeader noElip",
            className: "td",
          },
          {
            Header: 'Height',
            accessor: 'height',
            minWidth: 21,
            width: 21,
            hclass: "th blackHeader noElip",
            className: "td",
          },
          {
            Header: 'Jersey',
            accessor: 'jersey',
            minWidth: 21,
            width: 21,
            hclass: "th blackHeader noElip",
            className: "td",
          },
          {
            Header: 'Year',
            accessor: 'year',
            minWidth: 15,
            width: 15,
            hclass: "th blackHeader noElip",
            className: "td",
          },
          {
            Header: 'Position',
            accessor: 'position',
            minWidth: 22,
            width: 22,
            hclass: "th blackHeader noElip",
            className: "td",
          },
          {
            Header: 'City',
            accessor: 'homeCity',
            minWidth: 50,
            width: 50,
            hclass: "th blackHeader noElip",
            className: "td",
          },
          {
            Header: 'State',
            accessor: 'homeState',
            minWidth: 18,
            width: 18,
            hclass: "th blackHeader noElip",
            className: "td",
          },
          {
            Header: 'Country',
            accessor: 'homeCountry',
            minWidth: 25,
            width: 25,
            hclass: "th blackHeader noElip",
            className: "td",
          }
        ]
      }
    ],
    []
  )

  const [{ data: schoolData, loading: getLoading, error: getError }, getSchools] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/getschools`,
    },
    {
      manual: true,
      useCache:false
    }
  )

  const setup = () => {

    async function getSchoolData() {
      try {
        const response = await getSchools()
        //console.log("response", response)
        setSchools(response.data)

      } catch (e) {
        console.log("getSchools error called", error)
      }
    }    
    getSchoolData()
  }

  useEffect(() => {
    setup()
  }, []);

  if(schools.length<1) {
    return(<div>LOADING...</div>)
  } else {
    return (

      <div className="cfd">
        { gameData.length > 0 && <FCSortTable columns={columns} data={gameData} /> }
            
        <div className="explain jcenter f16 mtop20">Data goes back to around 2004 for some teams</div>
        <div className="explain f16">Select Team and Year</div>
          
        <form className="needs-validation  jcenter mtop20" onSubmit={formik.handleSubmit}>

          <div className="row jcenter">
            
            <div className="form-control w180 mright5">
              <label>Team :</label>
              <select
                className="form-control"
                name="team"
                value={formik.values.team}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                {schools.map(({school}, index) => (
                <option key={index} value={school}>{school}</option>
                ))}
              </select>
              {formik.touched.team && formik.errors.team ? <div className='errors'>{formik.errors.team}</div> : null}
            </div>

            <div className="form-control w80 mleft5">
              <label>Year :</label>
              <input
                type="text"
                className="form-control"
                id="floatingInput1"
                value={formik.values.year}
                placeholder="2022"
                name="year"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.year && formik.errors.year ? <div className='errors'>{formik.errors.year}</div> : null}
            </div>
          </div>
          
          <div className="cfdsubmit">
            <button id="btn-login" type="submit" className="btn btn-dark btn-sm">Submit</button>
          </div>
        
        </form>
      </div>
    )
  }
}

export default CFDRoster