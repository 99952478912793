import React, { useState, useEffect } from 'react'
import useAxios from 'axios-hooks'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import dateFormat from 'dateformat'

const CFDCoaches = ( { serverInfo } ) => {

  const [coaches, setCoachesData] = useState([]);
  const [schools, setSchools] = useState([])

  const validationSchema = Yup.object().shape ({
    'team': Yup.string()
      .when('year', {                                                    
        is: (year) => !year || year.length === 0,                      
        then: Yup.string()                                                   
        .required('At least one of the fields is required'),                 
      }),
    'year': Yup.number()
      .when('team', {                                                    
        is: (team) => !team || team.length === 0,                      
        then: Yup.number()
        .min(1869)
        .max(serverInfo.season)                                                  
        .required('At least one of the fields is required'),                 
      })
  }, ['team', 'year'] )

  const formik = useFormik({
    initialValues: {
      team: '',
      year: ''
    },
    onSubmit: values => {

      async function getPost() {
        console.log("values", values)
        try {
          const response = await getCoaches( { params: { team: values.team, year: values.year } } )
          //console.log("response", response.data)
          setCoachesData(response.data)
  
        } catch (e) {
          console.log("error in CFDCoaches", error)
        }
      }
      getPost()
    },
    validationSchema
  })

  const [{ data: coachesData, loading: getLoading, error: error }, getCoaches] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/cfd/coaches`,
    },
    {
      manual: true,
      useCache:false
    }
  )

  const [{ data: schoolData, loading: sLoading, error: sError }, getSchools] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/getschools`,
    },
    {
      manual: true,
      useCache:false
    }
  )

  const setup = () => {

    async function getSchoolData() {
      try {
        const response = await getSchools()
        //console.log("response", response)
        setSchools(response.data)

      } catch (e) {
        console.log("getSchools error called", sError)
      }
    }    
    getSchoolData()
  }

  useEffect(() => {
    setup()
  }, []);


  if(schools.length<1) {
    return(<div>LOADING...</div>)
  } else {
    return(

      <div className="coaches">

        {coaches &&

          coaches.map((cstat) => {
            return(
              <table className="gameTable mbottom20">
                <thead className="coachHead">
                  <tr>
                    <td className="tleft"><span className="bold8 tleft mleft5">{ cstat.firstName } { cstat.lastName }</span><span className="fright mr5"> Hired: { dateFormat((cstat.hireDate), "ddd, mmm dS yyyy") }</span></td>
                  </tr>
                </thead>
                <tbody>
                  
                
                  <tr>
                    <td colSpan="2">
                      <table className="coachTable">
                        <thead>
                        <tr className="gameHeader">
                            <th colSpan="3"></th>
                            <th colSpan="3" className="bgSalmon">Record</th>
                            <th colSpan="2" className="bgGray">Ranking</th>
                            <th colSpan="3" className="bgBisque">SP+ Rating</th>
                            <th></th>
                          </tr>
                          <tr className="gameHeader">
                            <th>School</th>
                            <th className="bgCornsilk">Year</th>
                            <th>Games</th>
                            <th className="bgSalmon">W</th>
                            <th className="bgSalmon">L</th>
                            <th className="bgSalmon">T</th>
                            <th className="bgGray">Start</th>
                            <th className="bgGray">End</th>
                            <th className="bgBisque">Def</th>
                            <th className="bgBisque">Off</th>
                            <th className="bgBisque">All</th>
                            <th className="bgGray">SRS</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cstat.seasons.map((sstat) => {
                            //console.log("in", cstat, sstat)
                            return(
                              <tr>
                                <td>{ sstat.school }</td>
                                <td className="bgCornsilk">{ sstat.year}</td>
                                <td>{ sstat.games}</td>
                                <td className="bgSalmon">{ sstat.wins}</td>
                                <td className="bgSalmon">{ sstat.losses}</td>
                                <td className="bgSalmon">{ sstat.ties}</td>
                                <td className="bgGray">{ sstat.preseason_rank}</td>
                                <td className="bgGray">{ sstat.postseason_rank}</td>
                                <td className="bgBisque">{ sstat.sp_defense}</td>
                                <td className="bgBisque">{ sstat.sp_offense}</td>
                                <td className="bgBisque">{ sstat.sp_overall}</td>
                                <td className="bgGray">{ sstat.srs}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            )
          })
        }

        <div className="explain jcenter">Data goes back to 1869</div>
        <div className="explain">Select Team (week is optonal)</div>
          
        <form className="needs-validation  jcenter mtop20" onSubmit={formik.handleSubmit}>

          <div className="row jcenter">
            
            <div className="form-control w180 mright5">
              <label>Team:</label>
              <select
                className="form-control"
                name="team"
                value={formik.values.team}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                {schools.map(({school}, index) => (
                <option key={index} value={school}>{school}</option>
                ))}
              </select>
              {formik.touched.team && formik.errors.team ? <div className='errors'>{formik.errors.team}</div> : null}
            </div>
          
            <div className="form-control w80 mleft5">
              <label>Year :</label>
              <input
                type="text"
                className="form-control"
                id="floatingInput1"
                value={formik.values.year}
                placeholder={serverInfo.season}
                name="year"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.year && formik.errors.year ? <div className='errors'>{formik.errors.year}</div> : null}
            </div>
          </div>
          
          <div className="cfdsubmit">
            <button id="btn-login" type="submit" className="btn btn-dark btn-sm">Submit</button>
          </div>
        
        </form>
      </div>
    )
  }
}

export default CFDCoaches