import React from 'react';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar'
import { FaUser, FaRegRegistered, FaFootballBall, FaDatabase, FaEnvelopeOpenText, FaBookReader, FaUserLock, FaTrophy, FaChartLine, FaCalculator, FaHatWizard, FaArchive } from 'react-icons/fa'
import { GiSherlockHolmes, GiInjustice } from 'react-icons/gi'
import { GoGear } from 'react-icons/go'
import sidebarBg from '../assets/barkley.jpg'
import { Link } from 'react-router-dom'


const Aside = ({ image, toggled, handleToggleSidebar, handleLogin, userInfo }) => {

  //console.log("uid", userInfo.uid)

  return (
    <ProSidebar
      image={image ? sidebarBg : false}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <Menu iconShape="circle">
          <div className="row">
            { parseInt(userInfo.uid) > -1 ? 
              <div className="menurow">
                <div className="menucolumn">
                  <img className="favorite" src={'./css/images/logos/'+userInfo.ufavorite+'.png'} width='20px' height='20px' alt='Logo' />
                </div>
                
                <div className="menucolumn">
                  <div id="username">{userInfo.uname}</div>
                
                  <MenuItem id="logout">
                    Logout
                    <Link to="/logout" />
                  </MenuItem>
                </div>
              </div>
            :
              <div className="menucolumn">
                <MenuItem icon={<FaUser />}>
                  Sign In
                  <Link to="/login" />
                </MenuItem> 
                <MenuItem icon={<FaRegRegistered />}>
                  Register
                  <Link to="/addplayer" />
                </MenuItem> 
              </div>}
            </div>
        </Menu> 
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="circle">
          <MenuItem icon={<FaFootballBall />}>
            Matchups
            <Link to="/" />
          </MenuItem>
          <MenuItem icon={<FaTrophy />}>
            Standings
            <Link to="/standings" />
          </MenuItem>
          <MenuItem icon={<FaChartLine />}>
            Statistics
            <Link to="/stats" />
          </MenuItem>
          <MenuItem icon={<FaHatWizard />}>
            Predictions
            <Link to="/predictions" />
          </MenuItem>
          <MenuItem icon={<FaCalculator />}>
            Scenario Generator
            <Link to="/scenariogenerator" />
          </MenuItem>
          <MenuItem icon={<FaBookReader />}>
            Scoreboard
            <Link to="/scores" />
          </MenuItem>
          { parseInt(userInfo.uid) > -1 &&  
            <MenuItem icon={<FaEnvelopeOpenText />}>
              Submit Picks
              <Link to="/submitPicks" />
            </MenuItem>
          }
          <MenuItem icon={<GiInjustice />}>
            Rules
            <Link to="/rules" />
          </MenuItem>
          { parseInt(userInfo.uid) > -1 &&  
            <MenuItem icon={<GoGear />}>
              User Profile
              <Link to="/profile" />
            </MenuItem>
          } 
        </Menu>
 
        <Menu iconShape="circle">
          <SubMenu
            //suffix={<span className="badge yellow">2</span>}
            title='Football Challenge'
            icon={<FaArchive />}
          >
            <MenuItem>
              Season Archives
              <Link to="/seasonarchive" />
            </MenuItem>
            <MenuItem suffix={<span className="badge red">Beta</span>}>
              Player History
              <Link to="/playerarchive" />
            </MenuItem>
          </SubMenu>

          { parseInt(userInfo.uid)===1 &&
            <SubMenu
              //prefix={<span className="badge gray">8</span>}
              title='Administration'
              icon={<FaUserLock />}
            >
              <MenuItem>
                Winners Editor
                <Link to="/winners" />
              </MenuItem>
              <MenuItem>
                Server Editor
                <Link to="/server" />
              </MenuItem>
              <MenuItem>
                Matchups Editor
                <Link to="/gameselection" />
              </MenuItem>
              <MenuItem>
                User Editor
                <Link to="/users" />
              </MenuItem>
              <MenuItem>
                Picks Editor
                <Link to="/picks" />
              </MenuItem>
              <MenuItem>
                Calculate Week
                <Link to="/brain" />
              </MenuItem> 
            </SubMenu>
          }

          <SubMenu
            icon={<FaDatabase />}
            suffix={<span className="badge red">new</span>}
            title='Football Database'
          >
            <SubMenu title='Games'>
              <MenuItem>
                Game Results
                <Link to="/cfd/games" />
              </MenuItem>
              <MenuItem>
                Game Stats
                <Link to="/cfd/gamestats" />
              </MenuItem>
              <MenuItem>
                Play Data
                <Link to="/cfd/plays" />
              </MenuItem>
              <MenuItem>
                Drive Data
                <Link to="/cfd/drives" />
              </MenuItem>
              <MenuItem>
                Game Weather
                <Link to="/cfd/weather" />
              </MenuItem>
            </SubMenu>
            <SubMenu title='Teams'>
              <MenuItem>
                Team Roster
                <Link to="/cfd/roster" />
              </MenuItem>
              <MenuItem>
                Team Records
                <Link to="/cfd/records" />
              </MenuItem>
              <MenuItem>
                Matchup History
                <Link to="/cfd/matchhistory" />
              </MenuItem>
            </SubMenu>
            <SubMenu title='Miscellenous'>
              <MenuItem>
                Betting Line
                <Link to="/cfd/line" />
              </MenuItem>
              <MenuItem>
                Coaches
                <Link to="/cfd/coaches" />
              </MenuItem>
              <MenuItem>
                Stadiums
                <Link to="/cfd/venues" />
              </MenuItem>
              <MenuItem>
                Rankings
                <Link to="/cfd/polls" />
              </MenuItem>
            </SubMenu>
          </SubMenu>
        </Menu> 
      </SidebarContent>

      <SidebarFooter style={{ textAlign: 'center' }}>
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: '20px 24px',
          }}
        >
          <a
            href="https://github.com/senilemonk"
            target="_blank"
            className="sidebar-btn"
            rel="noopener noreferrer"
          >
            <GiSherlockHolmes />
            <span>SenileMonk Github</span>
          </a>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Aside;