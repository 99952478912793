import React, { useState, useEffect } from 'react'
import useAxios from 'axios-hooks'
import FCSortTable from "./FCSortTable"
import { useFormik } from 'formik';
import * as Yup from 'yup';


const ScenariorGenerator = ({ serverInfo }) => {

  const [schools, setSchools] = useState();
  const [matchupsData, setMatchupsData] = useState()
  const [recordsOriginal, setRecordsOriginal] = useState()
  const [picksData, setPicksData] = useState()
  const [recordsD, setRecordsD] = useState()
  const [ready, setReady] = useState(false)
  const [buttonPush, setButtonPush] = useState(false)

  var actualsData = [{}]
  var recordsData = []

  const validationSchema = Yup.object( {
    game0: Yup.string()
      .required('Required'),
    game1: Yup.string()
      .required('Required'),
    game2: Yup.string()
      .required('Required'),
    game3: Yup.string()
      .required('Required'),
    game4: Yup.string()
      .required('Required'),
    game5: Yup.string()
      .required('Required'),
    game6: Yup.string()
      .required('Required'),
    game7: Yup.string()
      .required('Required'),
    game8: Yup.string()
      .required('Required'),
    game9: Yup.string()
      .required('Required'),
  } )

  const formik = useFormik({
    initialValues: {
      game0: '',
      game1: '',
      game2: '',
      game3: '',
      game4: '',
      game5: '',
      game6: '',
      game7: '',
      game8: '',
      game9: '',
    },
    onSubmit: values => {
      
      actualsData[0].pick0=values.game0
      actualsData[0].pick1=values.game1
      actualsData[0].pick2=values.game2
      actualsData[0].pick3=values.game3
      actualsData[0].pick4=values.game4
      actualsData[0].pick5=values.game5
      actualsData[0].pick6=values.game6
      actualsData[0].pick7=values.game7
      actualsData[0].pick8=values.game8
      actualsData[0].pick9=values.game9
      
      setButtonPush(true)
      handleCalculating()
    },
    validationSchema
  })

  useEffect(() => {
    console.log("i made here useeffect (ready, buttonPush)", ready, buttonPush)
    if(buttonPush) {
      setReady(true)
    }
 }, [recordsD]);


  function HdogCatcher(p) {
    var trueDogs = 0
    //check if this were actually an underdog that happened
    switch(p)
    {
      case 0:
        if(matchupsData[p]['teamH'] === actualsData[0]['pick0'])
        {
          trueDogs += 1;
        }
        break;
      
      case 1:
        if(matchupsData[p]['teamH'] === actualsData[0]['pick1'])
        {
          trueDogs += 1;
        }
        break;
      
      case 2:
        if(matchupsData[p]['teamH'] === actualsData[0]['pick2'])
        {
          trueDogs += 1;
        }
        break;
      
      case 3:
        if(matchupsData[p]['teamH'] === actualsData[0]['pick3'])
        {
          trueDogs += 1;
        }
        break;
      
      case 4:
        if(matchupsData[p]['teamH'] === actualsData[0]['pick4'])
        {
          trueDogs += 1;
        }
        break;
      
      case 5:
        if(matchupsData[p]['teamH'] === actualsData[0]['pick5'])
        {
          trueDogs += 1;
        }
        break;
      
      case 6:
        if(matchupsData[p]['teamH'] === actualsData[0]['pick6'])
        {
          trueDogs += 1;
        }
        break;
      
      case 7:
        if(matchupsData[p]['teamH'] === actualsData[0]['pick7'])
        {
          trueDogs += 1;
        }
        break;
      
      case 8:
        if(matchupsData[p]['teamH'] === actualsData[0]['pick8'])
        {
          trueDogs += 1;
        }
        break;
      
      case 9:
        if(matchupsData[p]['teamH'] === actualsData[0]['pick9'])
        {
          trueDogs += 1;
        }
        break;
      
      default:
        break;
    }
    return(trueDogs);
  }

  function AdogCatcher(p) {
    var trueDogs=0;
    
    //check if this were actually an underdog that happened
    switch(p)
    {
      case 0:
        if(matchupsData[p]['teamA'] === actualsData[0]['pick0'])
        {
         trueDogs += 1;
        }
        break;
      
      case 1:
        if(matchupsData[p]['teamA'] === actualsData[0]['pick1'])
        {
          trueDogs += 1;
        }
        break;
      
      case 2:
        if(matchupsData[p]['teamA'] === actualsData[0]['pick2'])
        {
          trueDogs += 1;
        }
        break;
      
      case 3:
        if(matchupsData[p]['teamA'] === actualsData[0]['pick3'])
        {
          trueDogs += 1;
        }
        break;
      
      case 4:
        if(matchupsData[p]['teamA'] === actualsData[0]['pick4'])
        {
          trueDogs += 1;
        }
        break;
      
      case 5:
        if(matchupsData[p]['teamA'] === actualsData[0]['pick5'])
        {
          trueDogs += 1;
        }
        break;
      
      case 6:
        if(matchupsData[p]['teamA'] === actualsData[0]['pick6'])
        {
          trueDogs += 1;
        }
        break;
      
      case 7:
        if(matchupsData[p]['teamA'] === actualsData[0]['pick7'])
        {
          trueDogs += 1;
        }
        break;
      
      case 8:
        if(matchupsData[p]['teamA'] === actualsData[0]['pick8'])
        {
          trueDogs += 1;
        }
        break;
      
      case 9:
        if(matchupsData[p]['teamA'] === actualsData[0]['pick9'])
        {
          trueDogs += 1;
        }
        break;
        
      default:
        break;
    }
    return(trueDogs);
  }

  const [{ data: schoolData, loading: getLoadingSchools, error: getErrorSchools }, getSchools] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/getschools`,
    },
    {
      manual: true,
      useCache:false
    }
  )

  const [{ data: aData, loading: isPendingActuals, error: errorActuals }, getActuals] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/brain/actuals`,
    },
    {
      manual: true,
      useCache:false
    }
  )

  const [{ data: mData, loading: isPendingMatchups, error: errorMatchups }, getMatchups] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/brain/matchups`,
    },
    {
      manual: true,
      useCache:false
    }
  )

  const [{ data: rData, loading: isPendingRecords, error: errorRecords }, getRecords] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/brain/recordsbrain`,
      method: 'POST'
    },
    {
      manual: true,
      useCache:false
    }
  )

  const [{ data: pData, loading: isPendingPicks, error: errorPicks }, getPicks] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/brain/picks`,
    },
    {
      manual: true,
      useCache:false
    }
  )

  const [{ data: uData, loading: isPendingUsers, error: errorUsers }, getUsers] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/brain/users`,
    },
    {
      manual: true,
      useCache:false
    }
  )

  const [{ data: alData, loading: isPendingAll, error: errorAll }, getAll] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/brain/all`,
    },
    {
      manual: true,
      useCache:false
    }
  )


  async function getPostMatchups() {
    try {
      const responseMatchups = await getMatchups()
      //console.log("responseMatchups", responseMatchups.data)
      setMatchupsData(responseMatchups.data)
      setTimeout(2000)

    } catch (e) {
      console.log("error in Brain Matchups", e)
    }
  }

  async function getPostRecords() {
    try {
      const responseRecords = await getRecords( { params: { season: serverInfo.season } } )
      //console.log("responseRecords", responseRecords.data)
      setRecordsOriginal(responseRecords.data)
      setRecordsD(responseRecords.data)
      setTimeout(2000)

    } catch (e) {
      console.log("error in Brain Records", e)
    }
  }

  async function getPostPicks() {
    try {
      const responsePicks = await getPicks()
      //console.log("responsePicks", responsePicks.data)
      setPicksData(responsePicks.data)
      setTimeout(2000)

    } catch (e) {
      console.log("error in Brain Picks", e)
    }
  }

  async function getSchoolData() {
    try {
      const responseSchool = await getSchools()
      //console.log("schools", responseSchool)
      setSchools(responseSchool.data)
      setTimeout(2000)

    } catch (e) {
      console.log("getSchools error called", e)
    }
  }


  useEffect(() => {
    getPostMatchups()
    getPostRecords()
    getPostPicks()
    getSchoolData()
  },[]);


/////////////////////////  Button to Start Calculating Weekly Results
/////////////////////////////////////////////////////////////////////

  const handleCalculating = () => 
  {
    // console.log("Matchups data:", matchupsData)
    // console.log("Picks data:", picksData)
    // console.log("Records Original data:", recordsOriginal)

    
    
    // i = generic counter
    // p = means pick
    // u = Usually means user(player)
    // points = points earned this week
    // correctpicks = number of matches correctly picked
    // conatt = confidence attempted this week
    // conwin = confidence won this week
    // dogatt = underdog opportunities
    // dogwin = number of times you correctly picked an underdog to win (only 30% picked = Underdog)
    // trueDogs = total number of underdogs that actually happened
    // percentdog = Number
    var i = 0
    var p = 0
    var u = 0
    var j = 0
    var points = 0
    var correctpicks = 0
    var conatt = 0
    var conwin = 0
    var dogatt = 0
    var dogwin = 0
    var trueDogs = 0    // set the total number of true underdogs to zero
    var percentdog = 0
    var numberofwinners = 0
    var weeklywinnerID = 0
    var bayesianresult = 0
    var predictionaccuracy = 0
    var weeklywinnerTotal = 0
    

    var dog = [0,0,0,0,0,0,0,0,0,0];  // var dog:Array = new Array(0,0,0,0,0,0,0,0,0,0);
                                // How many players picked the home team 
                              // (minus that from total players who played // that week equals picks for away team)	
                              
    //  RESET all stats to original

    for(u=0; u<recordsOriginal.length; u++)
    {
      var recordElement = {}    // must do this in loop for some stupid reason - stumped me for quite a while
      recordElement.user_id = recordsOriginal[u].user_id;
      recordElement.alias = recordsOriginal[u].alias;
      recordElement.weekly_points = 0;
      recordElement.total_points = recordsOriginal[u].total_points;
      recordElement.ranking = recordsOriginal[u].ranking;
      recordElement.accuracy = recordsOriginal[u].accuracy;
      recordElement.weekly_correct_picks = 0;
      recordElement.correct_picks = recordsOriginal[u].correct_picks;
      recordElement.weekly_conf_attempts = 0;
      recordElement.weekly_conf_wins = 0;
      recordElement.conf_attempts = recordsOriginal[u].conf_attempts;
      recordElement.conf_wins = recordsOriginal[u].conf_wins;
      recordElement.wins = recordsOriginal[u].wins;
      recordElement.weekly_underdog_attempts = 0;
      recordElement.weekly_underdogs = 0;
      recordElement.underdog_attempts = recordsOriginal[u].underdog_attempts;
      recordElement.underdogs = recordsOriginal[u].underdogs;
      recordElement.underdogs_possible = recordsOriginal[u].underdogs_possible;
      recordElement.weekly_underdogs_possible = 0;
      recordElement.weeks_played = recordsOriginal[u].weeks_played;
      recordElement.season = recordsOriginal[u].season;
      recordsData.push(recordElement);
    }

    //console.log("START total", recordsData)

    // FIND UNDERDOGS
    // Finds how many picked the Home Team
    for(p=0; p<picksData.length; p++)
    {
      if(picksData[p]['pick0'] === matchupsData[0]['teamH'])
      {
        dog[0] += 1;
      }
      if(picksData[p]['pick1'] === matchupsData[1]['teamH'])
      {
        dog[1] += 1;
      }
      if(picksData[p]['pick2'] === matchupsData[2]['teamH'])
      {
        dog[2] += 1;
      }
      if(picksData[p]['pick3'] === matchupsData[3]['teamH'])
      {
        dog[3] += 1;
      }
      if(picksData[p]['pick4'] === matchupsData[4]['teamH'])
      {
        dog[4] += 1;
      }
      if(picksData[p]['pick5'] === matchupsData[5]['teamH'])
      {
        dog[5] += 1;
      }
      if(picksData[p]['pick6'] === matchupsData[6]['teamH'])
      {
        dog[6] += 1;
      }
      if(picksData[p]['pick7'] === matchupsData[7]['teamH'])
      {
        dog[7] += 1;
      }
      if(picksData[p]['pick8'] === matchupsData[8]['teamH'])
      {
        dog[8] += 1;
      }
      if(picksData[p]['pick9'] === matchupsData[9]['teamH'])
      {
        dog[9] += 1;
      }
    }
      
    //  NOW find if it was an underdog and set value to 1 if underdog is home, -1 if away team and zero if neither team is an underdog
    for(p=0; p<10; p++)
    {
      percentdog = dog[p]/picksData.length;
        
      if(percentdog <= .305)
      {
        dog[p]=1; // Home is an Underdog
        trueDogs+=HdogCatcher(p);
      } else if (percentdog > .695)
      {
        dog[p]= -1; // Away is an Underdog
        trueDogs+=AdogCatcher(p);
      } else 
      {
        dog[p]=0;
      }
    }	
    
    // Calculate for each PICK = p
    for(p=0; p<picksData.length; p++)
    {
      // Everyone gets 20 points for playing this week
      points=20;
      correctpicks=0;
      conatt=0;
      conwin=0;
      dogatt=0;
      dogwin=0;
      
      
      // PICK 0
      //
      // If pick was correct reward 1pt
      if(picksData[p]['pick0']===actualsData[0]['pick0'])
      {
        points += 1;
        correctpicks += 1;
        
        // If Confidence was picked reward 1pt bonus
        if(picksData[p]['confidence0']===1)
        {
          points +=1;
          conatt +=1;
          conwin +=1;
        }
        
        // Check to see if it was an Underdog pick
        if(dog[0]!==0)
        {
          if(dog[0]=== 1 && picksData[p]['pick0']===matchupsData[0]['teamH'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }else if(dog[0]=== -1 && picksData[p]['pick0']===matchupsData[0]['teamA'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }	
          // else you picked the favorite not the underdog ... But you got it right!!!						
        }
      
      // If pick was not correct then zero,
      // BUT if incorrect and Confidence then -2 points
      }else 
      {		
        if(picksData[p]['confidence0']===1)
        {
          points -= 2;
          conatt +=1;
        }
          
        // Did you attempt an Underdog Pick??
        if(dog[0]=== 1 && picksData[p]['pick0']===matchupsData[0]['teamH'])
        {							
          dogatt += 1;							
        }else if(dog[0]=== -1 && picksData[p]['pick0']===matchupsData[0]['teamA'])
        {							
          dogatt += 1;
        }							
      }
      
      
      // PICK 1
      //
      // If pick was correct reward 1pt
      if(picksData[p]['pick1']===actualsData[0]['pick1'])
      {
        points += 1;
        correctpicks += 1;
        
        // If Confidence was picked reward 1pt bonus
        if(picksData[p]['confidence1']===1)
        {
          points +=1;
          conatt +=1;
          conwin +=1;
        }
        
        // Check to see if it was an Underdog pick
        if(dog[1]!==0)
        {
          if(dog[1]=== 1 && picksData[p]['pick1']===matchupsData[1]['teamH'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }else if(dog[1]=== -1 && picksData[p]['pick1']===matchupsData[1]['teamA'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }	
          // else you picked the favorite not the underdog ... But you got it right!!!						
        }
        
        // If pick was not correct then zero,
        // BUT if incorrect and Confidence then -2 points
      }else 
      {
        if(picksData[p]['confidence1']===1)
        {
          points -= 2;
          conatt +=1;
        }
        
        // Did you attempt an Underdog Pick??
        if(dog[1]=== 1 && picksData[p]['pick1']===matchupsData[1]['teamH'])
        {							
          dogatt += 1;							
        }else if(dog[1]=== -1 && picksData[p]['pick1']===matchupsData[1]['teamA'])
        {							
          dogatt += 1;
        }
      }
      
      // PICK 2
      //
      // If pick was correct reward 1pt
      if(picksData[p]['pick2']===actualsData[0]['pick2'])
      {
        points += 1;
        correctpicks += 1;
        
        // If Confidence was picked reward 1pt bonus
        if(picksData[p]['confidence2']===1)
        {
          points +=1;
          conatt +=1;
          conwin +=1;
        }
        
        // Check to see if it was an Underdog pick
        if(dog[2]!==0)
        {
          if(dog[2]=== 1 && picksData[p]['pick2']===matchupsData[2]['teamH'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }else if(dog[2]=== -1 && picksData[p]['pick2']===matchupsData[2]['teamA'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }	
          // else you picked the favorite not the underdog ... But you got it right!!!						
        }

        
        // If pick was not correct then zero,
        // BUT if incorrect and Confidence then -2 points
      }else
      {
        if(picksData[p]['confidence2']===1)
        {
          points -= 2;
          conatt +=1;
        }
        
        // Did you attempt an Underdog Pick??
        if(dog[2]=== 1 && picksData[p]['pick2']===matchupsData[2]['teamH'])
        {							
          dogatt += 1;							
        }else if(dog[2]=== -1 && picksData[p]['pick2']===matchupsData[2]['teamA'])
        {							
          dogatt += 1;
        }
      }
      
      
      // PICK 3
      //
      // If pick was correct reward 1pt
      if(picksData[p]['pick3']===actualsData[0]['pick3'])
      {
        points += 1;
        correctpicks += 1;
        
        // If Confidence was picked reward 1pt bonus
        if(picksData[p]['confidence3']===1)
        {
          points +=1;
          conatt +=1;
          conwin +=1;
        }
        
        // Check to see if it was an Underdog pick
        if(dog[3]!==0)
        {
          if(dog[3]=== 1 && picksData[p]['pick3']===matchupsData[3]['teamH'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }else if(dog[3]=== -1 && picksData[p]['pick3']===matchupsData[3]['teamA'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }	
          // else you picked the favorite not the underdog ... But you got it right!!!						
        }

        // If pick was not correct then zero,
        // BUT if incorrect and Confidence then -2 points
      }else
      {
        if(picksData[p]['confidence3']===1)
        {
          points -= 2;
          conatt +=1;
        }
        
        // Did you attempt an Underdog Pick??
        if(dog[3]=== 1 && picksData[p]['pick3']===matchupsData[3]['teamH'])
        {							
          dogatt += 1;							
        }else if(dog[3]=== -1 && picksData[p]['pick3']===matchupsData[3]['teamA'])
        {							
          dogatt += 1;
        }
      }
      
      
      // PICK 4
      //
      // If pick was correct reward 1pt
      if(picksData[p]['pick4']===actualsData[0]['pick4'])
      {
        points += 1;
        correctpicks += 1;
        
        // If Confidence was picked reward 1pt bonus
        if(picksData[p]['confidence4']===1)
        {
          points +=1;
          conatt +=1;
          conwin +=1;
        }
        // Check to see if it was an Underdog pick
        if(dog[4]!==0)
        {
          if(dog[4]=== 1 && picksData[p]['pick4']===matchupsData[4]['teamH'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }else if(dog[4]=== -1 && picksData[p]['pick4']===matchupsData[4]['teamA'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }	
          // else you picked the favorite not the underdog ... But you got it right!!!						
        }

        // If pick was not correct then zero,
        // BUT if incorrect and Confidence then -2 points
      }else
      {
        if(picksData[p]['confidence4']===1)
        {
          points -= 2;
          conatt +=1;
        }
        
        // Did you attempt an Underdog Pick??
        if(dog[4]=== 1 && picksData[p]['pick4']===matchupsData[4]['teamH'])
        {							
          dogatt += 1;							
        }else if(dog[4]=== -1 && picksData[p]['pick4']===matchupsData[4]['teamA'])
        {							
          dogatt += 1;
        }
      }
      
      
      // PICK 5
      //
      // If pick was correct reward 1pt
      if(picksData[p]['pick5']===actualsData[0]['pick5'])
      {
        points += 1;
        correctpicks += 1;
        
        // If Confidence was picked reward 1pt bonus
        if(picksData[p]['confidence5']===1)
        {
          points +=1;
          conatt +=1;
          conwin +=1;
        }
        
        // Check to see if it was an Underdog pick
        if(dog[5]!==0)
        {
          if(dog[5]=== 1 && picksData[p]['pick5']===matchupsData[5]['teamH'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }else if(dog[5]=== -1 && picksData[p]['pick5']===matchupsData[5]['teamA'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }	
          // else you picked the favorite not the underdog ... But you got it right!!!						
        }

        // If pick was not correct then zero,
        // BUT if incorrect and Confidence then -2 points
      }else
      {
        if(picksData[p]['confidence5']===1)
        {
          points -= 2;
          conatt +=1;
        }
        
        // Did you attempt an Underdog Pick??
        if(dog[5]=== 1 && picksData[p]['pick5']===matchupsData[5]['teamH'])
        {							
          dogatt += 1;							
        }else if(dog[5]=== -1 && picksData[p]['pick5']===matchupsData[5]['teamA'])
        {							
          dogatt += 1;
        }
      }
      
      
      // PICK 6
      //
      // If pick was correct reward 1pt
      if(picksData[p]['pick6']===actualsData[0]['pick6'])
      {
        points += 1;
        correctpicks += 1;
        
        // If Confidence was picked reward 1pt bonus
        if(picksData[p]['confidence6']===1)
        {
          points +=1;
          conatt +=1;
          conwin +=1;
        }
        
        // Check to see if it was an Underdog pick
        if(dog[6]!==0)
        {
          if(dog[6]=== 1 && picksData[p]['pick6']===matchupsData[6]['teamH'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }else if(dog[6]=== -1 && picksData[p]['pick6']===matchupsData[6]['teamA'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }	
          // else you picked the favorite not the underdog ... But you got it right!!!						
        }

        // If pick was not correct then zero,
        // BUT if incorrect and Confidence then -2 points
      }else
      {
        if(picksData[p]['confidence6']===1)
        {
          points -= 2;
          conatt +=1;
        }
        
        // Did you attempt an Underdog Pick??
        if(dog[6]=== 1 && picksData[p]['pick6']===matchupsData[6]['teamH'])
        {							
          dogatt += 1;							
        }else if(dog[6]=== -1 && picksData[p]['pick6']===matchupsData[6]['teamA'])
        {							
          dogatt += 1;
        }
      }
      
      
      // PICK 7
      //
      // If pick was correct reward 1pt
      if(picksData[p]['pick7']===actualsData[0]['pick7'])
      {
        points += 1;
        correctpicks += 1;
        
        // If Confidence was picked reward 1pt bonus
        if(picksData[p]['confidence7']===1)
        {
          points +=1;
          conatt +=1;
          conwin +=1;
        }
        
        // Check to see if it was an Underdog pick
        if(dog[7]!==0)
        {
          if(dog[7]=== 1 && picksData[p]['pick7']===matchupsData[7]['teamH'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }else if(dog[7]=== -1 && picksData[p]['pick7']===matchupsData[7]['teamA'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }	
          // else you picked the favorite not the underdog ... But you got it right!!!						
        }

        // If pick was not correct then zero,
        // BUT if incorrect and Confidence then -2 points
      }else
      {
        if(picksData[p]['confidence7']===1)
        {
          points -= 2;
          conatt +=1;
        }
        
        // Did you attempt an Underdog Pick??
        if(dog[7]=== 1 && picksData[p]['pick7']===matchupsData[7]['teamH'])
        {							
          dogatt += 1;							
        }else if(dog[7]=== -1 && picksData[p]['pick7']===matchupsData[7]['teamA'])
        {							
          dogatt += 1;
        }
      }
      
      
      // PICK 8
      //
      // If pick was correct reward 1pt
      if(picksData[p]['pick8']===actualsData[0]['pick8'])
      {
        points += 1;
        correctpicks += 1;
        
        // If Confidence was picked reward 1pt bonus
        if(picksData[p]['confidence8']===1)
        {
          points +=1;
          conatt +=1;
          conwin +=1;
        }
        
        // Check to see if it was an Underdog pick
        if(dog[8]!==0)
        {
          if(dog[8]=== 1 && picksData[p]['pick8']===matchupsData[8]['teamH'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }else if(dog[8]=== -1 && picksData[p]['pick8']===matchupsData[8]['teamA'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }	
          // else you picked the favorite not the underdog ... But you got it right!!!						
        }

        // If pick was not correct then zero,
        // BUT if incorrect and Confidence then -2 points
      }else
      {
        if(picksData[p]['confidence8']===1)
        {
          points -= 2;
          conatt +=1;
        }
        
        // Did you attempt an Underdog Pick??
        if(dog[8]=== 1 && picksData[p]['pick8']===matchupsData[8]['teamH'])
        {							
          dogatt += 1;							
        }else if(dog[8]=== -1 && picksData[p]['pick8']===matchupsData[8]['teamA'])
        {							
          dogatt += 1;
        }
      }
      
      
      // PICK 9
      //
      // If pick was correct reward 1pt
      if(picksData[p]['pick9']===actualsData[0]['pick9'])
      {
        points += 1;
        correctpicks += 1;
        
        // If Confidence was picked reward 1pt bonus
        if(picksData[p]['confidence9']===1)
        {
          points +=1;
          conatt +=1;
          conwin +=1;
        }
        
        // Check to see if it was an Underdog pick
        if(dog[9]!==0)
        {
          if(dog[9]=== 1 && picksData[p]['pick9']===matchupsData[9]['teamH'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }else if(dog[9]=== -1 && picksData[p]['pick9']===matchupsData[9]['teamA'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }	
          // else you picked the favorite not the underdog ... But you got it right!!!						
        }

        // If pick was not correct then zero,
        // BUT if incorrect and Confidence then -2 points
      }else
      {
        if(picksData[p]['confidence9']===1)
        {
          points -= 2;
          conatt +=1;
        }
        
        // Did you attempt an Underdog Pick??
        if(dog[9]=== 1 && picksData[p]['pick9']===matchupsData[9]['teamH'])
        {							
          dogatt += 1;							
        }else if(dog[9]=== -1 && picksData[p]['pick9']===matchupsData[9]['teamA'])
        {							
          dogatt += 1;
        }
      }
      
      ///////////////////////////////////////////////////////////////////////
      ///////////////////////////////////////////////////////////////////////
      
      // Find correct USER = u for each Pick(p) of the current season records
      for(u=0; u<recordsData.length; u++)
      {
        if(picksData[p]['user_id']===recordsData[u]['user_id'])
        {
          recordsData[u]['weekly_points'] = points;
          recordsData[u]['total_points'] += points;
          recordsData[u]['weekly_correct_picks'] = correctpicks;
          recordsData[u]['correct_picks'] += correctpicks;							
          recordsData[u]['weekly_conf_attempts'] = conatt;
          recordsData[u]['weekly_conf_wins'] = conwin;
          recordsData[u]['conf_attempts'] += conatt;							
          recordsData[u]['conf_wins'] += conwin;							
          recordsData[u]['weekly_underdog_attempts'] = dogatt;
          recordsData[u]['weekly_underdogs'] = dogwin;
          recordsData[u]['underdog_attempts'] += dogatt;							
          recordsData[u]['underdogs'] += dogwin;							
          recordsData[u]['underdogs_possible'] += trueDogs;							
          recordsData[u]['weekly_underdogs_possible'] = trueDogs;
          recordsData[u]['weeks_played'] += 1;
          u=recordsData.length;
        }
      }
    } // p loop end???
    
    // Find out who was the weekly winner (most weekly_points) and update total_points and weeks_played
    // weeklywinnerTotal:Number = Holds the current highest weekly total
    // weeklywinnerID:Array = new Array() - Holds the current weekly winner ID(s)
    // numberofwinners:Number = Holds total number of winners this week
    // j = generic var
    
    weeklywinnerTotal = 1;
    numberofwinners = 0;
    
    for(i=0; i < recordsData.length; i+=1)
    {
      // Check for weekly winner
      if(recordsData[i]['weekly_points'] > weeklywinnerTotal)
      {
        numberofwinners = 0;
        weeklywinnerID = [];
        weeklywinnerTotal = recordsData[i]['weekly_points'];
        weeklywinnerID[numberofwinners] = recordsData[i]['user_id'];
        
      }else if(recordsData[i]['weekly_points'] === weeklywinnerTotal)
      {
        numberofwinners += 1;
        weeklywinnerID[numberofwinners] = recordsData[i]['user_id'];
        
      }
    }
    
    // Award the Bonus Point for weekly winner(s)
    for(i=0; i <= numberofwinners; i+=1)
    {
      // Update weekly points in recordData
      for(j=0; j<recordsData.length; j+=1)
      {
        // find the correct user to update 
        if(recordsData[j]['user_id'] === weeklywinnerID[i])
        {
          // Add the weekly points earned this week
          recordsData[j]['weekly_points'] += 1;
          recordsData[j]['total_points'] += 1;
          
          recordsData[j]['wins'] +=1;
        }						
      }
    }
    
    // Find MaxWeeks BEGINS
    // MaxWeeks is needed to calculate Ranking (Basian)	
    var MaxWeeks = 0;
      
    for (i=0; i < recordsData.length; i++)
    {
      if(recordsData[i]['weeks_played'] > MaxWeeks)
        {
          MaxWeeks=recordsData[i]['weeks_played'];
        }
    } 
    // Find MaxWeeks ENDS

    
    // FIND:  recordsData[i]['ranking']					
    // BAYESIAN PROCESS BEGINS
    //
    // weighted rank (WR) = (w / (w+m)) * R + (m / (w+m)) * C				
    // where:
    //  R = average for the module (mean) = (Rating)
    //	w = number of weeks player played
    //  m = minimum weeks played required (currently 1/4 of total weeks since start of year)
    //  C = the mean of all players (per week avg.) 
    //bayesianresult = (item.weeks_played / (item.weeks_played + (recordData[0].weeks_played/4))) * (item.total_points / item.weeks_played) + ((recordData[0].weeks_played/4) /(item.weeks_played + (recordData[0].weeks_played/4))) * 9.7;
    
    for (i=0; i < recordsData.length; i++)
    {
      if(recordsData[i]['weeks_played'] === 0)
      {
        bayesianresult = 0;
        predictionaccuracy = 0;
      } else if(recordsData[i]['season'] === serverInfo.season ) 		// NEEDS Updating because it is the current season
      {
        bayesianresult = (recordsData[i]['weeks_played'] / (recordsData[i]['weeks_played'] + (MaxWeeks/3))) * (recordsData[i]['total_points'] / recordsData[i]['weeks_played']) + ((MaxWeeks/3) /(recordsData[i]['weeks_played'] + (MaxWeeks/3))) * 24;
        
        // FIND PREDICTION ACCURACY
        predictionaccuracy = (recordsData[i]['correct_picks'] / recordsData[i]['weeks_played']*10);
        // PREDICTION ACCURACY END
      }
      recordsData[i].ranking = bayesianresult.toFixed(2);
      recordsData[i].accuracy = predictionaccuracy.toFixed(2);
    }
    // BAYESIAN PROCESS END

    console.log("FINAL recordsData", recordsData)
    setRecordsD(recordsData)

    //setButtonPush(false)  // Not sure why this worked and now doesn't

  } //end of handleSubmit


  const columns = React.useMemo(
    () => [
      {
        Header: "STANDINGS",
        hstyle: {
          fontSize: '20px',
          textAlign: 'center',
          borderRight: '1px solid black',
        },
        columns: [
          {
            Header: 'Name',
            accessor: 'alias',
            minWidth: 65,
            width: 110,
            hclass: "th blackHeader noElip",
            className: "td tright",
          },
          {
            Header: 'Ranking',
            accessor: 'ranking',
            minWidth: 40,
            width: 49,
            hclass: "th blackHeader noElip",
            className: "td",
          },
          {
            Header: 'Total Points',
            accessor: 'total_points',
            minWidth: 35,
            width: 40,
            hclass: "th blackHeader noElip",
            className: "td",
          },
          {
            Header: 'Weekly Points',
            accessor: 'weekly_points',
            minWidth: 30,
            width: 43,
            hclass: "th blackHeader noElip",
            className: "td",
          },
          {
            Header: 'Accuracy',
            accessor: 'accuracy',
            minWidth: 30,
            width: 49,
            hclass: "th blackHeader noElip",
            className: "td",
          },
          {
            Header: 'Wins',
            accessor: 'wins',
            minWidth: 20,
            width: 38,
            hclass: "th blackHeader noElip",
            className: "td",
          },
          {
            Header: 'Weeks Played',
            accessor: 'weeks_played',
            minWidth: 20,
            width: 45,
            hclass: "th blackHeader noElip",
            className: "td",
          }
        ]
      }
    ],
    []
  )

  if(serverInfo.server !== "CLOSED" ) {
    return(<div>Predictions are still being accepted.  Please wait for prediction deadline to expire.</div>)
  } else {
    return (

      <div className="cfd">
        { ready && <FCSortTable
          columns={columns}
          data={recordsD}
          /* getCellProps={cellInfo => ({
            style: {
              backgroundColor: `hsl(${120 * ((120 - cellInfo.value) / 120) * -1 +
                120}, 100%, 67%)`,
            },
          })}
          getRowProps={row => ({
            style: {
              background: row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
            },
          })} */
        /> }

        { matchupsData && schools && picksData && recordsOriginal ?

          <>
            <div className="explain jcenter mtop20">Select the winners for all 10 games</div>
              
            <form className="needs-validation  jcenter mtop20" onSubmit={formik.handleSubmit}>

              <div className="row jcenter">

                <div className="form-control w180 mright5">
                  <label>Game #1</label>
                  <select
                    className="form-control"
                    name="game0"
                    value={formik.values.game0}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  >
                    <option value=''>Select Winner</option>
                    <option value={matchupsData[0].teamH} >{schools[matchupsData[0].teamH].school}</option>
                    <option value={matchupsData[0].teamA} >{schools[matchupsData[0].teamA].school}</option>
                  </select>
                  {formik.touched.game0 && formik.errors.game0 ? <div className='errors'>{formik.errors.game0}</div> : null}
                </div>
                <div className="form-control w180 mright5">
                  <label>Game #2</label>
                  <select
                    className="form-control"
                    name="game1"
                    value={formik.values.game1}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  >
                    <option value=''>Select Winner</option>
                    <option value={matchupsData[1].teamH} >{schools[matchupsData[1].teamH].school}</option>
                    <option value={matchupsData[1].teamA} >{schools[matchupsData[1].teamA].school}</option>
                  </select>
                  {formik.touched.game1 && formik.errors.game1 ? <div className='errors'>{formik.errors.game1}</div> : null}
                </div>
                <div className="form-control w180 mright5">
                  <label>Game #3</label>
                  <select
                    className="form-control"
                    name="game2"
                    value={formik.values.game2}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  >
                    <option value=''>Select Winner</option>
                    <option value={matchupsData[2].teamH} >{schools[matchupsData[2].teamH].school}</option>
                    <option value={matchupsData[2].teamA} >{schools[matchupsData[2].teamA].school}</option>
                  </select>
                  {formik.touched.game2 && formik.errors.game2 ? <div className='errors'>{formik.errors.game2}</div> : null}
                </div>
                <div className="form-control w180 mright5">
                  <label>Game #4</label>
                  <select
                    className="form-control"
                    name="game3"
                    value={formik.values.game3}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  >
                    <option value=''>Select Winner</option>
                    <option value={matchupsData[3].teamH} >{schools[matchupsData[3].teamH].school}</option>
                    <option value={matchupsData[3].teamA} >{schools[matchupsData[3].teamA].school}</option>
                  </select>
                  {formik.touched.game3 && formik.errors.game3 ? <div className='errors'>{formik.errors.game3}</div> : null}
                </div>
                <div className="form-control w180 mright5">
                  <label>Game #5</label>
                  <select
                    className="form-control"
                    name="game4"
                    value={formik.values.game4}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  >
                    <option value=''>Select Winner</option>
                    <option value={matchupsData[4].teamH} >{schools[matchupsData[4].teamH].school}</option>
                    <option value={matchupsData[4].teamA} >{schools[matchupsData[4].teamA].school}</option>
                  </select>
                  {formik.touched.game4 && formik.errors.game4 ? <div className='errors'>{formik.errors.game4}</div> : null}
                </div>
                <div className="form-control w180 mright5">
                  <label>Game #6</label>
                  <select
                    className="form-control"
                    name="game5"
                    value={formik.values.game5}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  >
                    <option value=''>Select Winner</option>
                    <option value={matchupsData[5].teamH} >{schools[matchupsData[5].teamH].school}</option>
                    <option value={matchupsData[5].teamA} >{schools[matchupsData[5].teamA].school}</option>
                  </select>
                  {formik.touched.game5 && formik.errors.game5 ? <div className='errors'>{formik.errors.game5}</div> : null}
                </div>
                <div className="form-control w180 mright5">
                  <label>Game #7</label>
                  <select
                    className="form-control"
                    name="game6"
                    value={formik.values.game6}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  >
                    <option value=''>Select Winner</option>
                    <option value={matchupsData[6].teamH} >{schools[matchupsData[6].teamH].school}</option>
                    <option value={matchupsData[6].teamA} >{schools[matchupsData[6].teamA].school}</option>
                  </select>
                  {formik.touched.game6 && formik.errors.game6 ? <div className='errors'>{formik.errors.game6}</div> : null}
                </div>
                <div className="form-control w180 mright5">
                  <label>Game #8</label>
                  <select
                    className="form-control"
                    name="game7"
                    value={formik.values.game7}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  >
                    <option value=''>Select Winner</option>
                    <option value={matchupsData[7].teamH} >{schools[matchupsData[7].teamH].school}</option>
                    <option value={matchupsData[7].teamA} >{schools[matchupsData[7].teamA].school}</option>
                  </select>
                  {formik.touched.game7 && formik.errors.game7 ? <div className='errors'>{formik.errors.game7}</div> : null}
                </div>
                <div className="form-control w180 mright5">
                  <label>Game #9</label>
                  <select
                    className="form-control"
                    name="game8"
                    value={formik.values.game8}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  >
                    <option value=''>Select Winner</option>
                    <option value={matchupsData[8].teamH} >{schools[matchupsData[8].teamH].school}</option>
                    <option value={matchupsData[8].teamA} >{schools[matchupsData[8].teamA].school}</option>
                  </select>
                  {formik.touched.game8 && formik.errors.game8 ? <div className='errors'>{formik.errors.game8}</div> : null}
                </div>
                <div className="form-control w180">
                  <label>Game #10</label>
                  <select
                    className="form-control"
                    name="game9"
                    value={formik.values.game9}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  >
                    <option value=''>Select Winner</option>
                    <option value={matchupsData[9].teamH} >{schools[matchupsData[9].teamH].school}</option>
                    <option value={matchupsData[9].teamA} >{schools[matchupsData[9].teamA].school}</option>
                  </select>
                  {formik.touched.game9 && formik.errors.game9 ? <div className='errors'>{formik.errors.game9}</div> : null}
                </div>

                <div className="cfdsubmit mtop20">
                  <button id="btn-login" type="submit" className="btn btn-success btn-sm">Calculate</button>
                </div>
              </div>
            </form>
          </>
        :
          <div>Loading...</div>
        }
      </div>
    )
  }
}

export default ScenariorGenerator