import React from 'react'
import { useTable, useSortBy, useResizeColumns, useFlexLayout } from 'react-table'

const defaultPropGetter = () => ({})

function FCSortTable(
  { columns, 
    data,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  }) {

    // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
    },
    useSortBy,
    useFlexLayout,
    useResizeColumns
  )


  //console.log("columns: ", columns)
  //console.log("data: ", data)
  //console.log("rows: ", rows)
  // console.log("headerGroups: ", headerGroups)

  // Render the UI for your table
  return (
    <>
      <div {...getTableProps()} className="table">
        <div className="header">
          {headerGroups.map((headerGroup, index) => (
            <div
              {...headerGroup.getHeaderGroupProps({
                // style: { paddingRight: '15px' },
              })}
              className="tr"
            >
              {headerGroup.headers.map(column => (
                <div {...column.getHeaderProps([
                  {
                    className: column.hclass,
                    style: column.hstyle,
                  },
                  getColumnProps(column),
                  getHeaderProps(column),
                  column.getSortByToggleProps()
                ])} /* className="th" */>
                  {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  {/* Use column.getResizerProps to hook up the events correctly */}
                  {column.canResize && (
                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${
                        column.isResizing ? 'isResizing' : ''
                      }`}
                    />
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="tbody">
          {rows.map(row => {
            prepareRow(row)
            return (
              <div {...row.getRowProps()} className="tr">
                {row.cells.map(cell => {
                  return (
                    <div {...cell.getCellProps([
                      {
                        className: cell.column.className,
                        style: cell.column.style,
                      },
                      getColumnProps(cell.column),
                      getCellProps(cell),
                    ])} /* className="td" */>
                      {typeof cell.value === "boolean" ?
                        String(cell.value)
                      :
                        cell.render('Cell')
                      }
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default FCSortTable