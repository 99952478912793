import React from 'react'
import dateFormat from 'dateformat'

var ordinal = require('ordinal')

function CFDScoresTable({ data }) {

  //console.log("data: ", data)

  // Render the UI for your table
  return (
    <div>
      <div className="explain">Home team is on the bottom</div>
      { data.length > 0 &&
        <div className="boxscores">

          {data.map((gData, index) => {
            //console.log(dateFormat((gData.startDate), "dddd, mmmm dS, h:MM TT"));
            
            if(gData.clock !==null) {
              var halftime = "no"
              var period = gData.clock
              var [hours, minutes, seconds] = period.split(':');
              if(gData.clock==="00:00:00" && gData.period === 2) {
                halftime = "Halftime"
              }
            }
            return(
              <div className="scoresbox">

                <div className="scoresheader">
                  <div className="scorescolumn">
                    { gData.status ==="completed" && <div className="scoresstatus">FINAL</div> }
                    { gData.status ==="scheduled" && <div className="scoresdate">{ dateFormat((gData.startDate), "dddd, h:MM TT") }</div> }
                    { gData.status !=="scheduled" && gData.status !=="completed" && halftime ==="no" && <div className="scoresclock">{ minutes }:{seconds}&nbsp;&nbsp;{ordinal(gData.period)}</div> }
                    { halftime ==="Halftime" && <div className="scoresclock">{ halftime }</div> }
                  </div>
                  <div className="scorescolumnr">
                    <div className="scorestv">{ gData.tv }</div>
                  </div>
                </div>
                
                <div className="scoresrow lefty">
                  <div className="scorescolumn">
                    <div className="scoresTeamLeft">{ gData.awayTeam.name }</div>
                    <div className="scoresConferenceL">O/U:&nbsp;{ gData.betting.overUnder } &nbsp; { gData.betting.awayMoneyline } &nbsp; ({ gData.awayTeam.conference })</div>
                  </div>
                  <div className="scorescolumn scoresPoints">{ gData.awayTeam.points }</div>
                </div>

                <div className="scoresrow lefty">
                  <div className="scorescolumn">
                    <div className="scoresTeamLeft">{ gData.homeTeam.name }</div>
                    <div className="scoresConferenceL">Line:&nbsp;{ gData.betting.spread } &nbsp; { gData.betting.homeMoneyline } &nbsp; ({ gData.homeTeam.conference })</div>
                  </div>
                  <div className="scorescolumn scoresPoints">{ gData.homeTeam.points }</div>
                </div>

                <div className="scoresbottom">
                  <div className="scorescolumn">
                    <div className="scoresvenue">{ gData.venue.name }</div>
                    <div className="scoresvenue">{ gData.venue.city }, { gData.venue.state }</div>
                  </div>
                  <div className="scorescolumnr">
                    <div className="scoresweatherR">{ gData.weather.temperature }&#176;&nbsp;F</div>
                    <div className="scoresweatherR">Wind: { gData.weather.windSpeed }&nbsp;mph</div>
                  </div>
                </div>

              </div>
            )
          })}
        </div>
      }
    </div>
  )
}

export default CFDScoresTable