import React, { useState } from "react"
import { useNavigate, Link } from "react-router-dom"
import Auth from "../Auth"
import useAxios from 'axios-hooks'
import md5 from "md5";
import Popup from "reactjs-popup"


const Login = ({
  handleLogin
} ) => {
 
  const [user, setUser] = useState('')
  const [ssap, setSsap] = useState('')
  const navigate = useNavigate()

  let codepass 

  const [{ data: userData, loading: isPending, error }, getUserInfo] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/login`,
      method: 'POST'
    },
    { 
      manual: true,
      userCache: false
    }
  )
  
  const handleSubmit = (e) => {
    e.preventDefault();
    codepass=md5(ssap);
      
    async function getPost() {
      try {
        const response = await getUserInfo( { params: { username: user, password: codepass } } )
        //console.log("response", response.data)

        if(response.data === "NO MATCH") {
          setMessage("Incorrect User Name or Password")
          setWarning(true);
        } else {
          Auth.login(() => {
            setMessage("You are logged in")
            setAdded(true);
            navigate("/#/", handleLogin(response.data))
          });
        }
        
      } catch (e) {
        console.log("error in useEffect Login", e)
      }
    }    
    getPost()
  }
  
  const [added, setAdded] = useState(false);
  const [warning, setWarning] = useState(false);
  const [message, setMessage] = useState('');

  const Warning = () => {
    return(
      <Popup
        open={warning}
        className="addplayer-popup"
        onClose={() => setWarning(false)} 
        modal
      >
        {close => (
          <div className="fcmodal">
            <button className="close" onClick={close}>
              X
            </button>
            <div className="header cRed bold">ERROR</div>
            <div className="content">
              {' '}
              <span className="nooutline cyellow">{message}</span>
            </div>
          </div>
        )}
      </Popup>
    )
  }

  const Added = () => {
    return(
      <Popup
        open={added}
        className="addplayer-popup"
        onClose={() => setAdded(false)} 
        modal
      >
        {close => (
          <div className="fcmodal">
            <button className="close" onClick={close}>
              &times;
            </button>
            <div className="header cdeepskyblue bold">WELCOME</div>
            <div className="content cdeepskyblue">
              {' '}
              <span className="nooutline">{message}</span>
            </div>
          </div>
        )}
      </Popup>
    )
  }

  
  return (

    <div className="fcolumn">
      <Warning/>
      <Added/>
      <div id="loginbox">

        <form id="loginform" className="form-horizontal" onSubmit={handleSubmit}>
          <div className="input-group">
            <span className="input-group-addon"><i className="glyphicon glyphicon-user"></i></span>
            <input
              id="login-username"
              type="text"
              placeholder="username"
              autoComplete="username"
              value={user}
              onChange={(e) => setUser(e.target.value)}
            />                                       
          </div>

          <div className="input-group">
            <span className="input-group-addon"><i className="glyphicon glyphicon-lock"></i></span>
            <input
              id="login-password"
              type="password"
              placeholder="password"
              autoComplete="current-password"
              value={ssap}
              onChange={(e) => setSsap(e.target.value)}
            />
          </div>

          <div className="form-group">
            <div className="col-sm-12 controls">
              <button id="btn-login" type="submit" className="btn btn-primary btn-sm">Login  </button>
            </div>
            <div id="passforgot"><Link to="/passreset">Forgot password?</Link></div>
          </div>

          <div className="form-group">
            <div className="col-md-12 control">
              <div id="noaccount">
                <span>Don't have an account!  </span>
                <Link to="/addplayer"> Register Here</Link>
              </div>
            </div>
          </div>    
        </form>                
      </div>
    </div>
  )
}

export default Login