import React from 'react'
import Countdown from 'react-countdown'
import { FaBars } from 'react-icons/fa'

const Header = ({ 
  handleToggleSidebar,
  serverInfo,
}) => {

  //console.log("header",serverInfo)

  // Deadline Over
  const Completionist = () => <span>Deadline Passed!</span>
    
  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return <span>{days} Days {' '} {hours} Hours {' '} {minutes} Minutes </span>;
    }
  }

  

  return (
    <header className="fcHeader">
      <div className="fcolumn">
        <div>
          Prediction Deadline
        </div>
          <div className="countdown">
          {<Countdown 
            date={serverInfo.serverdeadline}
            renderer={renderer}
          />}
        </div>
      </div>
      <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>
    </header>
  )
}

export default Header