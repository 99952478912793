import React from 'react'
import dateFormat from 'dateformat'

function CFDWeatherTable({ data }) {

  //console.log("data: ", data)

  // Render the UI for your table
  return (
    <div>
      <div className="explain">Home team is on the bottom</div>
      { data.length > 0 &&
        <div className="boxscores">

          {data.map((gData, index) => {
            return(
              <div className="scoresbox">

                <div className="scoresheader">
                  <div className="scorescolumn">
                  <div className="scoresdate">{ dateFormat((gData.startTime), "ddd, dd mmm yyyy h:mm TT Z") }</div>
                  </div>
                  <div className="scorescolumnr">
                    <div className="scoresvenueR">{ gData.venue }</div>
                    { gData.gameIndoors ? <div className="scoresvenueR">Indoor</div> :
                    <div className="scoresvenueR">Outdoor</div> }
                  </div>
                </div>
                
                <div className="scoresrow lefty">
                  <div className="scorescolumn">
                    <div className="scoresTeamLeft">{ gData.awayTeam }</div>
                    <div className="scoresConferenceL">({ gData.awayConference })</div>
                  </div>
                  <div className="scorescolumn">
                    <div className="weather">{ gData.temperature }&#176;&nbsp;F</div>
                    <div className="humidity">Humidity: { gData.humidity }</div>
                  </div>
                </div>

                <div className="scoresrow lefty">
                  <div className="scorescolumn">
                    <div className="scoresTeamLeft">{ gData.homeTeam }</div>
                    <div className="scoresConferenceL">({ gData.homeConference })</div>
                  </div>
                  <div className="scorescolumn">
                    { gData.weatherCondition && <div className="weather">{ gData.weatherCondition }</div> }
                    { gData.weatherCondition && <div className="humidity">Code { gData.weatherConditionCode }</div> }
                  </div>
                </div>

                <div className="scoresbottom">
                  <div className="scorescolumn">
                    <div className="scoresweather">Wind: { gData.windSpeed }&nbsp;mph</div>
                    <div className="scoresweather">Direction: { gData.windDirection }</div>
                  </div>
                  <div className="scorescolumnm">
                    <div className="scoresweather">Pressure: { gData.pressure }</div>
                    <div className="scoresweather">Dewpoint: { gData.dewpoint }</div>
                  </div>
                  <div className="scorescolumnr">
                    <div className="scoresweatherR">Rain: { gData.precipitation }</div>
                    <div className="scoresweatherR">Snow: { gData.snowfall }</div>
                  </div>
                </div>

              </div>
            )
          })}
        </div>
      }
    </div>
  )
}

export default CFDWeatherTable