import React, { useState, useEffect } from 'react'
import PicksTable from "./PicksTable"
import useAxios from 'axios-hooks'


const Picks = () => {

  const [schools, setSchools] = useState()
  const [picks, setPicks] = useState()

  const [{ data: picksData, loading: isPending, error }, getPicks] = useAxios( {
    url: `${process.env.REACT_APP_HOST}/picks`
    },
    {
      manual: true,
      useCache:false
    }
  )

  const [{ data: schoolData, loading: getLoading, error: getError }, getSchools] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/getschools`,
    },
    {
      manual: true,
      useCache:false
    }
  )

  const setup = () => {

    async function getSchoolData() {
      try {
        const response = await getSchools()
        //console.log("response schools", response)
        setSchools(response.data)

        async function getPicksData() {
          try {
            const response = await getPicks()
            //console.log("response picks", response)
            setPicks(response.data)
    
          } catch (e) {
            console.log("getPicks error called", error)
          }
        }    
        getPicksData()

      } catch (e) {
        console.log("getSchools error called", error)
      }
    }    
    getSchoolData()
  }

  useEffect(() => {
    setup()
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: '',
        accessor: 'user_name',
        minWidth: 30,
        width: 50,
        hclass: "th blackHeader noElip f12",
        className: "td f12",
      },
      {
        Header: 'Gm 1',
        accessor: 'pick0',
        minWidth: 15,
        width: 15,
        hclass: "th blackHeader noElip f12",
        className: "td f12",
      },
      {
        Header: 'C 1',
        accessor: 'confidence0',
        minWidth: 10,
        width: 10,
        hclass: "th blackHeader noElip f12",
        className: "td f12",
      },
      {
        Header: 'Gm 2',
        accessor: 'pick1',
        minWidth: 15,
        width: 15,
        hclass: "th blackHeader noElip f12",
        className: "td f12",
      },
      {
        Header: 'C 2',
        accessor: 'confidence1',
        minWidth: 10,
        width: 10,
        hclass: "th blackHeader noElip f12",
        className: "td f12",
      },
      {
        Header: 'Gm 3',
        accessor: 'pick2',
        minWidth: 15,
        width: 15,
        hclass: "th blackHeader noElip f12",
        className: "td f12",
      },
      {
        Header: 'C 3',
        accessor: 'confidence2',
        minWidth: 10,
        width: 10,
        hclass: "th blackHeader noElip f12",
        className: "td f12",
      },
      {
        Header: 'Gm 4',
        accessor: 'pick3',
        minWidth: 15,
        width: 15,
        hclass: "th blackHeader noElip f12",
        className: "td f12",
      },
      {
        Header: 'C 4',
        accessor: 'confidence3',
        minWidth: 10,
        width: 10,
        hclass: "th blackHeader noElip f12",
        className: "td f12",
      },
      {
        Header: 'Gm 5',
        accessor: 'pick4',
        minWidth: 15,
        width: 15,
        hclass: "th blackHeader noElip f12",
        className: "td f12",
      },
      {
        Header: 'C 5',
        accessor: 'confidence4',
        minWidth: 10,
        width: 10,
        hclass: "th blackHeader noElip f12",
        className: "td f12",
      },
      {
        Header: 'Gm 6',
        accessor: 'pick5',
        minWidth: 15,
        width: 15,
        hclass: "th blackHeader noElip f12",
        className: "td f12",
      },
      {
        Header: 'C 6',
        accessor: 'confidence5',
        minWidth: 10,
        width: 10,
        hclass: "th blackHeader noElip f12",
        className: "td f12",
      },
      {
        Header: 'Gm 7',
        accessor: 'pick6',
        minWidth: 15,
        width: 15,
        hclass: "th blackHeader noElip f12",
        className: "td f12",
      },
      {
        Header: 'C 7',
        accessor: 'confidence6',
        minWidth: 10,
        width: 10,
        hclass: "th blackHeader noElip f12",
        className: "td f12",
      },
      {
        Header: 'Gm 8',
        accessor: 'pick7',
        minWidth: 15,
        width: 15,
        hclass: "th blackHeader noElip f12",
        className: "td f12",
      },
      {
        Header: 'C 8',
        accessor: 'confidence7',
        minWidth: 10,
        width: 10,
        hclass: "th blackHeader noElip f12",
        className: "td f12",
      },
      {
        Header: 'Gm 9',
        accessor: 'pick8',
        minWidth: 15,
        width: 15,
        hclass: "th blackHeader noElip f12",
        className: "td f12",
      },      
      {
        Header: 'C 9',
        accessor: 'confidence8',
        minWidth: 10,
        width: 10,
        hclass: "th blackHeader noElip f12",
        className: "td f12",
      },
      {
        Header: 'Gm 10',
        accessor: 'pick9',
        minWidth: 15,
        width: 15,
        hclass: "th blackHeader noElip f12",
        className: "td f12",
      },
      {
        Header: 'C 10',
        accessor: 'confidence9',
        minWidth: 10,
        width: 10,
        hclass: "th blackHeader noElip f12",
        className: "td f12",
      }
    ],
    []
  )

  if(!schools || !picks ) {
    return(<div>LOADING...</div>)
  } else {
    return (
      <div className="editData">
        { error ? <div>{ error }</div> : <PicksTable columns={columns} picks={picks} schools={schools} /> }
      </div>
    )
  }
}

export default Picks