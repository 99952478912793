import React from 'react'
import useAxios from 'axios-hooks'
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';


const DBServer = ({ handleServer, serverInfo }) => {

  //console.log(serverInfo.serverdeadline)

  const navigate = useNavigate();

  const validationSchema = Yup.object( {
    season: Yup.number()
      .required('Required'),
    week: Yup.number()
      .required('Required'),
    deadline: Yup.string()
      .required('Required'),
    reveal: Yup.number()
      .required('Required'),
    server: Yup.string()
      .required('Required'),
  } )

  const formik = useFormik({
    initialValues: {
      season: serverInfo.season,
      week: serverInfo.week,
      deadline: serverInfo.serverdeadline,
      reveal: serverInfo.reveal,
      server: serverInfo.server
    },
    onSubmit: values => {
      //console.log(values)

      async function setServerData() {
        try {
          const response = await setServer( { params: { season: values.season, week: values.week, serverdeadline: values.deadline, reveal: values.reveal, server: values.server } })
          //console.log("response", response.data)
          navigate("/#/", handleServer(response.data));

        } catch (e) {
          console.log("error in setting serverData", setError)
        }
      }    
      setServerData()
    },
    validationSchema
  })


  const [{ data: setData, loading: setPending, error: setError }, setServer] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/serverupdate`,
      method: 'POST'
    },
    { 
      manual: true,
      useCache: false
    }
  )
  

  return (

    <div className="cfdForm">

      <form className="needs-validation jcenter mtop20" onSubmit={formik.handleSubmit}>

        <div className="row jcenter">
          <div className="form-control w320 f13" role="group" aria-labelledby="server-group">
            <label> SERVER:&nbsp;&nbsp;
              <input
                type="radio"
                name="server"
                value="OPEN"
                defaultChecked={formik.values.server=== "OPEN"}
                onChange={formik.handleChange}
              /> OPEN (accepting)&nbsp;
            </label>
            <label>
              <input
                type="radio"
                name="server"
                value="LOCKED"
                defaultChecked={formik.values.server=== "LOCKED"}
                onChange={formik.handleChange}
              /> LOCKED (No View)&nbsp;
            </label>
            <label>
              <input
                type="radio"
                name="server"
                value="CLOSED"
                defaultChecked={formik.values.server=== "CLOSED"}
                onChange={formik.handleChange}
              /> CLOSED (No Submit)&nbsp;
            </label>
          </div>
        </div>

        <div className="row jcenter">
          <div className="form-control w320 f13" role="group" aria-labelledby="reveal-group">
            <label>REVEAL:&nbsp;&nbsp; 
              <input
                type="radio"
                name="reveal"
                value={0}
                defaultChecked={formik.values.reveal===0}
                onChange={formik.handleChange}
              /> 0 (View last week's picks)&nbsp;
            </label>
            <label>
              <input
                type="radio"
                name="reveal"
                value={1}
                defaultChecked={formik.values.reveal===1}
                onChange={formik.handleChange}
              /> 1 (View this week's picks)&nbsp;
            </label>
          </div>
        </div>
          
        <div className="row jcenter">
          <div className="form-control w203 f13">
            <label>Deadline:</label>
            <input
              type="text"
              className="form-control f13"
              id="floatingInput1"
              value={formik.values.deadline}
              placeholder="October 27, 2022 12:00:00"
              name="deadline"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {formik.touched.deadline && formik.errors.deadline ? <div className='errors'>{formik.errors.deadline}</div> : null}
          </div>
        
          <div className="form-control w80 mleft5 f13">
            <label>Week:</label>
            <input
              type="text"
              className="form-control f13"
              id="floatingInput1"
              value={formik.values.week}
              placeholder="1"
              name="week"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {formik.touched.week && formik.errors.week ? <div className='errors'>{formik.errors.week}</div> : null}
          </div>

          <div className="form-control w80 mleft5 f13">
            <label>Season :</label>
            <input
              type="text"
              className="form-control f13"
              id="floatingInput1"
              value={formik.values.season}
              placeholder="2022"
              name="season"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {formik.touched.season && formik.errors.season ? <div className='errors'>{formik.errors.season}</div> : null}
          </div>
        </div>
        
        <div className="cfdsubmit">
          <button id="btn-login" type="submit" className="btn btn-dark btn-sm">Submit</button>
        </div>
      
      </form>
    </div>
  )
}

export default DBServer