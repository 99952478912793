import React from 'react'
import Login from './components/Login'
import Matchups from './components/Matchups'
import Users from './components/Users'
import Profile from './components/Profile'
import DBServer from './components/DBServer'
import Winners from './components/Winners'
import SeasonArchive from './components/SeasonArchive'
import AddPlayer from './components/AddPlayer'
import PassReset from './components/PassReset'
import Standings from './components/Standings'
import Predictions from './components/Predictions'
import Submit from './components/Submit'
import Stats from './components/Stats'
import CFDGames from './components/CFDGames'
import CFDRecords from './components/CFDRecords'
import CFDWeather from './components/CFDWeather'
import CFDLine from './components/CFDLine'
import CFDMatchHistory from './components/CFDMatchHistory'
import CFDGameStats from './components/CFDGameStats'
import CFDRoster from './components/CFDRoster'
import CFDScores from './components/CFDScores'
import CFDPlays from './components/CFDPlays'
import CFDDrives from './components/CFDDrives'
import CFDVenues from './components/CFDVenues'
import CFDCoaches from './components/CFDCoaches'
import CFDPolls from './components/CFDPolls'
import { useNavigate, Route, Routes } from 'react-router-dom'
import Logout from './components/Logout'
import Header from './components/Header'
import { PrivateRoute } from './PrivateRoute'
import Brain from './components/Brain'
import ScenarioGenerator from './components/ScenarioGenerator'
import GameSelection from './components/GameSelection'
import Picks from './components/Picks'
import PlayerArchive from './components/PlayerArchive'
import Rules from './components/Rules'
import Popup from "reactjs-popup"


const Main = ({
  handleToggleSidebar,
  toggled,
  userInfo,
  serverInfo,
  handleLogin,
  handleLogout,
  handleServer
}) => {

  const navigate = useNavigate();

  let Draggable = require('react-draggable')

  //console.log("user", {userInfo})

  return (
    <main>
      <Header 
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        serverInfo={serverInfo}
      />
      
      <div className="content">
        <Routes>
          <Route
            exact path="/login"
            element={<>
              <Matchups/>
              <Popup
                open
                modal
                onClose={() => navigate("/#/")} 
                nested
                className="signin-popup"
              >
                {close => (
                  <div className="fcmodal">
                    <button className="close" onClick={close}>
                      &times;
                    </button>
                    <div className="header"> Sign In </div>
                    <div className="content">
                      {' '}
                      <Login handleLogin={handleLogin}/>
                    </div>
                  </div>
                )}
              </Popup>
            </>}
          />
          <Route exact path="/logout" element={<Logout handleLogout={handleLogout} />} />
          <Route exact path="/" element={<Matchups /> } />
          <Route exact path="/rules" element={<Rules />}/>

          { parseInt(userInfo.uid)===1 &&
            <Route element={<PrivateRoute userInfo={userInfo} />} >
              <Route exact path="/winners" element={<Winners userInfo={userInfo} />} />
              <Route exact path="/users" element={<Users userInfo={userInfo} />} />
              <Route exact path="/brain" element={<Brain serverInfo={serverInfo} />} />
              <Route exact path="/server" element={<DBServer serverInfo={serverInfo} handleServer={handleServer} />} />
              <Route exact path="/gameselection" element={<GameSelection />} />
              <Route exact path="/picks" element={<Picks />} />
            </Route>
          }

          <Route element={<PrivateRoute userInfo={userInfo} />} > 
            <Route exact path="/profile" element={<Profile userInfo={userInfo} />} />
            <Route exact path="/submitpicks" element={<Submit serverInfo={serverInfo} userInfo={userInfo} />} />
          </Route>

          <Route exact path="/scenariogenerator" element={<ScenarioGenerator serverInfo={serverInfo} />} />
          <Route exact path="/scores" element={<CFDScores />} />
          <Route exact path="/stats" element={<Stats serverInfo={serverInfo} />} />
          <Route exact path="/seasonarchive" element={<SeasonArchive />} />
          <Route exact path="/playerarchive" element={<PlayerArchive />} />
          <Route exact path="/addplayer" element={<AddPlayer />} />
          <Route exact path="/standings" element={<Standings serverInfo={serverInfo} />} />
          <Route exact path="/predictions" element={<Predictions serverInfo={serverInfo} />} />

          <Route exact path="/cfd/games" element={<CFDGames serverInfo={serverInfo} />} />
          <Route exact path="/cfd/records" element={<CFDRecords serverInfo={serverInfo} />} />
          <Route exact path="/cfd/roster" element={<CFDRoster serverInfo={serverInfo} />} />
          <Route exact path="/cfd/weather" element={<CFDWeather serverInfo={serverInfo} />} />
          <Route exact path="/cfd/line" element={<CFDLine serverInfo={serverInfo} />} />
          <Route exact path="/cfd/matchhistory" element={<CFDMatchHistory serverInfo={serverInfo} />} />
          <Route exact path="/cfd/plays" element={<CFDPlays />} />
          <Route exact path="/cfd/coaches" element={<CFDCoaches serverInfo={serverInfo} />} />
          <Route exact path="/cfd/polls" element={<CFDPolls serverInfo={serverInfo} />} />
          <Route exact path="/cfd/drives" element={<CFDDrives />} />
          <Route exact path="/cfd/venues" element={<CFDVenues/>} />
          <Route exact path="/cfd/gamestats" element={<CFDGameStats serverInfo={serverInfo} />} />
          <Route exact path="/passreset" element={<PassReset />} />
          <Route path="*" element={() => "404 NOT FOUND"} />
        </Routes>
      </div>

      <footer className="fcfooter">
        <small>
          © 2024 made by a {' '}
          <a target="_blank" rel="noopener noreferrer" href="https://www.zionbiblechurch.net">Fool</a>
        </small>
        <br />
        <div className="social-bagdes">
          <a href="https://twitter.com/Filled2TheFool" target="_blank" rel="noopener noreferrer">
            <img
              alt="Twitter Follow"
              src="https://img.shields.io/twitter/follow/Filled2TheFool?label=twitter&style=social"
            />
          </a>
        </div>
      </footer>
    </main>
  );
};

export default Main;