import React, { useState, useEffect } from 'react'
import useAxios from 'axios-hooks'
import { useNavigate } from "react-router-dom"
import Popup from 'reactjs-popup'


const Brain = ({ serverInfo }) => {

  const [isPending, setIsPending] = useState(false)
  const [error, setError] = useState('')

  const [matchupsData, setMatchupsData] = useState()
  const [actualsData, setActualsData] = useState()
  const [recordsData, setRecordsData] = useState()
  const [picksData, setPicksData] = useState()
  const [usersData, setUsersData] = useState()
  const [lifetimeData, setLifetimeData] = useState()
  const [allData, setAllData] = useState()

  const navigate = useNavigate();


  function HdogCatcher(p) {
    var trueDogs = 0
    //check if this were actually an underdog that happened
    switch(p)
    {
      case 0:
        if(matchupsData[p]['teamH'] === actualsData[0]['pick0'])
        {
          trueDogs += 1;
        }
        break;
      
      case 1:
        if(matchupsData[p]['teamH'] === actualsData[0]['pick1'])
        {
          trueDogs += 1;
        }
        break;
      
      case 2:
        if(matchupsData[p]['teamH'] === actualsData[0]['pick2'])
        {
          trueDogs += 1;
        }
        break;
      
      case 3:
        if(matchupsData[p]['teamH'] === actualsData[0]['pick3'])
        {
          trueDogs += 1;
        }
        break;
      
      case 4:
        if(matchupsData[p]['teamH'] === actualsData[0]['pick4'])
        {
          trueDogs += 1;
        }
        break;
      
      case 5:
        if(matchupsData[p]['teamH'] === actualsData[0]['pick5'])
        {
          trueDogs += 1;
        }
        break;
      
      case 6:
        if(matchupsData[p]['teamH'] === actualsData[0]['pick6'])
        {
          trueDogs += 1;
        }
        break;
      
      case 7:
        if(matchupsData[p]['teamH'] === actualsData[0]['pick7'])
        {
          trueDogs += 1;
        }
        break;
      
      case 8:
        if(matchupsData[p]['teamH'] === actualsData[0]['pick8'])
        {
          trueDogs += 1;
        }
        break;
      
      case 9:
        if(matchupsData[p]['teamH'] === actualsData[0]['pick9'])
        {
          trueDogs += 1;
        }
        break;
      
      default:
        break;
    }
    return(trueDogs);
  }

  function AdogCatcher(p) {
    var trueDogs=0;
    
    //check if this were actually an underdog that happened
    switch(p)
    {
      case 0:
        if(matchupsData[p]['teamA'] === actualsData[0]['pick0'])
        {
         trueDogs += 1;
        }
        break;
      
      case 1:
        if(matchupsData[p]['teamA'] === actualsData[0]['pick1'])
        {
          trueDogs += 1;
        }
        break;
      
      case 2:
        if(matchupsData[p]['teamA'] === actualsData[0]['pick2'])
        {
          trueDogs += 1;
        }
        break;
      
      case 3:
        if(matchupsData[p]['teamA'] === actualsData[0]['pick3'])
        {
          trueDogs += 1;
        }
        break;
      
      case 4:
        if(matchupsData[p]['teamA'] === actualsData[0]['pick4'])
        {
          trueDogs += 1;
        }
        break;
      
      case 5:
        if(matchupsData[p]['teamA'] === actualsData[0]['pick5'])
        {
          trueDogs += 1;
        }
        break;
      
      case 6:
        if(matchupsData[p]['teamA'] === actualsData[0]['pick6'])
        {
          trueDogs += 1;
        }
        break;
      
      case 7:
        if(matchupsData[p]['teamA'] === actualsData[0]['pick7'])
        {
          trueDogs += 1;
        }
        break;
      
      case 8:
        if(matchupsData[p]['teamA'] === actualsData[0]['pick8'])
        {
          trueDogs += 1;
        }
        break;
      
      case 9:
        if(matchupsData[p]['teamA'] === actualsData[0]['pick9'])
        {
          trueDogs += 1;
        }
        break;
        
      default:
        break;
    }
    return(trueDogs);
  }

  const [{ data: aData, loading: isPendingActuals, error: errorActuals }, getActuals] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/brain/actuals`,
    },
    {
      manual: true,
      useCache:false
    }
  )

  const [{ data: mData, loading: isPendingMatchups, error: errorMatchups }, getMatchups] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/brain/matchups`,
    },
    {
      manual: true,
      useCache:false
    }
  )

  const [{ data: rData, loading: isPendingRecords, error: errorRecords }, getRecords] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/brain/records`,
      method: 'POST'
    },
    {
      manual: true,
      useCache:false
    }
  )

  const [{ data: pData, loading: isPendingPicks, error: errorPicks }, getPicks] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/brain/picks`,
    },
    {
      manual: true,
      useCache:false
    }
  )

  const [{ data: uData, loading: isPendingUsers, error: errorUsers }, getUsers] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/brain/users`,
    },
    {
      manual: true,
      useCache:false
    }
  )

  const [{ data: lData, loading: isPendingLifetime, error: errorLifetime }, getLifetime] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/brain/lifetime`,
    },
    {
      manual: true,
      useCache:false
    }
  )

  const [{ data: alData, loading: isPendingAll, error: errorAll }, getAll] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/brain/all`,
    },
    {
      manual: true,
      useCache:false
    }
  )

  const [{ data: zData, loading: isPendingZero, error: errorZero }, getZero] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/brain/zero`,
      method: 'POST'
    },
    {
      manual: true,
      useCache:false
    }
  )

  const [{ data: updateRData, loading: isPendingUpdateR, error: errorUpdateR }, getUpdateR] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/brain/updater`,
      method: 'POST'
    },
    {
      manual: true,
      useCache:false
    }
  )

  const [{ data: updatelifetimeData, loading: isPendinglifetimeData, error: errorlifetimeData }, getUpdateLifetime] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/brain/updatelifetime`,
      method: 'POST'
    },
    {
      manual: true,
      useCache:false
    }
  )

  const [{ data: updatepicksarchiveData, loading: isPendingUpdatePicksArchive, error: errorUpdatePicksArchive }, getUpdatePicksArchive] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/brain/updatepicksarchive`,
      method: 'POST'
    },
    {
      manual: true,
      useCache:false
    }
  )

  const [{ data: updatematchupsarchiveData, loading: isPendingUpdateMatchupsArchive, error: errorUpdateMatchupsArchive }, getUpdateMatchupsArchive] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/brain/updatematchupsarchive`,
      method: 'POST'
    },
    {
      manual: true,
      useCache:false
    }
  )

  const [{ data: updateactualsarchiveData, loading: isPendingUpdateActualsArchive, error: errorUpdateActualsArchive }, getUpdateActualsArchive] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/brain/updateactualsarchive`,
      method: 'POST'
    },
    {
      manual: true,
      useCache:false
    }
  )

  const grabData = () => {
      
    async function getPostActuals() {
      try {
        const responseActuals = await getActuals()
        //console.log("responseActuals", responseActuals.data)
        setActualsData(responseActuals.data)
        setTimeout(2000)
        
      } catch (e) {
        console.log("error in Brain Actuals", e)
      }
    }    
    getPostActuals()

    async function getPostMatchups() {
      try {
        const responseMatchups = await getMatchups()
        //console.log("responseMatchups", responseMatchups.data)
        setMatchupsData(responseMatchups.data)
        setTimeout(2000)

      } catch (e) {
        console.log("error in Brain Matchups", e)
      }
    }    
    getPostMatchups()

    async function getPostRecords() {
      try {
        const responseRecords = await getRecords( { params: { season: serverInfo.season } } )
        //console.log("responseRecords", responseRecords.data)
        setRecordsData(responseRecords.data)
        setTimeout(2000)

      } catch (e) {
        console.log("error in Brain Records", e)
      }
    }    
    getPostRecords()

    async function getPostPicks() {
      try {
        const responsePicks = await getPicks()
        //console.log("responsePicks", responsePicks.data)
        setPicksData(responsePicks.data)
        setTimeout(2000)

      } catch (e) {
        console.log("error in Brain Picks", e)
      }
    }    
    getPostPicks()

    async function getPostUsers() {
      try {
        const responseUsers = await getUsers()
        //console.log("responseUsers", responseUsers.data)
        setUsersData(responseUsers.data)
        setTimeout(2000)

      } catch (e) {
        console.log("error in Brain Users", e)
      }
    }    
    getPostUsers()

    async function getPostLifetime() {
      try {
        const responseLifetime = await getLifetime()
        //console.log("responseLifetime", responseLifetime.data)
        setLifetimeData(responseLifetime.data)
        setTimeout(2000)

      } catch (e) {
        console.log("error in Brain Lifetime", e)
      }
    }    
    getPostLifetime()

    async function getPostAll() {
      try {
        const responseAll = await getAll()
        //console.log("responseAll", responseAll.data)
        setAllData(responseAll.data)
        setTimeout(2000)

      } catch (e) {
        console.log("error in Brain All", e)
      }
    }    
    getPostAll()
  }


  useEffect(() => {
    grabData()
  },[]);

/////////////////////////  Button to Start Calculating Weekly Results
/////////////////////////////////////////////////////////////////////

  const handleCalculating = (e) => 
  {
    e.preventDefault();

    // console.log("Matchups data:", matchupsData)
    // console.log("Actuals data:", actualsData)
    // console.log("Picks data:", picksData)
    // console.log("Records data:", recordsData)
    // console.log("Users data:", usersData)
    // console.log("Lifetime data:", lifetimeData)
    // console.log("All data:", allData)
    
    // i = generic counter
    // p = means pick
    // u = Usually means user(player)
    // points = points earned this week
    // correctpicks = number of matches correctly picked
    // conatt = confidence attempted this week
    // conwin = confidence won this week
    // dogatt = underdog opportunities
    // dogwin = number of times you correctly picked an underdog to win (only 30% picked = Underdog)
    // trueDogs = total number of underdogs that actually happened
    // percentdog = Number
    var i = 0
    var p = 0
    var u = 0
    var j = 0
    var points = 0
    var correctpicks = 0
    var conatt = 0
    var conwin = 0
    var dogatt = 0
    var dogwin = 0
    var trueDogs = 0    // set the total number of true underdogs to zero
    var percentdog = 0
    var numberofwinners = 0
    var weeklywinnerID = 0
    var bayesianresult = 0
    var predictionaccuracy = 0
    var weeklywinnerTotal = 0

    var dog = [0,0,0,0,0,0,0,0,0,0];  // var dog:Array = new Array(0,0,0,0,0,0,0,0,0,0);
                                // How many players picked the home team 
                              // (minus that from total players who played // that week equals picks for away team)			

    // Zero the weekly stats for every player who has played during the current season
    /* async function getZ() {
      try {
        const responseZero = await getZero( { data: { recordsData } } )
        //console.log("responseZero", responseZero.data)

      } catch (e) {
        console.log("error in Brain Zero", e)
      }
    }    
    getZ() */


    // First reset everyone's weekly points
    for(i=0; i < recordsData.length; i+=1)
    {
      recordsData[i]['weekly_points'] = 0;
      recordsData[i]['weekly_conf_attempts'] = 0;
      recordsData[i]['weekly_conf_wins'] = 0;
      recordsData[i]['weekly_underdog_attempts'] = 0;
      recordsData[i]['weekly_underdogs'] = 0;
      recordsData[i]['weekly_correct_picks'] = 0;
    }

    // FIND UNDERDOGS
    // Finds how many picked the Home Team
    for(p=0; p<picksData.length; p++)
    {
      if(picksData[p]['pick0'] === matchupsData[0]['teamH'])
      {
        dog[0] += 1;
      }
      if(picksData[p]['pick1'] === matchupsData[1]['teamH'])
      {
        dog[1] += 1;
      }
      if(picksData[p]['pick2'] === matchupsData[2]['teamH'])
      {
        dog[2] += 1;
      }
      if(picksData[p]['pick3'] === matchupsData[3]['teamH'])
      {
        dog[3] += 1;
      }
      if(picksData[p]['pick4'] === matchupsData[4]['teamH'])
      {
        dog[4] += 1;
      }
      if(picksData[p]['pick5'] === matchupsData[5]['teamH'])
      {
        dog[5] += 1;
      }
      if(picksData[p]['pick6'] === matchupsData[6]['teamH'])
      {
        dog[6] += 1;
      }
      if(picksData[p]['pick7'] === matchupsData[7]['teamH'])
      {
        dog[7] += 1;
      }
      if(picksData[p]['pick8'] === matchupsData[8]['teamH'])
      {
        dog[8] += 1;
      }
      if(picksData[p]['pick9'] === matchupsData[9]['teamH'])
      {
        dog[9] += 1;
      }
    }
      
    //  NOW find if it was an underdog and set value to 1 if underdog is home, -1 if away team and zero if neither team is an underdog
    for(p=0; p<10; p++)
    {
      percentdog = dog[p]/picksData.length;
        
      if(percentdog <= .305)
      {
        dog[p]=1; // Home is an Underdog
        trueDogs+=HdogCatcher(p);
      } else if (percentdog > .695)
      {
        dog[p]= -1; // Away is an Underdog
        trueDogs+=AdogCatcher(p);
      } else 
      {
        dog[p]=0;
      }
    }	
    
    // Calculate for each PICK = p
    for(p=0; p<picksData.length; p++)
    {
      // Everyone gets 20 points for playing this week
      points=20;
      correctpicks=0;
      conatt=0;
      conwin=0;
      dogatt=0;
      dogwin=0;
      
      
      // PICK 0
      //
      // If pick was correct reward 1pt
      if(picksData[p]['pick0']===actualsData[0]['pick0'])
      {
        points += 1;
        correctpicks += 1;
        
        // If Confidence was picked reward 1pt bonus
        if(picksData[p]['confidence0']===1)
        {
          points +=1;
          conatt +=1;
          conwin +=1;
        }
        
        // Check to see if it was an Underdog pick
        if(dog[0]!==0)
        {
          if(dog[0]=== 1 && picksData[p]['pick0']===matchupsData[0]['teamH'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }else if(dog[0]=== -1 && picksData[p]['pick0']===matchupsData[0]['teamA'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }	
          // else you picked the favorite not the underdog ... But you got it right!!!						
        }
      
      // If pick was not correct then zero,
      // BUT if incorrect and Confidence then -2 points
      }else 
      {		
        if(picksData[p]['confidence0']===1)
        {
          points -= 2;
          conatt +=1;
        }
          
        // Did you attempt an Underdog Pick??
        if(dog[0]=== 1 && picksData[p]['pick0']===matchupsData[0]['teamH'])
        {							
          dogatt += 1;							
        }else if(dog[0]=== -1 && picksData[p]['pick0']===matchupsData[0]['teamA'])
        {							
          dogatt += 1;
        }							
      }
      
      
      // PICK 1
      //
      // If pick was correct reward 1pt
      if(picksData[p]['pick1']===actualsData[0]['pick1'])
      {
        points += 1;
        correctpicks += 1;
        
        // If Confidence was picked reward 1pt bonus
        if(picksData[p]['confidence1']===1)
        {
          points +=1;
          conatt +=1;
          conwin +=1;
        }
        
        // Check to see if it was an Underdog pick
        if(dog[1]!==0)
        {
          if(dog[1]=== 1 && picksData[p]['pick1']===matchupsData[1]['teamH'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }else if(dog[1]=== -1 && picksData[p]['pick1']===matchupsData[1]['teamA'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }	
          // else you picked the favorite not the underdog ... But you got it right!!!						
        }
        
        // If pick was not correct then zero,
        // BUT if incorrect and Confidence then -2 points
      }else 
      {
        if(picksData[p]['confidence1']===1)
        {
          points -= 2;
          conatt +=1;
        }
        
        // Did you attempt an Underdog Pick??
        if(dog[1]=== 1 && picksData[p]['pick1']===matchupsData[1]['teamH'])
        {							
          dogatt += 1;							
        }else if(dog[1]=== -1 && picksData[p]['pick1']===matchupsData[1]['teamA'])
        {							
          dogatt += 1;
        }
      }
      
      // PICK 2
      //
      // If pick was correct reward 1pt
      if(picksData[p]['pick2']===actualsData[0]['pick2'])
      {
        points += 1;
        correctpicks += 1;
        
        // If Confidence was picked reward 1pt bonus
        if(picksData[p]['confidence2']===1)
        {
          points +=1;
          conatt +=1;
          conwin +=1;
        }
        
        // Check to see if it was an Underdog pick
        if(dog[2]!==0)
        {
          if(dog[2]=== 1 && picksData[p]['pick2']===matchupsData[2]['teamH'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }else if(dog[2]=== -1 && picksData[p]['pick2']===matchupsData[2]['teamA'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }	
          // else you picked the favorite not the underdog ... But you got it right!!!						
        }

        
        // If pick was not correct then zero,
        // BUT if incorrect and Confidence then -2 points
      }else
      {
        if(picksData[p]['confidence2']===1)
        {
          points -= 2;
          conatt +=1;
        }
        
        // Did you attempt an Underdog Pick??
        if(dog[2]=== 1 && picksData[p]['pick2']===matchupsData[2]['teamH'])
        {							
          dogatt += 1;							
        }else if(dog[2]=== -1 && picksData[p]['pick2']===matchupsData[2]['teamA'])
        {							
          dogatt += 1;
        }
      }
      
      
      // PICK 3
      //
      // If pick was correct reward 1pt
      if(picksData[p]['pick3']===actualsData[0]['pick3'])
      {
        points += 1;
        correctpicks += 1;
        
        // If Confidence was picked reward 1pt bonus
        if(picksData[p]['confidence3']===1)
        {
          points +=1;
          conatt +=1;
          conwin +=1;
        }
        
        // Check to see if it was an Underdog pick
        if(dog[3]!==0)
        {
          if(dog[3]=== 1 && picksData[p]['pick3']===matchupsData[3]['teamH'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }else if(dog[3]=== -1 && picksData[p]['pick3']===matchupsData[3]['teamA'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }	
          // else you picked the favorite not the underdog ... But you got it right!!!						
        }

        // If pick was not correct then zero,
        // BUT if incorrect and Confidence then -2 points
      }else
      {
        if(picksData[p]['confidence3']===1)
        {
          points -= 2;
          conatt +=1;
        }
        
        // Did you attempt an Underdog Pick??
        if(dog[3]=== 1 && picksData[p]['pick3']===matchupsData[3]['teamH'])
        {							
          dogatt += 1;							
        }else if(dog[3]=== -1 && picksData[p]['pick3']===matchupsData[3]['teamA'])
        {							
          dogatt += 1;
        }
      }
      
      
      // PICK 4
      //
      // If pick was correct reward 1pt
      if(picksData[p]['pick4']===actualsData[0]['pick4'])
      {
        points += 1;
        correctpicks += 1;
        
        // If Confidence was picked reward 1pt bonus
        if(picksData[p]['confidence4']===1)
        {
          points +=1;
          conatt +=1;
          conwin +=1;
        }
        // Check to see if it was an Underdog pick
        if(dog[4]!==0)
        {
          if(dog[4]=== 1 && picksData[p]['pick4']===matchupsData[4]['teamH'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }else if(dog[4]=== -1 && picksData[p]['pick4']===matchupsData[4]['teamA'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }	
          // else you picked the favorite not the underdog ... But you got it right!!!						
        }

        // If pick was not correct then zero,
        // BUT if incorrect and Confidence then -2 points
      }else
      {
        if(picksData[p]['confidence4']===1)
        {
          points -= 2;
          conatt +=1;
        }
        
        // Did you attempt an Underdog Pick??
        if(dog[4]=== 1 && picksData[p]['pick4']===matchupsData[4]['teamH'])
        {							
          dogatt += 1;							
        }else if(dog[4]=== -1 && picksData[p]['pick4']===matchupsData[4]['teamA'])
        {							
          dogatt += 1;
        }
      }
      
      
      // PICK 5
      //
      // If pick was correct reward 1pt
      if(picksData[p]['pick5']===actualsData[0]['pick5'])
      {
        points += 1;
        correctpicks += 1;
        
        // If Confidence was picked reward 1pt bonus
        if(picksData[p]['confidence5']===1)
        {
          points +=1;
          conatt +=1;
          conwin +=1;
        }
        
        // Check to see if it was an Underdog pick
        if(dog[5]!==0)
        {
          if(dog[5]=== 1 && picksData[p]['pick5']===matchupsData[5]['teamH'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }else if(dog[5]=== -1 && picksData[p]['pick5']===matchupsData[5]['teamA'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }	
          // else you picked the favorite not the underdog ... But you got it right!!!						
        }

        // If pick was not correct then zero,
        // BUT if incorrect and Confidence then -2 points
      }else
      {
        if(picksData[p]['confidence5']===1)
        {
          points -= 2;
          conatt +=1;
        }
        
        // Did you attempt an Underdog Pick??
        if(dog[5]=== 1 && picksData[p]['pick5']===matchupsData[5]['teamH'])
        {							
          dogatt += 1;							
        }else if(dog[5]=== -1 && picksData[p]['pick5']===matchupsData[5]['teamA'])
        {							
          dogatt += 1;
        }
      }
      
      
      // PICK 6
      //
      // If pick was correct reward 1pt
      if(picksData[p]['pick6']===actualsData[0]['pick6'])
      {
        points += 1;
        correctpicks += 1;
        
        // If Confidence was picked reward 1pt bonus
        if(picksData[p]['confidence6']===1)
        {
          points +=1;
          conatt +=1;
          conwin +=1;
        }
        
        // Check to see if it was an Underdog pick
        if(dog[6]!==0)
        {
          if(dog[6]=== 1 && picksData[p]['pick6']===matchupsData[6]['teamH'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }else if(dog[6]=== -1 && picksData[p]['pick6']===matchupsData[6]['teamA'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }	
          // else you picked the favorite not the underdog ... But you got it right!!!						
        }

        // If pick was not correct then zero,
        // BUT if incorrect and Confidence then -2 points
      }else
      {
        if(picksData[p]['confidence6']===1)
        {
          points -= 2;
          conatt +=1;
        }
        
        // Did you attempt an Underdog Pick??
        if(dog[6]=== 1 && picksData[p]['pick6']===matchupsData[6]['teamH'])
        {							
          dogatt += 1;							
        }else if(dog[6]=== -1 && picksData[p]['pick6']===matchupsData[6]['teamA'])
        {							
          dogatt += 1;
        }
      }
      
      
      // PICK 7
      //
      // If pick was correct reward 1pt
      if(picksData[p]['pick7']===actualsData[0]['pick7'])
      {
        points += 1;
        correctpicks += 1;
        
        // If Confidence was picked reward 1pt bonus
        if(picksData[p]['confidence7']===1)
        {
          points +=1;
          conatt +=1;
          conwin +=1;
        }
        
        // Check to see if it was an Underdog pick
        if(dog[7]!==0)
        {
          if(dog[7]=== 1 && picksData[p]['pick7']===matchupsData[7]['teamH'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }else if(dog[7]=== -1 && picksData[p]['pick7']===matchupsData[7]['teamA'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }	
          // else you picked the favorite not the underdog ... But you got it right!!!						
        }

        // If pick was not correct then zero,
        // BUT if incorrect and Confidence then -2 points
      }else
      {
        if(picksData[p]['confidence7']===1)
        {
          points -= 2;
          conatt +=1;
        }
        
        // Did you attempt an Underdog Pick??
        if(dog[7]=== 1 && picksData[p]['pick7']===matchupsData[7]['teamH'])
        {							
          dogatt += 1;							
        }else if(dog[7]=== -1 && picksData[p]['pick7']===matchupsData[7]['teamA'])
        {							
          dogatt += 1;
        }
      }
      
      
      // PICK 8
      //
      // If pick was correct reward 1pt
      if(picksData[p]['pick8']===actualsData[0]['pick8'])
      {
        points += 1;
        correctpicks += 1;
        
        // If Confidence was picked reward 1pt bonus
        if(picksData[p]['confidence8']===1)
        {
          points +=1;
          conatt +=1;
          conwin +=1;
        }
        
        // Check to see if it was an Underdog pick
        if(dog[8]!==0)
        {
          if(dog[8]=== 1 && picksData[p]['pick8']===matchupsData[8]['teamH'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }else if(dog[8]=== -1 && picksData[p]['pick8']===matchupsData[8]['teamA'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }	
          // else you picked the favorite not the underdog ... But you got it right!!!						
        }

        // If pick was not correct then zero,
        // BUT if incorrect and Confidence then -2 points
      }else
      {
        if(picksData[p]['confidence8']===1)
        {
          points -= 2;
          conatt +=1;
        }
        
        // Did you attempt an Underdog Pick??
        if(dog[8]=== 1 && picksData[p]['pick8']===matchupsData[8]['teamH'])
        {							
          dogatt += 1;							
        }else if(dog[8]=== -1 && picksData[p]['pick8']===matchupsData[8]['teamA'])
        {							
          dogatt += 1;
        }
      }
      
      
      // PICK 9
      //
      // If pick was correct reward 1pt
      if(picksData[p]['pick9']===actualsData[0]['pick9'])
      {
        points += 1;
        correctpicks += 1;
        
        // If Confidence was picked reward 1pt bonus
        if(picksData[p]['confidence9']===1)
        {
          points +=1;
          conatt +=1;
          conwin +=1;
        }
        
        // Check to see if it was an Underdog pick
        if(dog[9]!==0)
        {
          if(dog[9]=== 1 && picksData[p]['pick9']===matchupsData[9]['teamH'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }else if(dog[9]=== -1 && picksData[p]['pick9']===matchupsData[9]['teamA'])
          {
            points += 1;
            dogatt += 1;
            dogwin += 1;
          }	
          // else you picked the favorite not the underdog ... But you got it right!!!						
        }

        // If pick was not correct then zero,
        // BUT if incorrect and Confidence then -2 points
      }else
      {
        if(picksData[p]['confidence9']===1)
        {
          points -= 2;
          conatt +=1;
        }
        
        // Did you attempt an Underdog Pick??
        if(dog[9]=== 1 && picksData[p]['pick9']===matchupsData[9]['teamH'])
        {							
          dogatt += 1;							
        }else if(dog[9]=== -1 && picksData[p]['pick9']===matchupsData[9]['teamA'])
        {							
          dogatt += 1;
        }
      }
      
      ///////////////////////////////////////////////////////////////////////
      ///////////////////////////////////////////////////////////////////////
      
      // Find correct USER = u for each Pick(p) of the current season records
      for(u=0; u<recordsData.length; u++)
      {
        if(picksData[p]['user_id']===recordsData[u]['user_id'])
        {
          recordsData[u]['weekly_points'] = points;
          recordsData[u]['total_points'] += points;
          recordsData[u]['weekly_correct_picks'] = correctpicks;
          recordsData[u]['correct_picks'] += correctpicks;							
          recordsData[u]['weekly_conf_attempts'] = conatt;
          recordsData[u]['weekly_conf_wins'] = conwin;
          recordsData[u]['conf_attempts'] += conatt;							
          recordsData[u]['conf_wins'] += conwin;							
          recordsData[u]['weekly_underdog_attempts'] = dogatt;
          recordsData[u]['weekly_underdogs'] = dogwin;
          recordsData[u]['underdog_attempts'] += dogatt;							
          recordsData[u]['underdogs'] += dogwin;							
          recordsData[u]['underdogs_possible'] += trueDogs;							
          recordsData[u]['weekly_underdogs_possible'] = trueDogs;
          recordsData[u]['weeks_played'] += 1;
          u=recordsData.length;	
        }						
      }
      
      // Find correct USER = u for each Pick(p) of the LIFETIME records
      for(u=0; u<lifetimeData.length; u++)
      {
        if(picksData[p]['user_id']===lifetimeData[u]['user_id'])
        {
          lifetimeData[u]['weekly_points'] = 0;
          lifetimeData[u]['total_points'] += points;
          lifetimeData[u]['weekly_correct_picks'] = 0;
          lifetimeData[u]['correct_picks'] += correctpicks;							
          lifetimeData[u]['weekly_conf_attempts'] = 0;
          lifetimeData[u]['weekly_conf_wins'] = 0;
          lifetimeData[u]['conf_attempts'] += conatt;							
          lifetimeData[u]['conf_wins'] += conwin;							
          lifetimeData[u]['weekly_underdog_attempts'] = 0;
          lifetimeData[u]['weekly_underdogs'] = 0;
          lifetimeData[u]['underdog_attempts'] += dogatt;							
          lifetimeData[u]['underdogs'] += dogwin;							
          lifetimeData[u]['underdogs_possible'] += trueDogs;							
          lifetimeData[u]['weekly_underdogs_possible'] = 0;
          lifetimeData[u]['weeks_played'] += 1;	

          u=lifetimeData.length;	
        }	
      }
    } // p loop end???
    
    // Find out who was the weekly winner (most weekly_points) and update total_points and weeks_played
    // weeklywinnerTotal:Number = Holds the current highest weekly total
    // weeklywinnerID:Array = new Array() - Holds the current weekly winner ID(s)
    // numberofwinners:Number = Holds total number of winners this week
    // j = generic var
    
    weeklywinnerTotal = 1;
    numberofwinners = 0;
    
    for(i=0; i < recordsData.length; i+=1)
    {
      // Check for weekly winner
      if(recordsData[i]['weekly_points'] > weeklywinnerTotal)
      {
        numberofwinners = 0;
        weeklywinnerID = [];
        weeklywinnerTotal = recordsData[i]['weekly_points'];
        weeklywinnerID[numberofwinners] = recordsData[i]['user_id'];
        
      }else if(recordsData[i]['weekly_points'] === weeklywinnerTotal)
      {
        numberofwinners += 1;
        weeklywinnerID[numberofwinners] = recordsData[i]['user_id'];
        
      }
    }
    
    // Award the Bonus Point for weekly winner(s)
    for(i=0; i <= numberofwinners; i+=1)
    {
      // Update weekly points in recordData
      for(j=0; j<recordsData.length; j+=1)
      {
        // find the correct user to update 
        if(recordsData[j]['user_id'] === weeklywinnerID[i])
        {
          // Add the weekly points earned this week
          recordsData[j]['weekly_points'] += 1;
          recordsData[j]['total_points'] += 1;
          
          recordsData[j]['wins'] +=1;
        }						
      }
      // Find correct USER = j for each Pick(p) of the LIFETIME records
      for(j=0; j<lifetimeData.length; j+=1)
      {
        if(lifetimeData[j]['user_id']=== weeklywinnerID[i])
        {
          lifetimeData[j]['total_points'] += 1;
          lifetimeData[j]['wins'] +=1;
        }	
      }
    }
    
    // Find MaxWeeks BEGINS
    // MaxWeeks is needed to calculate Ranking (Basian)	
    var MaxWeeks = 0;
      
    for (i=0; i < recordsData.length; i++)
    {
      if(recordsData[i]['weeks_played'] > MaxWeeks)
        {
          MaxWeeks=recordsData[i]['weeks_played'];
        }
    } 
    // Find MaxWeeks ENDS

    
    // FIND:  recordsData[i]['ranking']					
    // BAYESIAN PROCESS BEGINS
    //
    // weighted rank (WR) = (w / (w+m)) * R + (m / (w+m)) * C				
    // where:
    //  R = average for the module (mean) = (Rating)
    //	w = number of weeks player played
    //  m = minimum weeks played required (currently 1/4 of total weeks since start of year)
    //  C = the mean of all players (per week avg.) 
    //bayesianresult = (item.weeks_played / (item.weeks_played + (recordData[0].weeks_played/4))) * (item.total_points / item.weeks_played) + ((recordData[0].weeks_played/4) /(item.weeks_played + (recordData[0].weeks_played/4))) * 9.7;
    
    for (i=0; i < recordsData.length; i++)
    {				
      if(recordsData[i]['weeks_played'] === 0)
        {
          bayesianresult = 0;
          predictionaccuracy = 0;
        } else if(recordsData[i]['season'] === serverInfo.season ) 		// NEEDS Updating because it is the current season
        {
          bayesianresult = (recordsData[i]['weeks_played'] / (recordsData[i]['weeks_played'] + (MaxWeeks/3))) * (recordsData[i]['total_points'] / recordsData[i]['weeks_played']) + ((MaxWeeks/3) /(recordsData[i]['weeks_played'] + (MaxWeeks/3))) * 24;
          // FIND PREDICTION ACCURACY
          predictionaccuracy = (recordsData[i]['correct_picks'] / recordsData[i]['weeks_played']*10);
          // PREDICTION ACCURACY END
        }
      recordsData[i]['ranking'] = bayesianresult.toFixed(2);
      recordsData[i]['accuracy'] = predictionaccuracy.toFixed(2);
      
    }
    
    // BAYESIAN PROCESS END
                    
     
    // SAVE PROCESS BEGINS
    //
    //
    // Save all RECORDS for this current Season (which is $totalRows_rsCRecords)
    
    // IT IS SAFE TO ASSUME IT MUST ALREADY HAVE A LIFETIME RECORD TOO
    // Update with the found RECORDSDATA for CURRENT SEASON (NOT LIFETIME) from line directly above
    
    
      
    ////////////////////////////////////////////////////////////////////////
    for( u=0; u < recordsData.length; u++) 
    {	          
      // Update LIFETIME 						
      for(var k=0; k < lifetimeData.length; k++) {	// Find their recordData row
    
        if(recordsData[u]['user_id'] === lifetimeData[k]['user_id'] ) {								
          // LIFETIME UPDATE

          // LIFETIME PART:  Now do BAYESIAN process for lifetime records (it is a different result!)
          //FIRST: load ALL SEASONS data so it is up to date for Baysian and accuracy
          var archivecount = 0;
          var archivetotal = 0;
      
          for (var gu=0; gu < allData.length; gu++)
          {
            if(allData[gu]['user_id'] === lifetimeData[k]['user_id'] && allData[gu]['season'] !== "lifetime" )
            {
              archivecount += 1; 	// How many seasons did you play
              if(allData[gu]['season'] === serverInfo.season ) 
              {	
                //  Need to get current ranking (figured just above) not the loaded ranking at start of TheBrain.php
                archivetotal += recordsData[u]['ranking'];	// total up all rankings
              } else
              {
                archivetotal += allData[gu]['ranking'];	// total up all rankings
              }
            }
          }	// gu loop end
          lifetimeData[k]['ranking'] = parseFloat(archivetotal)/archivecount;	// Just take the average of each seasons rankings
          //console.log("ranking lifetime", lifetimeData[k]['ranking'])
        
          // FIND PREDICTION ACCURACY
          predictionaccuracy = (lifetimeData[k]['correct_picks'] / lifetimeData[k]['weeks_played']*10);
          lifetimeData[k]['accuracy'] = Math.round(predictionaccuracy, 2);
          //console.log("accuracy lifetime", lifetimeData[k]['accuracy'])
          // PREDICTION ACCURACY END
        }	// if end
      }	// k loop end
    }	  // u loop end

    async function getUp() {
      try {
        const responseUpdateR = await getUpdateR( { data: {
          recordsData,
          season: serverInfo.season
        } } )
        //console.log("responseUpdateR", responseUpdateR.data, recordsData)

        getUpMatchupsArchive()

      } catch (e) {
        console.log("error in Brain UpdateR", e)
      }
    }    
    
    // Pre-2013 teams require these additional datafields
    async function getUpMatchupsArchive() {
      try {
        const responseUpdateMatchupsArchive = await getUpdateMatchupsArchive( { data: {
          matchupsData,
          week: serverInfo.week,
          season: serverInfo.season
        } } )
        //console.log("responseUpdateMatchupsArchive", responseUpdateMatchupsArchive.data)

        getUpActualsArchive()

      } catch (e) {
        console.log("error in Brain UpdateMatchupsArchive", e)
      }
    }    

    async function getUpActualsArchive() {
      try {
        const responseUpdateActualsArchive = await getUpdateActualsArchive( { data: {
          actualsData,
          week: serverInfo.week,
          season: serverInfo.season
        } } )
        //console.log("responseUpdateActualsArchive", responseUpdateActualsArchive.data)

        getUpPickArchive()

      } catch (e) {
        console.log("error in Brain UpdateActualsArchive", e)
      }
    }

    // Save the picks for that week
    async function getUpPickArchive() {
      //console.log("responsePicksArchive", picksData)
      try {
        const responseUpdatePicksArchive = await getUpdatePicksArchive( { data: {
          picksData,
          week: serverInfo.week,
          season: serverInfo.season
        } } )
        //console.log("responseUpdatePicksArchive", responseUpdatePicksArchive.data)

        getUpLife()

      } catch (e) {
        console.log("error in Brain UpdatePicksArchive", e)
      }
    }

    async function getUpLife() {
      //console.log("lifetimeData", lifetimeData)
      try {
        const responseUpdateLifetime = await getUpdateLifetime( { data: {
          lifetimeData
        } } )
        //console.log("responseUpdateLifetime", responseUpdateLifetime.data)
        setMessage("Calculations completed successfully")
        setWarning(true)
      } catch (e) {
        console.log("error in Brain UpdateLifetime", e)
      }
    }

    getUp()

    // Save year, week number, and maybe highest weekly scores for users

  } //end of handleSubmit

  const [warning, setWarning] = useState(false);
  const [message, setMessage] = useState('');

  const Warning = () => {
    return(
      <Popup
        open={warning}
        className="addplayer-popup"
        onClose={() => navigate("/#/standings")} 
        modal
      >
        {close => (
          <div className="fcmodal">
            <button className="close" onClick={close}>
              X
            </button>
            <div className="header cdeepskyblue bold">SUCCESS</div>
            <div className="content">
              {' '}
              <span className="nooutline cdeepskyblue">{message}</span>
            </div>
          </div>
        )}
      </Popup>
    )
  }

  return (
    <div className="fcolumn">
      <Warning/>
      { matchupsData && actualsData && picksData && recordsData && usersData && lifetimeData && allData ?  
        <div className="col-sm-12 controls">
          <button id="btn-login" type="submit" className="btn btn-danger btn-sm" onClick={handleCalculating}>Calculate</button>
        </div>
      :
        <div>Loading...</div>
      }
    </div>
  )
}

export default Brain