import React, { useState, useEffect } from 'react'
import useAxios from 'axios-hooks'
import CFDDrivesTable from './CFDDrivesTable'
import { useFormik } from 'formik';
import * as Yup from 'yup';

const CFDDrives = () => {

  const [schools, setSchools] = useState([]);
  const [drivesData, setDrivesData] = useState([])

  const validationSchema = Yup.object( {
    week: Yup.number()
      .required('Required'),
    year: Yup.number()
      .required('Required'),
    team: Yup.string()
      .required('Required')
  } )

  const formik = useFormik({
    initialValues: {
      year: '',
      week: '',
      team: ''
    },
    onSubmit: values => {

      async function getPost() {
        try {
          const response = await getInfo( { params: { year: values.year, week: values.week, team: values.team } })
          //console.log("response", response.data)
          setDrivesData(response.data)

        } catch (e) {
          console.log("error in CFDDrives", error)
        }
      }    
      getPost()
    },
    validationSchema
  })

  const [{ data: cfdData, loading: isPending, error }, getInfo] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/cfd/drives`,
    },
    {
      manual: true,
      useCache:false
    }
  )

  const [{ data: schoolData, loading: getLoading, error: getError }, getSchools] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/getschools`,
    },
    {
      manual: true,
      useCache:false
    }
  )

  const setup = () => {

    async function getSchoolData() {
      try {
        const response = await getSchools()
        //console.log("response", response)
        setSchools(response.data)

      } catch (e) {
        console.log("getSchools error called", error)
      }
    }    
    getSchoolData()
  }

  useEffect(() => {
    setup()
  }, []);

  if(schools.length<1) {
    return(<div>LOADING...</div>)
  } else {
    return (

      <div className="container">
        { drivesData.length > 0 && <CFDDrivesTable data={drivesData} /> }

        <div className="explain jcenter">Data goes back to 2001</div>
        <div className="explain">Select Team, Year and Week</div>
          
        <form className="needs-validation  jcenter mtop20" onSubmit={formik.handleSubmit}>

          <div className="row jcenter">
            
            <div className="form-control w180">
              <label>Team :</label>
              <select
                className="form-control"
                name="team"
                value={formik.values.team}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                {schools.map(({school}, index) => (
                <option key={index} value={school}>{school}</option>
                ))}
              </select>
              {formik.touched.team && formik.errors.team ? <div className='errors'>{formik.errors.team}</div> : null}
            </div>

            <div className="form-control w80 mleft5">
              <label>Year :</label>
              <input
                type="text"
                className="form-control"
                id="floatingInput1"
                value={formik.values.year}
                placeholder="2022"
                name="year"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.year && formik.errors.year ? <div className='errors'>{formik.errors.year}</div> : null}
            </div>
          
            <div className="form-control w80 mleft5">
              <label>Week:</label>
              <input
                type="text"
                className="form-control"
                id="floatingInput1"
                value={formik.values.week}
                placeholder="1"
                name="week"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.week && formik.errors.week ? <div className='errors'>{formik.errors.week}</div> : null}
            </div>
          </div>
          
          <div className="cfdsubmit">
            <button id="btn-login" type="submit" className="btn btn-dark btn-sm">Submit</button>
          </div>
        
        </form>
      </div>
    )
  }
}

export default CFDDrives