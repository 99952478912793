import React from 'react'

function CFDRecordsTable({ data }) {

  //console.log("data: ", data)

  // Render the UI for your table
  return (
    <div>
      { data.length > 0 &&
        <div className="boxcenter">

          {data.map((gData, index) => {
            //console.log(dateFormat((gData.startDate), "dddd, mmmm dS, h:MM TT"));
            return(
              <div className="recordsbox">

                <div className="scoresheader2">
                  <div className="scorescolumn">
                    <div className="mleft5 mright5">{ gData.team } <span className="mleft5 cdarkgray f11"> ({ gData.conference }&nbsp;-&nbsp;{ gData.division })</span></div>
                  </div>
                  <div className="scorescolumnr">
                    <div className="mright5">{ gData.year }</div>
                  </div>
                </div>
                
                <div className="recordsrow lefty">
                  <div className="scorescolumn">
                    <div className="mleft5">Record ({ gData.total.wins }-{ gData.total.losses }-{ gData.total.ties })</div>

                    <div className="mleft5">Conference Record ({ gData.conferenceGames.wins }-{ gData.conferenceGames.losses }-{ gData.conferenceGames.ties })</div>

                    <div className="mleft5">Home Record ({ gData.homeGames.wins }-{ gData.homeGames.losses }-{ gData.homeGames.ties })</div>

                    <div className="mleft5">Away Record ({ gData.awayGames.wins }-{ gData.awayGames.losses }-{ gData.awayGames.ties })</div>
                  </div>
                </div>

              </div>
            )
          })}
        </div>
      }
    </div>
  )
}

export default CFDRecordsTable