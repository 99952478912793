import React from "react";


const Rules = () => {

  return (
    <div className="rules">       
      <h1 className="tcenter">FOOL'S FOOTBALL CHALLENGE</h1>
      <p>The goal of the Fool's Football Challenge is to accurately predict the winner in ten selected college football games.</p>
      <p>Each week ten games will be selected and participants in the Fool's Football Challenge will be given a number of days to submit their predictions for each of the game's outcomes. All submissions must be received by the prediction deadline posted in the top left corner of the website. Points will then be rewarded each Sunday based on how well you predicted the outcomes.</p>
      <h2>HOW TO PLAY</h2>
      <ol>
          <li>If you have not already registered then register to play using the 'Register' menu option at the top right of the page.</li>
          <li>Sign In using your username and password. Passwords are encrypted and can not be retrieved so please remember your password.</li>
          <li>Submit your predictions by choosing the 'Submit Picks' menu option.</li>
          <li>Make your predictions. You should see a selection of ten games which will be played in the next week. Select the team you think will win each game by ticking the box next to that team. You will receive <span className="bold cBlue">one point</span> for correct predictions and <span className="bold cRed">zero points</span> for incorrect predictions. Additionally, you start each week with <span className="bold cBlue">20 points</span>.</li>
          <li><span className="bold cBlue">Confidence Picks</span> - If you are especially sure of your prediction for a particular game you can check the confidence box for that game. You earn <span className="bold cBlue">an additional point</span> for accurate predictions when you select confidence but you <span className="bold cRed">lose two points</span> for wrong predictions.</li>
          <li>When you are finished making your predictions select the 'Submit' button at the bottom of the Slate of Games.</li>
          <li>Shortly after the submission deadline passes you can check to see what others have predicted by selecting the '<span className="bold cBlue">Predictions</span>' button.</li>
          <li>Finally, on Sunday when results are tallied and posted you can see how well you did by selecting the '<span className="bold cBlue">Standings</span>' button.</li>
      </ol>
      <h2>SCORING SUMMARY</h2>
      <ol> 
          <li>Each player receives <span className="bold cBlue">20 points</span> for free each week they participate.</li>
          <li>Additionally, they receive <span className="bold cBlue">one point</span> for each accurate prediction.</li>
          <li>If a player checked the confidence box when making their prediction for a particular match they receive <span className="bold cBlue">one additional point</span> for each correct prediction but <span className="bold cRed">lose two points</span> for incorrect predictions.</li>
          <li>If <span className="bold cRed">70% or more</span> of the participants selected a particular team to win, then the other team is considered an underdog. Any person accurately predicting an underdog to win receives <span className="bold cBlue">one additional point</span>.</li>
          <li>After all points have been awarded then <span className="bold cBlue">an additional point</span> will be rewarded to the player with the <span className="bold cBlue">highest score</span> that week. In case of a tie each player involved in the tie will receive the <span className="bold cBlue">bonus point</span>.</li>
      </ol>
      <h2>HOW RANKINGS ARE DETERMINED</h2>
      <p>Weekly rankings and the eventual year end standings will be determined using a Bayesian formula. The reason the Bayesian formula is used is to create a system that rewards consistently high weekly scores while preventing the results from being totally thrown off by an unusually lucky week of predictions.</p>
      <h2>WHY USE A BAYESIAN FORMULA? or I DON'T UNDERSTAND WHY WE NEED A BAYESIAN FORMULA</h2>
      <p>If someone plays just one week and happens to score a perfect week by accurately predicting each game and also selecting the confidence button for each game, they could total a score of 40 points. Additionally they could receive up to ten more points depending on how many underdogs they accurately selected to win. This highly improbable example could result in 50 points plus one additional point for winning the week for a total of 51 points. If that player were to never play another week his final average would be 51 points per week. No one could ever beat that score and the point of playing in this fun competition would be diminished greatly. The incentive for the player with the 51 point average would also be diminished if his goal was to simply win the year end competition. Using a Bayesian formula this scenario would be prevented from occurring because the more weeks a player plays the stronger his average is considered to be. In other words his average is considered to be more reflective of the player's skill. A player who plays only 1 week out of 10 suffers a penalty for having what is considered a weak average since it represents only one out of ten possible results. This fairly prevents 'one hit wonders' from throwing off the ranking system with their weakly established yet high average.</p>
      <h2 className="bold">THE BAYESIAN FOMULA:</h2>
      <p className="cRed">weighted rank (WR) = (w / (w+m)) * R + (m / (w+m)) * C</p>
      <span>where:</span>
      <ul>
          <li><span className="cRed">R</span> = player's average weekly score for the year (mean) = (Rating)</li>
          <li><span className="cRed">w</span> = number of weeks player played</li>
          <li><span className="cRed">m</span> = minimum number of weeks players are expected to play per year (currently 1/3 of total weeks since start of the season)</li>
          <li><span className="cRed">C</span> = the mean of all players (24 is the mean used currently)</li>
      </ul>
      <h2>STATS</h2>
      <p>Each week results are compiled and saved. Hence we have STATISTICS! Players can view a variety of stats by using the stats menu. Statistics are tracked based on most recent week and on a yearly total basis. Most categories should be self explanatory.</p>
      <h2>TERMS</h2>
      <ul>
          <li><span className="bold cBlue">Accuracy Percentage</span> - This is the percentage of correct game predictions for each player. (Formula: Percentage = Total Games Correctly Predicted/Total Games Predicted)</li>
          
          <li><span className="bold cBlue">Confidence</span> - Selecting Confidence when making your predictions means that you are particularly sure (confident) in your pick. If you are correct you are rewarded with a bonus point but if you are wrong you lose two points.</li>
            
          <li><span className="bold cBlue">Player of the Week (shown as Wins)</span> - The player, or players in case of a tie, with the highest score each week. Player of the Week honors earn one bonus point.</li>
            
          <li><span className="bold cBlue">Ranking</span> - Player Rankings are calculated based on the Bayesian formula (see notes on Bayesian formula). The year end champion will be determined by the player with the highest Bayesian score (ranking).</li>
            
          <li><span className="bold cBlue">Underdogs</span> - In a game where one team has been predicted to win by 70% or more of all players making predictions that week, the opponent of that heavily favored team is considered a Fool's Football Challenge Underdog. If the underdog wins that game then any person who picked the underdog to win receives one additional bonus point on top of any other points they may earn for picking the underdog.</li>
      </ul>
    </div>
  )
}

export default Rules