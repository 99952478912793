import React, { useState, useEffect } from 'react'
import useAxios from 'axios-hooks'
import FCSortTable from './FCSortTable';

const CFDVenues = () => {

  const [venues, setVenuesData] = useState([]);

  const [{ data: venueData, loading: getLoading, error: error }, getVenues] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/cfd/venues`,
    },
    {
      manual: true,
      useCache:false
    }
  )

  const setup = () => {

    async function getPost() {
      try {
        const response = await getVenues()
        console.log("response", response.data)
        setVenuesData(response.data)

      } catch (e) {
        console.log("error in CFDVenue", error)
      }
    }
    getPost()
  }

  useEffect(() => {
    setup()
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Venues",
        hstyle: {
        fontSize: '20px',
        textAlign: 'center',
        borderRight: '1px solid black',
        },
          columns: [
            {
              Header: 'Name',
              hclass: "th blackHeader noElip",
              accessor: 'name',
              width: 75,
              className: "td tright",
            },
            {
              Header: 'Capacity',
              hclass: "th blackHeader noElip",
              accessor: 'capacity',
              width: 30,
              className: "td tcenter",
            },
            {
              Header: 'Grass',
              hclass: "th blackHeader noElip",
              accessor: 'grass',
              width: 20,
              className: "td borderr",
            },
            {
              Header: 'City',
              hclass: "th blackHeader noElip",
              accessor: 'city',
              width: 35,
              className: "td",
            },
            {
              Header: 'State',
              hclass: "th blackHeader noElip",
              accessor: 'state',
              width: 18,
              className: "td",
            },
            {
              Header: 'Elevation',
              hclass: "th blackHeader noElip",
              accessor: 'elevation',
              width: 25,
              className: "td",
            },
            {
              Header: 'Built',
              hclass: "th blackHeader noElip",
              accessor: 'yearConstructed',
              width: 25,
              className: "td",
            },
            {
              Header: 'Dome',
              hclass: "th blackHeader noElip",
              accessor: 'dome',
              width: 20,
              className: "td",
            }
          ],
        }
      ],
    []
  )

  if(venues.length<1) {
    return(<div>LOADING...</div>)
  } else {
    return (

      <div className="cfd">
        { error ? <div>{ error }</div> : <FCSortTable columns={columns} data={venues} /> }
      </div>
    )
  }
}

export default CFDVenues