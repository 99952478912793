import React from 'react'
import dateFormat from 'dateformat'
import { IoMdAmericanFootball } from "react-icons/io";

var ordinal = require('ordinal')

function CFDPlaysTable({ data }) {

  console.log("data: ", data)

  // Render the UI for your table
  return (
    <div className="boxscores">
      { data.length > 0 &&
        
        <div className="playbyplay bgBeige">
          <div>{dateFormat((data[0].wallclock), "ddd mmm dd yyyy h:MM:ss TT Z")}</div>

          {data.map((gData, index) => {
            return(
              <>
                {gData.playNumber !== 1 ? 
                  <div className="plays mtop7">
                    <div><span className="cRed">Time: { gData.clock.minutes }:{ (gData.clock.seconds).toLocaleString('en-US', {minimumIntegerDigits: 2}) } </span> (Q{gData.period }) -- {ordinal(gData.down) } and { gData.distance } at the { gData.yardLine } </div>
                    <div>{ gData.offense } <IoMdAmericanFootball /> - { gData.playType } -- <span className="cBlue">{ gData.playText }</span> - <span className="cGreen">Yards gained { gData.yardsGained }</span></div>
                  </div>
                :
                  <div>
                    <div className="bold f18 mtop20">{ gData.offense } { gData.offenseScore } vs { gData.defense } { gData.defenseScore }</div>
                    <div><span className="cRed">Time: { gData.clock.minutes }:{ gData.clock.seconds} </span> (Q{gData.period}) -- {ordinal(gData.down) } and { gData.distance } at the { gData.yardLine } </div>
                    <div>{ gData.offense } <IoMdAmericanFootball /> - { gData.playType } -- <span className="cBlue">{ gData.playText }</span> - <span className="cGreen">Yards gained { gData.yardsGained }</span></div>
                  </div>
                }

              </>
            )
          })} 
        </div>
      }
    </div>
  )
}

export default CFDPlaysTable