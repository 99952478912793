import React, { useState, useEffect } from 'react'
import useAxios from 'axios-hooks'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const PlayerArchive = () => {


  var p // picks counter
  var m // matchups counter
  var lostGameTemp = 0
  var teamWonTemp = []
  var teamWinsTemp = null
  var teamLossesTemp = null
  var playerPicksTemp = []
  var playerPickedTemp = []
  var playerPlayedTemp = null
  var playerPickedThemTemp = null
  var playerPickedOppTemp = null
  var playerCorrectTemp = null
  var playerWrongTemp = null
  var confidencedCorrectTemp = null
  var confidencedWrongTemp = null
  var teamAwayWinTemp = null
  var teamHomeWinTemp = null
  var teamAwayLossTemp = null
  var teamHomeLossTemp = null
  var [lostGame, setLostGame] = useState(0)
  var [matchupsMaster, setMatchupsMaster] = useState([])
  var [teamWon, setTeamWon] = useState([])
  var [teamWins, setTeamWins] = useState(0)
  var [teamLosses, setTeamLosses] = useState(0)
  var [playerPicks, setPlayerPicks] = useState([])
  var [playerPicked, setPlayerPicked] = useState([])
  var [playerPlayed, setPlayerPlayed] = useState(0)
  var [playerPickedThem, setPlayerPickedThem] = useState(0)
  var [playerPickedOpp, setPlayerPickedOpp] = useState(0)
  var [playerCorrect, setPlayerCorrect] = useState(0)
  var [playerWrong, setPlayerWrong] = useState(0)
  var [confidencedCorrect, setConfidencedCorrect] = useState(0)
  var [confidencedWrong, setConfidencedWrong] = useState(0)
  var [teamAwayWin, setTeamAwayWin] = useState(0)
  var [teamHomeWin, setTeamHomeWin] = useState(0)
  var [teamAwayLoss, setTeamAwayLoss] = useState(0)
  var [teamHomeLoss, setTeamHomeLoss] = useState(0)
  var [pickTendency, setPickTendency] = useState(0)
  var [playerName, setPlayerName] = useState('')
  var [playerID, setPlayerID] = useState(0)
  var [teamName, setTeamName] = useState('')
  var [teamID, setTeamID] = useState(0)
  var [schools, setSchools] = useState([])
  var [players, setPlayers] = useState([])
  var [show, setShow] = useState(false)

  const validationSchema = Yup.object( {
    team: Yup.string()
      .required('Required'),
    player: Yup.number()
      .required('Required')
  } )

  const formik = useFormik({
    initialValues: {
      team: '',
      player: ''
    },
    onSubmit: values => {
      setShow(false)
      //console.log("submitted:", formik, values.team, values.player)
      setPlayerName(players[formik.values.player-1].user_name)
      setPlayerID(formik.values.player-1)
      setTeamName(schools[formik.values.team].school)
      setTeamID(formik.values.team)

      getMatchupsData(values, dataMatchups => {
        //console.log("datamatchups",dataMatchups);
        setMatchupsMaster(dataMatchups)
        
        getActualsData(dataMatchups, formik.values.team, callbackActuals => {
          getPlayedData(dataMatchups, formik.values.team, formik.values.player, callbackPlayed => {
            getPicksData(dataMatchups, formik.values.team, formik.values.player, callbackPicks => {
              //console.log("final data", dataMatchups, playerPicksTemp, playerPickedTemp)
              
              //compare results of all matchups with all actual results and all player picks for those matchups
              // Set all results to zero
              playerPlayedTemp=0
              playerPickedThemTemp=0
              playerPickedOppTemp=0
              playerCorrectTemp=0
              playerWrongTemp=0
              teamWinsTemp=0
              teamLossesTemp=0
              lostGameTemp=0
              confidencedCorrectTemp=0
              confidencedWrongTemp=0
              teamAwayWinTemp=0
              teamHomeWinTemp=0
              teamAwayLossTemp=0
              teamHomeLossTemp=0

              // loop through all matchups 
              for(m=0; m<dataMatchups.length; m++) {

                // Find if team won that week (length will be 1 if they did; zero if they did not)
                if(teamWonTemp[m].length===1) {
                  //console.log("team won")

                  // Loop through all playerPicks to find if they played
                  for(p=0; p<playerPickedTemp.length; p++) {
                    //find playerpick for that year and week
                    if(dataMatchups[m].year === playerPickedTemp[p].year && dataMatchups[m].week === playerPickedTemp[p].week && playerPickedTemp[p].pick !== 'n/a' && playerPickedTemp[p].pick !== 'N/A') {
                      teamWinsTemp += 1
                      //check if team was home or away
                      if(dataMatchups[m].teamA===formik.values.team){
                        teamAwayWinTemp +=1
                      } else {
                        teamHomeWinTemp +=1
                      }

                      playerPlayedTemp += 1

                      if(playerPickedTemp[p].pick === formik.values.team) {  // team won and player picked them to win
                        playerPickedThemTemp += 1
                        playerCorrectTemp += 1
                        if(playerPickedTemp[p].confidence === 1) {
                          confidencedCorrectTemp +=1
                        }
                      } else if( playerPickedTemp[p].pick !== 'n/a' && playerPickedTemp[p].pick !== 'N/A') {  // team won but player did not pick them
                        playerPickedOppTemp += 1
                        playerWrongTemp += 1
                        if(playerPickedTemp[p].confidence === 1) {
                          confidencedWrongTemp +=1
                        }
                      }
                    }
                  } // end picks p loop

                } else {  // else team Lost
                  //console.log("team loss")

                  // Loop through all playerPicks to find if they played
                  for(p=0; p<playerPickedTemp.length; p++) {
                    //find playerpick for that year and week
                    if(dataMatchups[m].year === playerPickedTemp[p].year && dataMatchups[m].week === playerPickedTemp[p].week && playerPickedTemp[p].pick !== 'n/a' && playerPickedTemp[p].pick !== 'N/A') {
                      teamLossesTemp += 1
                      //check if team was home or away
                      if(dataMatchups[m].teamA===formik.values.team){
                        teamAwayLossTemp +=1
                      } else {
                        teamHomeLossTemp +=1
                      }

                      playerPlayedTemp += 1

                      if(playerPickedTemp[p].pick === formik.values.team) {  // team lost bit player picked them to win
                        playerPickedThemTemp += 1
                        playerWrongTemp += 1
                        if(playerPickedTemp[p].confidence === 1) {
                          confidencedWrongTemp +=1
                        }
                      } else if( playerPickedTemp[p].pick !== 'n/a' && playerPickedTemp[p].pick !== 'N/A') {  // team lost and player did not pick them
                        playerPickedOppTemp += 1
                        playerCorrectTemp += 1
                        if(playerPickedTemp[p].confidence === 1) {
                          confidencedCorrectTemp +=1
                        }
                      }
                    }
                  } // end picks p loop

                }  // end of team won or lost if

              }  // end of m matchups loop
              setLostGame(lostGameTemp)
              setPlayerPlayed(playerPlayedTemp)
              setPlayerPickedThem(playerPickedThemTemp)
              setPlayerPickedOpp(playerPickedOppTemp)
              setPlayerCorrect(playerCorrectTemp)
              setPlayerWrong(playerWrongTemp)
              setTeamWins(teamWinsTemp)
              setTeamLosses(teamLossesTemp)
              setTeamWon(teamWonTemp)
              setPlayerPicks(playerPicksTemp)
              setPlayerPicked(playerPickedTemp)
              setConfidencedCorrect(confidencedCorrectTemp)
              setConfidencedWrong(confidencedWrongTemp)
              setTeamAwayWin(teamAwayWinTemp)
              setTeamHomeWin(teamHomeWinTemp)
              setTeamAwayLoss(teamAwayLossTemp)
              setTeamHomeLoss(teamHomeLossTemp)
              setPickTendency(((playerPickedThemTemp/(playerPickedOppTemp+playerPickedThemTemp))*100).toFixed(1))
              setShow(true)
              //console.log("Player Played, Correct, Wrong results:", playerPlayedTemp, playerCorrectTemp, playerWrongTemp)
              //console.log("Team Wins, Losses results:", teamWinsTemp, teamLossesTemp, show)
              //console.log(playerPickedThemTemp, playerPickedOppTemp)
            })
          })
        })

      });
      
    },
    validationSchema
  })

  async function getPlayedData( dataMatchups, team, player, callbackPlayed ) {
    //console.log("in getPlayedData", team, dataMatchups)
    for (const game of dataMatchups) {
      try {
        const response = await getPlayedArchive( { params: {week: game.week, year: game.year, team: team, player: player } } )
        //find and save info for that game only not the other 9 games that week
        var pickNumber = (game.prim % 10)-1
        if(pickNumber === -1) {
          pickNumber = 9
        }

        switch(pickNumber) {
          case 0:
            playerPickedTemp.push( { prim: response.data[0].prim, year: response.data[0].year, week: response.data[0].week, user_id: response.data[0].user_id, pick: response.data[0].pick0, confidence: response.data[0].confidence0 } )
            break;
          case 1:
            playerPickedTemp.push( { prim: response.data[0].prim, year: response.data[0].year, week: response.data[0].week, user_id: response.data[0].user_id, pick: response.data[0].pick1, confidence: response.data[0].confidence1 } )
            break;
          case 2:
            playerPickedTemp.push( { prim: response.data[0].prim, year: response.data[0].year, week: response.data[0].week, user_id: response.data[0].user_id, pick: response.data[0].pick2, confidence: response.data[0].confidence2 } )
            break;
          case 3:
            playerPickedTemp.push( { prim: response.data[0].prim, year: response.data[0].year, week: response.data[0].week, user_id: response.data[0].user_id, pick: response.data[0].pick3, confidence: response.data[0].confidence3 } )
            break;
          case 4:
            playerPickedTemp.push( { prim: response.data[0].prim, year: response.data[0].year, week: response.data[0].week, user_id: response.data[0].user_id, pick: response.data[0].pick4, confidence: response.data[0].confidence4 } )
            break;
          case 5:
            playerPickedTemp.push( { prim: response.data[0].prim, year: response.data[0].year, week: response.data[0].week, user_id: response.data[0].user_id, pick: response.data[0].pick5, confidence: response.data[0].confidence5 } )
            break;
          case 6:
            playerPickedTemp.push( { prim: response.data[0].prim, year: response.data[0].year, week: response.data[0].week, user_id: response.data[0].user_id, pick: response.data[0].pick6, confidence: response.data[0].confidence6 } )
            break;
          case 7:
            playerPickedTemp.push( { prim: response.data[0].prim, year: response.data[0].year, week: response.data[0].week, user_id: response.data[0].user_id, pick: response.data[0].pick7, confidence: response.data[0].confidence7 } )
            break;
          case 8:
            playerPickedTemp.push( { prim: response.data[0].prim, year: response.data[0].year, week: response.data[0].week, user_id: response.data[0].user_id, pick: response.data[0].pick8, confidence: response.data[0].confidence8 } )
            break;
          case 9:
            playerPickedTemp.push( { prim: response.data[0].prim, year: response.data[0].year, week: response.data[0].week, user_id: response.data[0].user_id, pick: response.data[0].pick9, confidence: response.data[0].confidence9 } )
            break;
          default:
            break;
        }

      } catch (e) {
        console.log("PlayedData error called", errorPL)
      }
    }
    //console.log("final playerPicked", playerPickedTemp)
    callbackPlayed()
  }

  async function getPicksData( dataMatchups, team, player, callbackPicks ) {
    //console.log("in getPicksData", team, dataMatchups)
    for (const game of dataMatchups) {
      try {
        const response = await getPicksArchive( { params: {week: game.week, year: game.year, team: team, player: player } } )
        //console.log("ActualsArchive response", response)
        if(response.data.length === 0) {  // meaning played that week but did not pick team to win
          // then we need to insert year and week so we at least know they played that week
          response.data.push({year: game.year, week: game.week, prim: -11})  // prim set to -11 to indicate that player did not pick team to win that week
        }
        //console.log("response data", response.data)
        playerPicksTemp.push(response.data)
      } catch (e) {
        console.log("PicksData error called", errorPA)
      }
    }
    //console.log("final playerPicks", playerPicksTemp, dataMatchupsTemp)
    callbackPicks()
  }

  async function getMatchupsData(values, callbackMatchups) {
    try {
      const response = await getMatchups( { params: { team: values.team } } )
      //console.log("MatchupArchive maData response", response)
      //setMatchupData(response.data)
      callbackMatchups(response.data)
    } catch (e) {
      console.log("MatchupArchive error called", errorMA)
    }
  }

  async function getActualsData( dataMatchups, team, callbackActuals ) {
    //console.log("in getActualsData", team, dataMatchups)
    for (const game of dataMatchups) {
      try {
        const response = await getActualsArchive( { params: {week: game.week, year: game.year, team: team } } )
        //console.log("ActualsArchive response", response)
        teamWonTemp.push(response.data)
      } catch (e) {
        console.log("actualsData error called", errorAA)
      }
    }
    //console.log("final teamWon", teamWon)
    callbackActuals()
  }
  
  /* const [{ data: confData, loading: getLoadingC, error: getErrorC }, getConf] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/archives/confarchive`,
    },
    {
      manual: true,
      useCache:false
    }
  ) */

  const [{ data: maData, loading: isPendingMA, error: errorMA }, getMatchups] = useAxios( {
    url: `${process.env.REACT_APP_HOST}/archives/matchupsarchive`,
    },
    {
      manual: true,
      useCache: false
    }
  )

  const [{ data: aaData, loading: isPendingAA, error: errorAA }, getActualsArchive] = useAxios( {
    url: `${process.env.REACT_APP_HOST}/archives/actualsarchive`,
    },
    {
      manual: true,
      useCache: false
    }
  )

  /* const [{ data: epaData, loading: isPendingEPA, error: errorEPA }, getEveryPicksArchive] = useAxios( {
    url: `${process.env.REACT_APP_HOST}archives/everypicksarchive`,
    },
    {
      manual: true,
      useCache: false
    }
  ) */

  const [{ data: paData, loading: isPendingPA, error: errorPA }, getPicksArchive] = useAxios( {
    url: `${process.env.REACT_APP_HOST}/archives/picksarchive`,
    },
    {
      manual: true,
      useCache: false
    }
  )

  const [{ data: plData, loading: isPendingPL, error: errorPL }, getPlayedArchive] = useAxios( {
    url: `${process.env.REACT_APP_HOST}/archives/played`,
    },
    {
      manual: true,
      useCache: false
    }
  )

  const [{ data: schoolData, loading: getLoadingS, error: getErrorS }, getSchools] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/archives/schoolarchive`,
    },
    {
      manual: true,
      useCache:false
    }
  )

  const [{ data: playerData, loading: getLoadingP, error: getErrorP }, getPlayers] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/user/`,
    },
    {
      manual: true,
      useCache:false
    }
  )

  const setup = () => {

    async function getSchoolData() {
      try {
        const response = await getSchools()
        //console.log("school response", response)
        setSchools(response.data)

        async function getPlayerData() {
          try {
            const response = await getPlayers()
            //console.log("players response", response)
            setPlayers(response.data)
    
          } catch (e) {
            console.log("getPlayers error called", getErrorP)
          }
        }    
        getPlayerData()

      } catch (e) {
        console.log("getSchools error called", getErrorS)
      }
    }    
    getSchoolData()
  }

  useEffect(() => {
    setup()
  }, []);
  

  if(schools.length<1 || players.length<1) {
    return(<div>LOADING...</div>)
  } else {
    return (

      <div className="playerarchive">
        {show &&
          <>
            <table className="gameTable mbottom20">
              <thead className="coachHead f12">
                <tr>
                  <th className="tleft ml5 cyellow">{playerName}</th>
                  <th className="tright mr5 cyellow">{teamName}  Record <span className="cRed">({teamWins}-{teamLosses}) </span></th>
                </tr>
                <tr>
                  <th className="tleft ml5">Games involving <span className="cyellow">{teamName}:</span></th>
                  <th className="tright mr5"><span> Home: </span><span className="cRed">({teamHomeWin}-{teamHomeLoss}) </span><span> Away: </span><span className="cRed">({teamAwayWin}-{teamAwayLoss})</span></th>
                </tr>
                <tr>
                  <th className="tleft ml5">Record <span className="cRed">({playerCorrect}-{playerWrong}) </span><span> Confidence: </span><span className="cRed">({confidencedCorrect}-{confidencedWrong})</span></th>
                  <th className="tright mr5"><span></span></th>
                </tr>
                <tr>
                  <th colSpan="2" className="tleft ml5">The <span className="tleft ml5 cyellow">{playerName}</span> picks <span className="tleft ml5 cyellow">{teamName}</span> to win <span className="cRed">{pickTendency}%</span> of the time.</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan="2">
                    <table className="coachTable">
                      <thead>
                        <tr className="gameHeader">
                          <th className="bgGray">Date</th>
                          <th className="bgGray">Matchup</th>
                          <th className="bgGray">Pick</th>
                          <th className="bgGray">Winner</th>
                        </tr>
                      </thead>
                      <tbody>
                        {matchupsMaster.map((game, index) => {
                          return (
                            playerPicked.map((played, indexp) => {
                              //console.log("game", index, indexp, played.pick, playerPicked)
                              return(
                                game.year === played.year && game.week === played.week && played.pick !== 'n/a' && played.pick !== 'N/A' && 
                                  <tr>
                                    <td>{game.year}</td>
                                    <td>{game.rankA > 0 && game.rankA} {schools[game.teamA].school} {game.location} {game.rankH > 0 && game.rankH} {schools[game.teamH].school}</td>
                                    { played.confidence === 1 ? <td className="bgYellow">{schools[played.pick].school}</td> : <td>{schools[played.pick].school}</td> }
                                    <td>
                                      {teamWon[index].length===1 ?
                                        played.pick===teamID ? <span>{teamName}</span> : <span className="cRed">{teamName}</span>
                                      :
                                        game.teamH === teamID ? 
                                          played.pick === game.teamH ? <span className="cRed">{schools[game.teamA].school}</span> : <span>{schools[game.teamA].school}</span>
                                        : 
                                          played.pick === game.teamA ? <span className="cRed">{schools[game.teamH].school}</span> : <span>{schools[game.teamH].school}</span>
                                      }</td>
                                  </tr>
                                
                              )
                            })
                          )
                        })}
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="mtop20neg lh1"><span className="bgYellow f11">Yellow Background = A Confidence Pick</span></div>
            <div className="cRed f11 lh1 mbottom15">Red Team Name = Pick was Wrong</div>
          </>
        }





        <div className="explain jcenter">Select Player and Team</div>
        <div className="explain jcenter cRed">Please be patient. These are very large database searches. The results can take up to a minute to appear.</div>
          
        <form className="needs-validation jcenter mtop20" onSubmit={formik.handleSubmit}>

          <div className="row jcenter">
            
            <div className="form-control w180">
              <label>Player:</label>
              <select
                className="form-control"
                name="player"
                value={formik.values.player}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Player</option>
                {/* <option value='0'>Everyone</option> */}
                {players.map((player, index) => (
                <option key={index} value={player.user_id}>{player.user_name}</option>
                ))}
              </select>
              {formik.touched.player && formik.errors.player ? <div className='errors'>{formik.errors.player}</div> : null}
            </div>
            
            <div className="form-control w180 mleft5">
              <label>Team:</label>
              <select
                className="form-control"
                name="team"
                value={formik.values.team}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Team</option>
                {/* <option value='200'>American</option>
                <option value='201'>ACC</option>
                <option value='202'>Big Ten</option>
                <option value='203'>Big 12</option>
                <option value='204'>C USA</option>
                <option value='205'>Independent</option>
                <option value='206'>PAC 12</option>
                <option value='207'>MAC</option>
                <option value='208'>Mountain West</option>
                <option value='209'>SEC</option>
                <option value='210'>Sun Belt</option> */}
                {schools.map(({school}, index) => (
                <option key={index} value={index}>{school}</option>
                ))}
              </select>
              {formik.touched.team && formik.errors.team ? <div className='errors'>{formik.errors.team}</div> : null}
            </div>

          </div>
          
          <div className="cfdsubmit">
            <button id="btn-login" type="submit" className="btn btn-dark btn-sm">Submit</button>
          </div>
        
        </form>
      </div>
    )
  }
}

export default PlayerArchive
