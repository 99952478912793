import React, { useState, useEffect } from 'react'
import useAxios from 'axios-hooks'
import CFDScoresTable from './CFDScoresTable'

const CFDScores = () => {

  const [ticker, setTicker] = useState([]);

  const [{ data: tickerData, loading: getLoading, error }, getTicker] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/cfd/scoreboard`,
    },
    {
      manual: true,
      useCache:false
    }
  )

  const setup = () => {

    //console.log("ticker")

    async function getInfo() {
      try {
        const response = await getTicker()
        //console.log("response", response)
        setTicker(response.data)

      } catch (e) {
        console.log("Ticker Error: ", error)
      }
    }    
    getInfo()
  }

  useEffect(() => {
    setup()
  }, []);


  return (

    <div className="container">
      { ticker.length > 0 ? <CFDScoresTable data={ticker} /> : <div className="center">LOADING...</div> }
    </div>
  )
}

export default CFDScores