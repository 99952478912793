import React from 'react'
import FCUsersTable from './UsersTable'
import useAxios from 'axios-hooks'


const Users = () => {

  const [{ data: userData, loading: isPending, error }] = useAxios( {
    url: `${process.env.REACT_APP_HOST}/user`
    },
    {
      useCache:false
    }
  )

  const columns = React.useMemo(
    () => [
      {
        Header: 'id',
        accessor: 'user_id',
        minWidth: 15,
        width: 15,
        hclass: "th blackHeader noElip f12",
        className: "td f12",
      },
      {
        Header: 'First Name',
        accessor: 'first_name',
        minWidth: 30,
        width: 30,
        hclass: "th blackHeader noElip f12",
        className: "td tright f12",
      },
      {
        Header: 'Last Name',
        accessor: 'last_name',
        minWidth: 30,
        width: 40,
        hclass: "th blackHeader noElip f12",
        className: "td f12",
      },
      {
        Header: 'User Name',
        accessor: 'user_name',
        minWidth: 30,
        width: 50,
        hclass: "th blackHeader noElip f12",
        className: "td f12",
      },
      {
        Header: 'Email',
        accessor: 'email',
        minWidth: 30,
        width: 75,
        hclass: "th blackHeader noElip f12",
        className: "td f12",
      },
      {
        Header: 'Password',
        accessor: 'password',
        minWidth: 30,
        width: 65,
        hclass: "th blackHeader noElip f12",
        className: "td f12",
      },
      {
        Header: 'Team',
        accessor: 'favorite',
        minWidth: 15,
        width: 23,
        hclass: "th blackHeader noElip f12",
        className: "td f12",
      }
    ],
    []
  )

  return (
    <div className="editData">
      { error && <div>{ error }</div> }
      { isPending ? <div>Loading...</div> : <FCUsersTable columns={columns} users={userData} /> }
    </div>
  )
}

export default Users