import React from 'react'
import dateFormat from 'dateformat'

function CFDGamesTable({ data }) {

  console.log("data: ", data)

  // Render the UI for your table
  return (
    <div>
      <div className="explain">Home team is on the bottom</div>
      { data.length > 0 &&
        <div className="boxscores">

          {data.map((gData, indexer) => {
            //console.log(dateFormat((gData.startDate), "dddd, mmmm dS, h:MM TT"));
            return(
              <div className="scoresbox">

                <div className="scoresheader2">
                  <div className="scorescolumn">
                    <div className="scoresdate">{ dateFormat((gData.startDate), "dddd, h:MM TT Z") }</div>
                    {/* <div className="scoresperiod">{ gData.period }</div> */}
                  </div>
                </div>
                
                <div className="scoresrow lefty">
                  <div className="scorescolumn">
                    <div className="scoresTeamL">{ gData.awayTeam }</div>
                    <div className="scoresConferenceL">({ gData.awayConference })</div>
                  </div>
                  { gData.awayPoints !==null && <div className="scorescolumn scoresPeriods">{ gData.awayLineScores[0] }</div> }
                  { gData.awayPoints !==null && <div className="scorescolumn scoresPeriods">{ gData.awayLineScores[1] }</div> }
                  { gData.awayPoints !==null && <div className="scorescolumn scoresPeriods">{ gData.awayLineScores[2] }</div> }
                  { gData.awayPoints !==null && <div className="scorescolumn scoresPeriods">{ gData.awayLineScores[3] }</div> }
                  { gData.awayPoints !==null && <div className="scorescolumn scoresTotal">{ gData.awayPoints }</div> }
                </div>

                <div className="scoresrow lefty">
                  <div className="scorescolumn">
                    <div className="scoresTeamL">{ gData.homeTeam }</div>
                    <div className="scoresConferenceL">({ gData.homeConference })</div>
                  </div>
                  { gData.homePoints !==null && <div className="scorescolumn scoresPeriods">{ gData.homeLineScores[0] }</div> }
                  { gData.homePoints !==null && <div className="scorescolumn scoresPeriods">{ gData.homeLineScores[1] }</div> }
                  { gData.homePoints !==null && <div className="scorescolumn scoresPeriods">{ gData.homeLineScores[2] }</div> }
                  { gData.homePoints !==null && <div className="scorescolumn scoresPeriods">{ gData.homeLineScores[3] }</div> }
                  { gData.homePoints !==null && <div className="scorescolumn scoresTotal">{ gData.homePoints }</div> }
                </div>

                <div className="scoresbottom">
                  <div className="scorescolumn">
                    <div className="scoresvenue">{ gData.venue }</div>
                    { gData.attendance !==null && <div className="scoresvenue">Attendance:&nbsp;{ gData.attendance }</div> }
                  </div>
                  <div className="highlights">
                    { gData.highlights !==null && <a className="nounderline cYellow" href={gData.highlights} target="_blank">Highlights</a> }
                    {/* <div className="scoresvenue">{ gData.excitementIndex }</div> */}
                  </div>
                </div>

              </div>
            )
          })}
        </div>
      }
    </div>
  )
}

export default CFDGamesTable