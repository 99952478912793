import React, { useEffect, useState } from 'react';
import Aside from './components/Aside';
import Main from './Main';
import {BrowserRouter as Router, HashRouter, useNavigate } from 'react-router-dom'
import useAxios from 'axios-hooks';
import {sessionStorage} from 'es-storage'
import Auth from "./Auth"


function Layout() {
  const [collapsed, setCollapsed] = useState(false)
  const [image, setImage] = useState(true)
  const [toggled, setToggled] = useState(false)

  const [userInfo, setUserInfo] = useState( { uname: '', uemail: '', ufavorite: -1, ulast: '', ufirst: '', uid: -1 } )
  const [serverInfo, setServerInfo] = useState( { server: 'CLOSED', reveal: 0, week: 0, season: 2022, serverdeadline: 'September 1, 2040 12:00:00'  } )

  //const navigate = useNavigate();

  const handleCollapsedChange = (checked) => {
    setCollapsed(checked)
  }

  const handleImageChange = (checked) => {
    setImage(checked)
  }

  const handleToggleSidebar = (value) => {
    setToggled(value)
  }

  const handleLogout = () => {
    setUserInfo( { uname: '', uemail: '', ufavorite: -1, ulast: '', ufirst: '', uid: -1 } )
    sessionStorage.set('uname', null)
    sessionStorage.set('uemail', null)
    sessionStorage.set('ufavorite', null)
    sessionStorage.set('ulast', null)
    sessionStorage.set('ufirst', null)
    sessionStorage.set('uid', null)
  }

  const handleLogin = (value) => {
    setUserInfo( { uname: value[0].user_name, uemail: value[0].email, ufavorite: value[0].favorite, ulast: value[0].last_name, ufirst: value[0].first_name, uid: value[0].user_id } )
    sessionStorage.set('uname', value[0].user_name)
    sessionStorage.set('uemail', value[0].email)
    sessionStorage.set('ufavorite', value[0].favorite)
    sessionStorage.set('ulast', value[0].last_name)
    sessionStorage.set('ufirst', value[0].first_name)
    sessionStorage.set('uid', value[0].user_id)
    //console.log("session", sessionStorage.get('uemail'))
  }

  const handleServer = (value) => {
    console.log("handleserver",value)
/*     var refresher = false  //this block doesn't work
    if(value.deadline !== serverInfo.serverdeadline || value.server !== serverInfo.server || value.reveal !== serverInfo.reveal || value.week !== serverInfo.week || value.season !== serverInfo.season) {
      refresher = true
      console.log("should be true", refresher)
    } */
    setServerInfo( { serverdeadline: value.deadline, server: value.server, reveal: value.reveal, week: value.week, season: value.season  } )
    sessionStorage.set('serverdeadline', value.deadline)
    sessionStorage.set('server', value.server)
    sessionStorage.set('reveal', value.reveal)
    sessionStorage.set('week', value.week)
    sessionStorage.set('season', value.season)
    //console.log("session server", sessionStorage.get('season'))
  }

  const [{ data: statData, loading: isPending, error }, getServerInfo] = useAxios(
    { url: `${process.env.REACT_APP_HOST}/server` },
    { 
      manual: true,
      useCache: false
    }
  )

  useEffect(() => {
    //console.log("STARTED")
    if(sessionStorage.get('uid') !=='null') {
      setUserInfo( { uname: sessionStorage.get('uname'), uemail: sessionStorage.get('uemail'), ufavorite: sessionStorage.get('ufavorite'), ulast: sessionStorage.get('ulast'), ufirst: sessionStorage.get('ufirst'), uid: sessionStorage.get('uid') } )
      //console.log("userinfo",userInfo)
      //console.log("session uid",sessionStorage.get('uid'))
      Auth.login(() => {
        //history.push("/#/")
      })
    }
    
    async function getPost() {
      try {
        const response = await getServerInfo();
        //////////////////////////////////////////////////////////////////////////console.log("useEffect in layout", response.data)
        //handleServer(response.data)  //Not sure why this doesn't work
        setServerInfo( { serverdeadline: response.data[0].deadline, server: response.data[0].server, reveal: response.data[0].reveal, week: response.data[0].week, season: response.data[0].season } );
        sessionStorage.set('serverdeadline', response.data[0].deadline)
        sessionStorage.set('server', response.data[0].server)
        sessionStorage.set('reveal', response.data[0].reveal)
        sessionStorage.set('week', response.data[0].week)
        sessionStorage.set('season', response.data[0].season)
        //console.log("session server", sessionStorage.get('season'))
      } catch (e) {
        console.log("error in useEffect layout", e)
      }
      }
    getPost();
  }, []);


  return (
    <HashRouter hashType="slash">
      { error && <div>{ error }</div> }
      { isPending ? <div>Loading...</div> : 
        <div className={`app ${toggled ? 'toggled' : ''}`}>
          <Main
            image={image}
            toggled={toggled}
            collapsed={collapsed}
            handleToggleSidebar={handleToggleSidebar}
            handleCollapsedChange={handleCollapsedChange}
            handleImageChange={handleImageChange}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            serverInfo={serverInfo}
            setServerInfo={setServerInfo}
            handleLogin={handleLogin}
            handleLogout={handleLogout}
            handleServer={handleServer}
          />
          <Aside
            image={image}
            collapsed={collapsed}
            toggled={toggled}
            handleToggleSidebar={handleToggleSidebar}
            userInfo={userInfo}
          />
        </div>
      }
    </HashRouter>
  )
}

export default Layout;