import React, { useState } from 'react'
import useAxios from 'axios-hooks'
import FCSortTable from "./FCSortTable"

const SeasonArchive = () => {

  const [{ data: statData, loading: isPending, error }] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/seasonarchive`,
    },
    {
      useCache:false
    }
  )

  const columns = React.useMemo(
    () => [
      {
        Header: "Basic Stats",
        hstyle: {
          fontSize: '20px',
          textAlign: 'center',
          borderRight: '1px solid black',
        },
        columns: [
          {
            Header: '',
            accessor: 'user_name',
            minWidth: 65,
            width: 90,
            hclass: "th blackHeader noElip",
            className: "td tright",
          },
          {
            Header: 'Season',
            accessor: 'season',
            minWidth: 50,
            width: 54,
            hclass: "th blackHeader noElip",
            className: "td",
          },
          {
            Header: 'Ranking',
            accessor: 'ranking',
            minWidth: 30,
            width: 49,
            hclass: "th blackHeader noElip",
            className: "td",
          },
          {
            Header: 'Total Points',
            accessor: 'total_points',
            minWidth: 30,
            width: 49,
            hclass: "th blackHeader noElip",
            className: "td",
          },
          {
            Header: 'Correct Picks',
            accessor: 'correct_picks',
            minWidth: 30,
            width: 44,
            hclass: "th blackHeader noElip",
            className: "td",
          },
          {
            Header: 'Wins',
            accessor: 'wins',
            minWidth: 20,
            width: 44,
            hclass: "th blackHeader noElip",
            className: "td",
          },
          {
            Header: 'Weeks Played',
            accessor: 'weeks_played',
            minWidth: 20,
            width: 44,
            hclass: "th blackHeader noElip",
            className: "td",
          }
        ]
      }
    ],
    []
  )

  const columnsC = React.useMemo(
    () => [
      {
        Header: "Confidence Stats",
        hstyle: {
          fontSize: '20px',
          textAlign: 'center',
          borderRight: '1px solid black',
        },
        columns: [
          {
            Header: '',
            accessor: 'user_name',
            minWidth: 65,
            width: 104,
            hclass: "th blackHeader noElip",
            className: "td tright",
          },
          {
            Header: 'Season',
            accessor: 'season',
            minWidth: 40,
            width: 54,
            hclass: "th blackHeader noElip",
            className: "td",
          },
          
          {
            Header: 'Yearly Confidence Attempts',
            accessor: 'conf_attempts',
            minWidth: 20,
            width: 65,
            hclass: "th blackHeader noElip",
            className: "td",
          },
          {
            Header: 'Yearly Confidences Correct',
            accessor: 'conf_wins',
            minWidth: 20,
            width: 65,
            hclass: "th blackHeader noElip",
            className: "td",
          }
        ]
      }
    ],
    []
  )

  const columnsU = React.useMemo(
    () => [
      {
        Header: "Underdog Stats",
        hstyle: {
          fontSize: '20px',
          textAlign: 'center',
          borderRight: '1px solid black',
        },
        columns: [
          {
            Header: '',
            accessor: 'user_name',
            minWidth: 65,
            width: 80,
            hclass: "th blackHeader noElip",
            className: "td tright",
          },
          {
            Header: 'Season',
            accessor: 'season',
            minWidth: 40,
            width: 42,
            hclass: "th blackHeader noElip",
            className: "td",
          },
          {
            Header: 'Yearly Underdog Attempts',
            accessor: 'underdog_attempts',
            minWidth: 20,
            width: 45,
            hclass: "th blackHeader noElip",
            className: "td",
          },
          {
            Header: 'Yearly Underdogs Correct',
            accessor: 'underdogs',
            minWidth: 20,
            width: 45,
            hclass: "th blackHeader noElip",
            className: "td",
          },
          {
            Header: 'Yearly Underdogs Possible',
            accessor: 'underdogs_possible',
            minWidth: 20,
            width: 45,
            hclass: "th blackHeader noElip",
            className: "td",
          }
        ]
      }
    ],
    []
  )

  function SwitchCase(props) {

    switch (props.value) {

      case 'basic':
        return (
          <div className="BasicStats">
            { error && <div>{ error }</div> }
            { isPending ? <div>Loading...</div> : <FCSortTable
              columns={columns}
              data={statData}
              getCellProps={cellInfo => ({
                style: {
                  backgroundColor: `hsl(${120 * ((120 - cellInfo.value) / 120) * -1 +
                    120}, 100%, 67%)`,
                },
              })}
              getRowProps={row => ({
                style: {
                  background: row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                },
              })}
            /> }
          </div>
        )

        case 'confidence':
          return (
            <div className="ConfidenceStats">
              { error && <div>{ error }</div> }
              { isPending ? <div>Loading...</div> : <FCSortTable
                columns={columnsC}
                data={statData}
                getCellProps={cellInfo => ({
                  style: {
                    backgroundColor: `hsl(${120 * ((120 - cellInfo.value) / 120) * -1 +
                      120}, 100%, 67%)`,
                  },
                })}
                getRowProps={row => ({
                  style: {
                    background: row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                  },
                })}
              /> }
            </div>
          )
      
      case 'underdog':
        return (
          <div className="UnderdogStats">
            { error && <div>{ error }</div> }
            { isPending ? <div>Loading...</div> : <FCSortTable
              columns={columnsU}
              data={statData}
              getCellProps={cellInfo => ({
                style: {
                  backgroundColor: `hsl(${120 * ((120 - cellInfo.value) / 120) * -1 +
                    120}, 100%, 67%)`,
                },
              })}
              getRowProps={row => ({
                style: {
                  background: row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                },
              })}
            /> }
          </div>
        )

        default:
          return (
            <div className="BasicStats">
              { error && <div>{ error }</div> }
              { isPending ? <div>Loading...</div> : <FCSortTable
                columns={columns}
                data={statData}
                getCellProps={cellInfo => ({
                  style: {
                    backgroundColor: `hsl(${120 * ((120 - cellInfo.value) / 120) * -1 +
                      120}, 100%, 67%)`,
                  },
                })}
                getRowProps={row => ({
                  style: {
                    background: row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                  },
                })}
              /> }
            </div>
          )
    }
  }

  const handleOnChange = (e) => {
    setState(e.target.value)
  };

  const [state, setState] = useState('basic');

  return (
    <div className="fcolumn">
      <div className="frow">
        <label className="mr10 mleft5 f13">
          <input
            type='radio'
            value='basic'
            name = 'tabletype'
            defaultChecked
            onChange={handleOnChange}
            />
            {' Basic'}
        </label>
        <label className="mr10 f13">
          <input
            type="radio"
            value='confidence'
            name = 'tabletype'
            onChange={handleOnChange}
            />
            {' Confidences'}
        </label>
        <label className="mr10 f13">
          <input
            type='radio'
            value='underdog'
            name = 'tabletype'
            onChange={handleOnChange}
            />
            {' Underdogs'}
        </label>
      </div>

      <div className="Stats">
        <SwitchCase value={state} />
      </div>
    </div>
  )
}

export default SeasonArchive