import React, { useState } from 'react'
import { useTable } from 'react-table'
import { useNavigate } from "react-router-dom";
import useAxios from 'axios-hooks'
import Popup from "reactjs-popup"

const defaultPropGetter = () => ({})

function SubmitTable ({ 
  columns,
  data,
  userInfo,
  serverInfo,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
 }) {
  
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  const navigate = useNavigate();

  const [{ data: statData, loading: isPending3, error: error3 }, getSeasonInfo] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/seasoncheck`,
      method: 'POST'
    },
    { 
      manual: true,
      useCache: false
    }
  )

  const [{ data: createData, loading: isPending2, error: error2 }, createSeasonInfo] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/createseason`,
      method: 'POST'
    },
    { 
      manual: true,
      useCache: false
    }
  )

  async function getPost() {
    try {
      //console.log("show userInfo", userInfo)
      const response = await getSeasonInfo( { params: { season: serverInfo.season, uid: userInfo.uid } } )
      //console.log("inside getseasoninfo", response.data.length)
      var foundRecord=false
      if(response.data.length>0) {
        // Find correct USER = u for each Pick(u) of the current season records
        var u = 0
        for(u=0; u< response.data.length; u++)
        {
          //console.log("WORKED", response.data[u].user_id)
          if(userInfo.uid === response.data[u].user_id)
          {
            foundRecord = true;
          }
        }
      } 
      if(!foundRecord){
        (async () => {
          try {
            const response2 = await createSeasonInfo( { params: { uid: userInfo.uid, uname: userInfo.uname, season: serverInfo.season } } )
            //console.log("creating current season", response2)

          } catch (error2) {
            console.log("error in createSeasonInfo: ", error2, userInfo, response.data)
          }
        })();
      }

    } catch (error3) {
      console.log("error in getSeasonInfo", error3)
    }
    submitter()
  }

  const [game0, setGame0] = useState(-1);
  const [game1, setGame1] = useState(-1);
  const [game2, setGame2] = useState(-1);
  const [game3, setGame3] = useState(-1);
  const [game4, setGame4] = useState(-1);
  const [game5, setGame5] = useState(-1);
  const [game6, setGame6] = useState(-1);
  const [game7, setGame7] = useState(-1);
  const [game8, setGame8] = useState(-1);
  const [game9, setGame9] = useState(-1);

  const [conf0, setConf0] = useState(false);
  const [conf1, setConf1] = useState(false);
  const [conf2, setConf2] = useState(false);
  const [conf3, setConf3] = useState(false);
  const [conf4, setConf4] = useState(false);
  const [conf5, setConf5] = useState(false);
  const [conf6, setConf6] = useState(false);
  const [conf7, setConf7] = useState(false);
  const [conf8, setConf8] = useState(false);
  const [conf9, setConf9] = useState(false);

  const [message1, setMessage1] = useState('Click on the name of the team you think will win.');
  const [message2, setMessage2] = useState('Clicking twice will select the team with confidence.');

  const [{ data: picksData, loading: isPending, error }, submitPicks] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/submitpicks`,
      method: 'POST'
    },
    { 
      manual: true,
      useCache: false
    }
  )

  const handleSubmit = (e) => {
    e.preventDefault();

    getPost() // check for and setup new season account if not done already
  }

  const submitter = () => {

    // make sure states have updated
    if(game0===-1||game1===-1||game2===-1||game3===-1||game4===-1||game5===-1||game6===-1||game7===-1||game8===-1||game9===-1) {
      return
    } else {
      var confi0 = conf0 ? 1: 0;
      var confi1 = conf1 ? 1: 0;
      var confi2 = conf2 ? 1: 0;
      var confi3 = conf3 ? 1: 0;
      var confi4 = conf4 ? 1: 0;
      var confi5 = conf5 ? 1: 0;
      var confi6 = conf6 ? 1: 0;
      var confi7 = conf7 ? 1: 0;
      var confi8 = conf8 ? 1: 0;
      var confi9 = conf9 ? 1: 0;

      let uid=userInfo.uid

      async function getSubmit() {
        try {
          const response3 = await submitPicks( { params: 
            {
              uid,
              game0,
              game1,
              game2,
              game3,
              game4,
              game5,
              game6,
              game7,
              game8,
              game9,
              confi0,
              confi1,
              confi2,
              confi3,
              confi4,
              confi5,
              confi6,
              confi7,
              confi8,
              confi9
            }
          })

          if(response3.data === "worked") {
            setWMessage('Submitted Successfully')
            setWarning(true);
          } else {
            setWMessage('Error: Picks NOT submitted')
            setWarning(true);
          }

          
  
        } catch (e) {
          console.log("error in submitter", error.response3.data)
        }
      }    
      getSubmit()
    }
  }


  const handleBG = (e) => {
    //console.log("event ", e.target)
    
    if(e.target.tagName ==="BUTTON"  || e.target.tagName === "SPAN" || e.target.tagName === "IMG") {  // make sure it is column zero or two so as to prevent vs/at (column 1)
      
      
      const teamName = e.target.offsetParent.innerText
      let value = null
      const row = parseInt(e.target.offsetParent.attributes.rower.value)
      const team = parseInt(e.target.offsetParent.attributes.id.value)
      if(e.target.tagName==="BUTTON") {
        value = e.target.value
      } else {
        value = parseInt(e.target.parentElement.value)
      }
      //console.log("game: ", row, " team: ", team, " value: ", value)

      switch(row) {
        case 0:
          if (game0===value) {
            if (conf0===true) {
              setConf0(false)
              e.target.offsetParent.style.backgroundColor = "yellow"
            } else {
              setConf0(true)
              e.target.offsetParent.style.backgroundColor = "red"
            }
          } else {
            setConf0(false)
            setGame0(value)
            e.target.offsetParent.style.backgroundColor = "yellow"
            if(team === 0) {
              e.target.offsetParent.nextSibling.nextSibling.style.backgroundColor = "white"
            } else {
              e.target.offsetParent.previousSibling.previousSibling.style.backgroundColor = "white"
            }
          }
          break;
        case 1:
          if (game1===value) {
            if (conf1===true) {
              setConf1(false)
              e.target.offsetParent.style.backgroundColor = "yellow"
            } else {
              setConf1(true)
              e.target.offsetParent.style.backgroundColor = "red"
            }
          } else {
            setConf1(false)
            setGame1(value)
            e.target.offsetParent.style.backgroundColor = "yellow"
            if(team === 0) {
              e.target.offsetParent.nextSibling.nextSibling.style.backgroundColor = "white"
            } else {
              e.target.offsetParent.previousSibling.previousSibling.style.backgroundColor = "white"
            }
          }
          break;
        case 2:
          if (game2===value) {
            if (conf2===true) {
              setConf2(false)
              e.target.offsetParent.style.backgroundColor = "yellow"
            } else {
              setConf2(true)
              e.target.offsetParent.style.backgroundColor = "red"
            }
          } else {
            setConf2(false)
            setGame2(value)
            e.target.offsetParent.style.backgroundColor = "yellow"
            if(team === 0) {
              e.target.offsetParent.nextSibling.nextSibling.style.backgroundColor = "white"
            } else {
              e.target.offsetParent.previousSibling.previousSibling.style.backgroundColor = "white"
            }
          }
          break;
        case 3:
          if (game3===value) {
            if (conf3===true) {
              setConf3(false)
              e.target.offsetParent.style.backgroundColor = "yellow"
            } else {
              setConf3(true)
              e.target.offsetParent.style.backgroundColor = "red"
            }
          } else {
            setConf3(false)
            setGame3(value)
            e.target.offsetParent.style.backgroundColor = "yellow"
            if(team === 0) {
              e.target.offsetParent.nextSibling.nextSibling.style.backgroundColor = "white"
            } else {
              e.target.offsetParent.previousSibling.previousSibling.style.backgroundColor = "white"
            }
          }
          break;
        case 4:
          if (game4===value) {
            if (conf4===true) {
              setConf4(false)
              e.target.offsetParent.style.backgroundColor = "yellow"
            } else {
              setConf4(true)
              e.target.offsetParent.style.backgroundColor = "red"
            }
          } else {
            setConf4(false)
            setGame4(value)
            e.target.offsetParent.style.backgroundColor = "yellow"
            if(team === 0) {
              e.target.offsetParent.nextSibling.nextSibling.style.backgroundColor = "white"
            } else {
              e.target.offsetParent.previousSibling.previousSibling.style.backgroundColor = "white"
            }
          }
          break;
        case 5:
          if (game5===value) {
            if (conf5===true) {
              setConf5(false)
              e.target.offsetParent.style.backgroundColor = "yellow"
            } else {
              setConf5(true)
              e.target.offsetParent.style.backgroundColor = "red"
            }
          } else {
            setConf5(false)
            setGame5(value)
            e.target.offsetParent.style.backgroundColor = "yellow"
            if(team === 0) {
              e.target.offsetParent.nextSibling.nextSibling.style.backgroundColor = "white"
            } else {
              e.target.offsetParent.previousSibling.previousSibling.style.backgroundColor = "white"
            }
          }
          break;
        case 6:
          if (game6===value) {
            if (conf6===true) {
              setConf6(false)
              e.target.offsetParent.style.backgroundColor = "yellow"
            } else {
              setConf6(true)
              e.target.offsetParent.style.backgroundColor = "red"
            }
          } else {
            setConf6(false)
            setGame6(value)
            e.target.offsetParent.style.backgroundColor = "yellow"
            if(team === 0) {
              e.target.offsetParent.nextSibling.nextSibling.style.backgroundColor = "white"
            } else {
              e.target.offsetParent.previousSibling.previousSibling.style.backgroundColor = "white"
            }
          }
          break;
        case 7:
          if (game7===value) {
            if (conf7===true) {
              setConf7(false)
              e.target.offsetParent.style.backgroundColor = "yellow"
            } else {
              setConf7(true)
              e.target.offsetParent.style.backgroundColor = "red"
            }
          } else {
            setConf7(false)
            setGame7(value)
            e.target.offsetParent.style.backgroundColor = "yellow"
            if(team === 0) {
              e.target.offsetParent.nextSibling.nextSibling.style.backgroundColor = "white"
            } else {
              e.target.offsetParent.previousSibling.previousSibling.style.backgroundColor = "white"
            }
          }
          break;
        case 8:
          if (game8===value) {
            if (conf8===true) {
              setConf8(false)
              e.target.offsetParent.style.backgroundColor = "yellow"
            } else {
              setConf8(true)
              e.target.offsetParent.style.backgroundColor = "red"
            }
          } else {
            setConf8(false)
            setGame8(value)
            e.target.offsetParent.style.backgroundColor = "yellow"
            if(team === 0) {
              e.target.offsetParent.nextSibling.nextSibling.style.backgroundColor = "white"
            } else {
              e.target.offsetParent.previousSibling.previousSibling.style.backgroundColor = "white"
            }
          }
          break;
        case 9:
          if (game9===value) {
            if (conf9===true) {
              setConf9(false)
              e.target.offsetParent.style.backgroundColor = "yellow"
            } else {
              setConf9(true)
              e.target.offsetParent.style.backgroundColor = "red"
            }
          } else {
            setConf9(false)
            setGame9(value)
            e.target.offsetParent.style.backgroundColor = "yellow"
            if(team === 0) {
              e.target.offsetParent.nextSibling.nextSibling.style.backgroundColor = "white"
            } else {
              e.target.offsetParent.previousSibling.previousSibling.style.backgroundColor = "white"
            }
          }
          break;
        default:
          break;
      }



      /* var statMaker = function () {

        //alert("paAdapter:  " + JSON.stringify(paAdapter.records));
        //alert("teamAdapter:  " + JSON.stringify(teamAdapter.records));

        var records = paAdapter.records;
        var length = records.length;
        var Trecords = teamAdapter.records;
        var Urecords = usAdapter.records;

        //alert(JSON.stringify(Trecords));
        //alert(length);

        if(length === 0) {

          //alert("zero");

          // Display Stats
          $("#line1").html('&nbsp' );

          $("#line2").html('<span id="redPop">NO DATA AVAILABLE</span>');

          $("#line3").html('&nbsp');

          $("#line4").html('&nbsp');

          $("#line5").html('<span id="redPop">REASON:</span>');

          $("#line6").html('&nbsp');

          $("#line7").html('This is your first time picking a game involving:');

          $("#line8").html('&nbsp');

          $("#line9").html('<span id="bluePop">' + Trecords[teamNo].school + '</span>');		

          $("#line10").html('&nbsp');	

        } else {

          // statDisplay compile Begin					
          var percentCorrect = ((records[0].correctPicks/length)*100).toFixed(0);
          var percentPickedWin = ((records[0].timesPicked/length)*100).toFixed(0);
          var percentCorrectConf;
          if(records[0].correctConf == 0) {
            percentCorrectConf = 0;
          } else {
            percentCorrectConf = ((records[0].correctConf/(records[0].correctConf+records[0].incorrectConf))*100).toFixed(0);
          }

          // Display Stats
          $("#line1").html('You pick <span id="bluePop">' + Trecords[records[0].searchID].school + '</span> to win <span id="redPop">' + percentPickedWin + '%</span> of the time');

          $("#line2").html('&nbsp');

          $("#line3").html('Your record in games involving &nbsp<span id="bluePop">' + Trecords[records[0].searchID].school + '</span>:');

          $("#line4").html('&nbsp<span id="redPop">(' + records[0].correctPicks + '-' + records[0].incorrectPicks + ') &nbsp' + percentCorrect + '%</span> &nbsp&nbsp&nbsp&nbsp&nbspConfidence: <span id="redPop">(' + records[0].correctConf + '-' + records[0].incorrectConf + ')</span> &nbsp<span id="redPop">' + percentCorrectConf + '%</span>');

          $("#line5").html('&nbsp');

          $("#line6").html('&nbsp');

          $("#line7").html('<span id="bluePop">' + Trecords[records[0].searchID].school + '&apos;s</span> record in the Football Challenge:');

          $("#line8").html('&nbsp');

          $("#line9").html('Overall Record: &nbsp<span id="redPop">(' + records[0].wins + '-' + (records[0].games-records[0].wins) + ')</span>');		

          $("#line10").html('Home Record: &nbsp<span id="redPop">(' + records[0].homeWins + '-' + (records[0].homeLosses) + ')</span> &nbsp&nbsp&nbsp Away Record: &nbsp<span id="redPop">(' + records[0].awayWins + '-' + (records[0].awayLosses) + ')</span>');	

          // statDisplay compile End								
        } */

      
      if(e.target.offsetParent.style.backgroundColor==="yellow") {
        setMessage1("You are picking " + teamName + " to win.")
        setMessage2("Click on them again to pick them with confidence.")
      } else {
        setMessage1("You are picking " + teamName + " to win with confidence!")
        setMessage2("Click on them again to pick them without confidence.")
      }
    } else {
      return
    }
  }

  const [warning, setWarning] = useState(false);
  const [wmessage, setWMessage] = useState('');

  const Warning = () => {
    return(
      <Popup
        open={warning}
        className="addplayer-popup"
        onClose={() => navigate("/#/")} 
        modal
      >
        {close => (
          <div className="fcmodal">
            <button className="close" onClick={close}>
              X
            </button>
            <div className="header cRed bold">NOTICE</div>
            <div className="content">
              {' '}
              <span className="nooutline cyellow">{wmessage}</span>
            </div>
          </div>
        )}
      </Popup>
    )
  }


  // Render the UI for your table
  return (
    <div className="fcolumn">
      <Warning/>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps([
                  {
                    className: column.hclassName,
                    style: column.hstyle,
                  },
                  getColumnProps(column),
                  getHeaderProps(column),
                ])}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, m) => {
                  return <td id={m} rower={i} onClick={handleBG} {...cell.getCellProps([
                    {
                      className: 'cell.column.className',
                      style: cell.column.style,
                    },
                    getColumnProps(cell.column),
                    getCellProps(cell),
                    
                  ])}
                  >{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>

      <div id="pickTop">
        <p id="pickText">
          {message1}
        </p>
        <p id="pickText">
          {message2}
        </p>
      </div>
      <div id="pickBottom">
        <button id="btn-pick" type="submit" className="btn btn-dark btn-sm" onClick={handleSubmit}>Submit</button>
      </div>
      
    </div>
  )
}

export default SubmitTable