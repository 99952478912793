import React, { useState, useEffect } from 'react'
import useAxios from 'axios-hooks'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from "react-router-dom"
import Popup from 'reactjs-popup'

const GameSelection = () => {

  const [schools, setSchools] = useState([])
  const [matchups, setMatchups] = useState([])

  const history = useNavigate()

  const validationSchema = Yup.object( {
    teamH0: Yup.string()
      .required('Required'),
    teamH1: Yup.string()
      .required('Required'),
    teamH2: Yup.string()
      .required('Required'),
    teamH3: Yup.string()
      .required('Required'),
    teamH4: Yup.string()
      .required('Required'),
    teamH5: Yup.string()
      .required('Required'),
    teamH6: Yup.string()
      .required('Required'),
    teamH7: Yup.string()
      .required('Required'),
    teamH8: Yup.string()
      .required('Required'),
    teamH9: Yup.string()
      .required('Required'),
  } )

  const formik = useFormik({
    initialValues: {
      teamH0: '',
      teamH1: '',
      teamH2: '',
      teamH3: '',
      teamH4: '',
      teamH5: '',
      teamH6: '',
      teamH7: '',
      teamH8: '',
      teamH9: '',

      teamA0: '',
      teamA1: '',
      teamA2: '',
      teamA3: '',
      teamA4: '',
      teamA5: '',
      teamA6: '',
      teamA7: '',
      teamA8: '',
      teamA9: '',

      location0: 'at',
      location1: 'at',
      location2: 'at',
      location3: 'at',
      location4: 'at',
      location5: 'at',
      location6: 'at',
      location7: 'at',
      location8: 'at',
      location9: 'at',

      rankH0: '0',
      rankH1: '0',
      rankH2: '0',
      rankH3: '0',
      rankH4: '0',
      rankH5: '0',
      rankH6: '0',
      rankH7: '0',
      rankH8: '0',
      rankH9: '0',

      rankA0: '0',
      rankA1: '0',
      rankA2: '0',
      rankA3: '0',
      rankA4: '0',
      rankA5: '0',
      rankA6: '0',
      rankA7: '0',
      rankA8: '0',
      rankA9: '0'
    },
    onSubmit: values => {
      //console.log(values)

      var matchupsData = []
      var matchupsElement = {}

      matchupsElement.prim=0
      matchupsElement.rankH=values.rankH0
      matchupsElement.teamH=values.teamH0
      matchupsElement.location=values.location0
      matchupsElement.rankA=values.rankA0
      matchupsElement.teamA=values.teamA0
      matchupsData.push(matchupsElement)

      matchupsElement = {}
      matchupsElement.prim=1
      matchupsElement.rankH=values.rankH1
      matchupsElement.teamH=values.teamH1
      matchupsElement.location=values.location1
      matchupsElement.rankA=values.rankA1
      matchupsElement.teamA=values.teamA1
      matchupsData.push(matchupsElement)

      matchupsElement = {}
      matchupsElement.prim=2
      matchupsElement.rankH=values.rankH2
      matchupsElement.teamH=values.teamH2
      matchupsElement.location=values.location2
      matchupsElement.rankA=values.rankA2
      matchupsElement.teamA=values.teamA2
      matchupsData.push(matchupsElement)

      matchupsElement = {}
      matchupsElement.prim=3
      matchupsElement.rankH=values.rankH3
      matchupsElement.teamH=values.teamH3
      matchupsElement.location=values.location3
      matchupsElement.rankA=values.rankA3
      matchupsElement.teamA=values.teamA3
      matchupsData.push(matchupsElement)

      matchupsElement = {}
      matchupsElement.prim=4
      matchupsElement.rankH=values.rankH4
      matchupsElement.teamH=values.teamH4
      matchupsElement.location=values.location4
      matchupsElement.rankA=values.rankA4
      matchupsElement.teamA=values.teamA4
      matchupsData.push(matchupsElement)

      matchupsElement = {}
      matchupsElement.prim=5
      matchupsElement.rankH=values.rankH5
      matchupsElement.teamH=values.teamH5
      matchupsElement.location=values.location5
      matchupsElement.rankA=values.rankA5
      matchupsElement.teamA=values.teamA5
      matchupsData.push(matchupsElement)

      matchupsElement = {}
      matchupsElement.prim=6
      matchupsElement.rankH=values.rankH6
      matchupsElement.teamH=values.teamH6
      matchupsElement.location=values.location6
      matchupsElement.rankA=values.rankA6
      matchupsElement.teamA=values.teamA6
      matchupsData.push(matchupsElement)

      matchupsElement = {}
      matchupsElement.prim=7
      matchupsElement.rankH=values.rankH7
      matchupsElement.teamH=values.teamH7
      matchupsElement.location=values.location7
      matchupsElement.rankA=values.rankA7
      matchupsElement.teamA=values.teamA7
      matchupsData.push(matchupsElement)

      matchupsElement = {}
      matchupsElement.prim=8
      matchupsElement.rankH=values.rankH8
      matchupsElement.teamH=values.teamH8
      matchupsElement.location=values.location8
      matchupsElement.rankA=values.rankA8
      matchupsElement.teamA=values.teamA8
      matchupsData.push(matchupsElement)

      matchupsElement = {}
      matchupsElement.prim=9
      matchupsElement.rankH=values.rankH9
      matchupsElement.teamH=values.teamH9
      matchupsElement.location=values.location9
      matchupsElement.rankA=values.rankA9
      matchupsElement.teamA=values.teamA9
      matchupsData.push(matchupsElement)

      //console.log("matchup form data", matchupsData)
      
      async function postM() {
        try {
          const response = await postMatchups( { data: { matchupsData, schools } } )
          console.log("setting matchups", response.data)
          setMessage("Update Successful")
          setWarning(true)
        } catch (e) {
          console.log("error in gameselection postMatchups", e)
        }
      }
      postM()
    },
    validationSchema
  })

  const [warning, setWarning] = useState(false);
  const [message, setMessage] = useState('');

  const Warning = () => {
    return(
      <Popup
        open={warning}
        className="addplayer-popup"
        onClose={() => history.push("/#/")} 
        modal
      >
        {close => (
          <div className="fcmodal">
            <button className="close" onClick={close}>
              X
            </button>
            <div className="header cdeepskyblue bold">SUCCESS</div>
            <div className="content">
              {' '}
              <span className="nooutline cdeepskyblue">{message}</span>
            </div>
          </div>
        )}
      </Popup>
    )
  }

  const [{ data: mData, loading: isPending, error: error }, postMatchups] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/selected`,
      method: 'POST'
    },
    {
      manual: true,
      useCache:false
    }
  )

  const [{ data: schoolData, loading: getLoadingSchools, error: getErrorSchools }, getSchools] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/getschools`,
    },
    {
      manual: true,
      useCache:false
    }
  )

  const [{ data: matchupsData, loading: mPending, error: mError }, getMatchups] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/matchup`,
    },
    {
      manual: true,
      useCache:false
    }
  )

  async function getSchoolData() {
    try {
      const responseSchool = await getSchools()
      //console.log("schools", responseSchool)
      setSchools(responseSchool.data)
      
      async function getMatchupData() {
        try {
          const responseMatchup = await getMatchups()
          //console.log("matchups", responseMatchup)
          setMatchups(responseMatchup.data)
          
          //setTimeout(2000)
    
        } catch (e) {
          console.log("Error getting matchups in selection matchups", e)
        }
      }
      getMatchupData();

    } catch (e) {
      console.log("Error getting schools in selection matchups", e)
    }
  }

  
  useEffect(() => {
    getSchoolData()
  },[]);

  

  if(schools.length<1 || matchups.length<1) {
    return(<div>LOADING...</div>)
  } else {
    //console.log("formik", formik)

    formik.initialValues.teamH0 = matchups[0].teamH
    formik.initialValues.teamH1 = matchups[1].teamH
    formik.initialValues.teamH2 = matchups[2].teamH
    formik.initialValues.teamH3 = matchups[3].teamH
    formik.initialValues.teamH4 = matchups[4].teamH
    formik.initialValues.teamH5 = matchups[5].teamH
    formik.initialValues.teamH6 = matchups[6].teamH
    formik.initialValues.teamH7 = matchups[7].teamH
    formik.initialValues.teamH8 = matchups[8].teamH
    formik.initialValues.teamH9 = matchups[9].teamH

    formik.initialValues.teamA0 = matchups[0].teamA
    formik.initialValues.teamA1 = matchups[1].teamA
    formik.initialValues.teamA2 = matchups[2].teamA
    formik.initialValues.teamA3 = matchups[3].teamA
    formik.initialValues.teamA4 = matchups[4].teamA
    formik.initialValues.teamA5 = matchups[5].teamA
    formik.initialValues.teamA6 = matchups[6].teamA
    formik.initialValues.teamA7 = matchups[7].teamA
    formik.initialValues.teamA8 = matchups[8].teamA
    formik.initialValues.teamA9 = matchups[9].teamA

    formik.initialValues.rankH0 = matchups[0].rankH
    formik.initialValues.rankH1 = matchups[1].rankH
    formik.initialValues.rankH2 = matchups[2].rankH
    formik.initialValues.rankH3 = matchups[3].rankH
    formik.initialValues.rankH4 = matchups[4].rankH
    formik.initialValues.rankH5 = matchups[5].rankH
    formik.initialValues.rankH6 = matchups[6].rankH
    formik.initialValues.rankH7 = matchups[7].rankH
    formik.initialValues.rankH8 = matchups[8].rankH
    formik.initialValues.rankH9 = matchups[9].rankH

    formik.initialValues.rankA0 = matchups[0].rankA
    formik.initialValues.rankA1 = matchups[1].rankA
    formik.initialValues.rankA2 = matchups[2].rankA
    formik.initialValues.rankA3 = matchups[3].rankA
    formik.initialValues.rankA4 = matchups[4].rankA
    formik.initialValues.rankA5 = matchups[5].rankA
    formik.initialValues.rankA6 = matchups[6].rankA
    formik.initialValues.rankA7 = matchups[7].rankA
    formik.initialValues.rankA8 = matchups[8].rankA
    formik.initialValues.rankA9 = matchups[9].rankA

    formik.initialValues.location0 = matchups[0].location
    formik.initialValues.location1 = matchups[1].location
    formik.initialValues.location2 = matchups[2].location
    formik.initialValues.location3 = matchups[3].location
    formik.initialValues.location4 = matchups[4].location
    formik.initialValues.location5 = matchups[5].location
    formik.initialValues.location6 = matchups[6].location
    formik.initialValues.location7 = matchups[7].location
    formik.initialValues.location8 = matchups[8].location
    formik.initialValues.location9 = matchups[9].location
    
    return (

      <div className="gameSelection">
        <Warning/>
        { schools ?
          

          <form className="needs-validation  jcenter mtop20" onSubmit={formik.handleSubmit}>

            <div className="row jcenter">
              <input
                type="text"
                className="form-control w30 p2 tcenter"
                id="floatingInput1"
                value={formik.values.rankA0}
                placeholder=""
                name="rankA0"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.rankA0 && formik.errors.rankA0 ? <div className='errors'>{formik.errors.rankA0}</div> : null}

              <select
                className="form-control w140 p2"
                name="teamA0"
                value={formik.values.teamA0}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                {schools.map(({school}, index) => (
                  <option key={index} value={index}>{school}</option>
                ))}
              </select>
              {formik.touched.teamA0 && formik.errors.teamA0 ? <div className='errors'>{formik.errors.teamA0}</div> : null}
            
              <select
                className="form-control w30 p2 tcenter"
                name="location0"
                value={formik.values.location0}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                <option value='at'>at</option>
                <option value='vs'>vs</option>
              </select>
              {formik.touched.location0 && formik.errors.location0 ? <div className='errors'>{formik.errors.location0}</div> : null}
            
              <input
                type="text"
                className="form-control w30 p2 tcenter"
                id="floatingInput1"
                value={formik.values.rankH0}
                placeholder=""
                name="rankH0"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.rankH0 && formik.errors.rankH0 ? <div className='errors'>{formik.errors.rankH0}</div> : null}
            
              <select
                className="form-control w140 p2"
                name="teamH0"
                value={formik.values.teamH0}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                {schools.map(({school}, index) => (
                  <option key={index} value={index}>{school}</option>
                ))}
              </select>
              {formik.touched.teamH0 && formik.errors.teamH0 ? <div className='errors'>{formik.errors.teamH0}</div> : null}
            </div>

            <div className="row jcenter">
              <input
                type="text"
                className="form-control w30 p2 tcenter"
                id="floatingInput1"
                value={formik.values.rankA1}
                placeholder=""
                name="rankA1"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.rankA1 && formik.errors.rankA1 ? <div className='errors'>{formik.errors.rankA1}</div> : null}
            
              <select
                className="form-control w140 p2"
                name="teamA1"
                value={formik.values.teamA1}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                {schools.map(({school}, index) => (
                  <option key={index} value={index}>{school}</option>
                ))}
              </select>
              {formik.touched.teamA1 && formik.errors.teamA1 ? <div className='errors'>{formik.errors.teamA1}</div> : null}
            
              <select
                className="form-control w30 p2 tcenter"
                name="location1"
                value={formik.values.location1}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                <option value='at'>at</option>
                <option value='vs'>vs</option>
              </select>
              {formik.touched.location1 && formik.errors.location1 ? <div className='errors'>{formik.errors.location1}</div> : null}
            
              <input
                type="text"
                className="form-control w30 p2 tcenter"
                id="floatingInput1"
                value={formik.values.rankH1}
                placeholder=""
                name="rankH1"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.rankH1 && formik.errors.rankH1 ? <div className='errors'>{formik.errors.rankH1}</div> : null}
            
              <select
                className="form-control w140 p2"
                name="teamH1"
                value={formik.values.teamH1}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                {schools.map(({school}, index) => (
                  <option key={index} value={index}>{school}</option>
                ))}
              </select>
              {formik.touched.teamH1 && formik.errors.teamH1 ? <div className='errors'>{formik.errors.teamH1}</div> : null}
            </div>

            <div className="row jcenter">
              <input
                type="text"
                className="form-control w30 p2 tcenter"
                id="floatingInput1"
                value={formik.values.rankA2}
                placeholder=""
                name="rankA2"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.rankA2 && formik.errors.rankA2 ? <div className='errors'>{formik.errors.rankA2}</div> : null}
            
              <select
                className="form-control w140 p2"
                name="teamA2"
                value={formik.values.teamA2}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                {schools.map(({school}, index) => (
                  <option key={index} value={index}>{school}</option>
                ))}
              </select>
              {formik.touched.teamA2 && formik.errors.teamA2 ? <div className='errors'>{formik.errors.teamA2}</div> : null}
            
              <select
                className="form-control w30 p2 tcenter"
                name="location2"
                value={formik.values.location2}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                <option value='at'>at</option>
                <option value='vs'>vs</option>
              </select>
              {formik.touched.location2 && formik.errors.location2 ? <div className='errors'>{formik.errors.location2}</div> : null}
            
              <input
                type="text"
                className="form-control w30 p2 tcenter"
                id="floatingInput1"
                value={formik.values.rankH2}
                placeholder=""
                name="rankH2"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.rankH2 && formik.errors.rankH2 ? <div className='errors'>{formik.errors.rankH2}</div> : null}
            
              <select
                className="form-control w140 p2"
                name="teamH2"
                value={formik.values.teamH2}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                {schools.map(({school}, index) => (
                  <option key={index} value={index}>{school}</option>
                ))}
              </select>
              {formik.touched.teamH2 && formik.errors.teamH2 ? <div className='errors'>{formik.errors.teamH2}</div> : null}
            </div>

            <div className="row jcenter">
              <input
                type="text"
                className="form-control w30 p2 tcenter"
                id="floatingInput1"
                value={formik.values.rankA3}
                placeholder=""
                name="rankA3"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.rankA3 && formik.errors.rankA3 ? <div className='errors'>{formik.errors.rankA3}</div> : null}
            
              <select
                className="form-control w140 p2"
                name="teamA3"
                value={formik.values.teamA3}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                {schools.map(({school}, index) => (
                  <option key={index} value={index}>{school}</option>
                ))}
              </select>
              {formik.touched.teamA3 && formik.errors.teamA3 ? <div className='errors'>{formik.errors.teamA3}</div> : null}
            
              <select
                className="form-control w30 p2 tcenter"
                name="location3"
                value={formik.values.location3}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                <option value='at'>at</option>
                <option value='vs'>vs</option>
              </select>
              {formik.touched.location3 && formik.errors.location3 ? <div className='errors'>{formik.errors.location3}</div> : null}
            
              <input
                type="text"
                className="form-control w30 p2 tcenter"
                id="floatingInput1"
                value={formik.values.rankH3}
                placeholder=""
                name="rankH3"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.rankH3 && formik.errors.rankH3 ? <div className='errors'>{formik.errors.rankH3}</div> : null}
            
              <select
                className="form-control w140 p2"
                name="teamH3"
                value={formik.values.teamH3}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                {schools.map(({school}, index) => (
                  <option key={index} value={index}>{school}</option>
                ))}
              </select>
              {formik.touched.teamH3 && formik.errors.teamH3 ? <div className='errors'>{formik.errors.teamH3}</div> : null}
            </div>

            <div className="row jcenter">
              <input
                type="text"
                className="form-control w30 p2 tcenter"
                id="floatingInput1"
                value={formik.values.rankA4}
                placeholder=""
                name="rankA4"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.rankA4 && formik.errors.rankA4 ? <div className='errors'>{formik.errors.rankA4}</div> : null}
            
              <select
                className="form-control w140 p2"
                name="teamA4"
                value={formik.values.teamA4}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                {schools.map(({school}, index) => (
                  <option key={index} value={index}>{school}</option>
                ))}
              </select>
              {formik.touched.teamA4 && formik.errors.teamA4 ? <div className='errors'>{formik.errors.teamA4}</div> : null}
            
              <select
                className="form-control w30 p2 tcenter"
                name="location4"
                value={formik.values.location4}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                <option value='at'>at</option>
                <option value='vs'>vs</option>
              </select>
              {formik.touched.location4 && formik.errors.location4 ? <div className='errors'>{formik.errors.location4}</div> : null}
            
              <input
                type="text"
                className="form-control w30 p2 tcenter"
                id="floatingInput1"
                value={formik.values.rankH4}
                placeholder=""
                name="rankH4"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.rankH4 && formik.errors.rankH4 ? <div className='errors'>{formik.errors.rankH4}</div> : null}
            
              <select
                className="form-control w140 p2"
                name="teamH4"
                value={formik.values.teamH4}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                {schools.map(({school}, index) => (
                  <option key={index} value={index}>{school}</option>
                ))}
              </select>
              {formik.touched.teamH4 && formik.errors.teamH4 ? <div className='errors'>{formik.errors.teamH4}</div> : null}
            </div>

            <div className="row jcenter">
              <input
                type="text"
                className="form-control w30 p2 tcenter"
                id="floatingInput1"
                value={formik.values.rankA5}
                placeholder=""
                name="rankA5"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.rankA5 && formik.errors.rankA5 ? <div className='errors'>{formik.errors.rankA5}</div> : null}
            
              <select
                className="form-control w140 p2"
                name="teamA5"
                value={formik.values.teamA5}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                {schools.map(({school}, index) => (
                  <option key={index} value={index}>{school}</option>
                ))}
              </select>
              {formik.touched.teamA5 && formik.errors.teamA5 ? <div className='errors'>{formik.errors.teamA5}</div> : null}
            
              <select
                className="form-control w30 p2 tcenter"
                name="location5"
                value={formik.values.location5}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                <option value='at'>at</option>
                <option value='vs'>vs</option>
              </select>
              {formik.touched.location5 && formik.errors.location5 ? <div className='errors'>{formik.errors.location5}</div> : null}
            
              <input
                type="text"
                className="form-control w30 p2 tcenter"
                id="floatingInput1"
                value={formik.values.rankH5}
                placeholder=""
                name="rankH5"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.rankH5 && formik.errors.rankH5 ? <div className='errors'>{formik.errors.rankH5}</div> : null}
            
              <select
                className="form-control w140 p2"
                name="teamH5"
                value={formik.values.teamH5}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                {schools.map(({school}, index) => (
                  <option key={index} value={index}>{school}</option>
                ))}
              </select>
              {formik.touched.teamH5 && formik.errors.teamH5 ? <div className='errors'>{formik.errors.teamH5}</div> : null}
            </div>

            <div className="row jcenter">
              <input
                type="text"
                className="form-control w30 p2 tcenter"
                id="floatingInput1"
                value={formik.values.rankA6}
                placeholder=""
                name="rankA6"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.rankA6 && formik.errors.rankA6 ? <div className='errors'>{formik.errors.rankA6}</div> : null}
            
              <select
                className="form-control w140 p2"
                name="teamA6"
                value={formik.values.teamA6}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                {schools.map(({school}, index) => (
                  <option key={index} value={index}>{school}</option>
                ))}
              </select>
              {formik.touched.teamA6 && formik.errors.teamA6 ? <div className='errors'>{formik.errors.teamA6}</div> : null}
            
              <select
                className="form-control w30 p2 tcenter"
                name="location6"
                value={formik.values.location6}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                <option value='at'>at</option>
                <option value='vs'>vs</option>
              </select>
              {formik.touched.location6 && formik.errors.location6 ? <div className='errors'>{formik.errors.location6}</div> : null}
            
              <input
                type="text"
                className="form-control w30 p2 tcenter"
                id="floatingInput1"
                value={formik.values.rankH6}
                placeholder=""
                name="rankH6"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.rankH6 && formik.errors.rankH6 ? <div className='errors'>{formik.errors.rankH6}</div> : null}
            
              <select
                className="form-control w140 p2"
                name="teamH6"
                value={formik.values.teamH6}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                {schools.map(({school}, index) => (
                  <option key={index} value={index}>{school}</option>
                ))}
              </select>
              {formik.touched.teamH6 && formik.errors.teamH6 ? <div className='errors'>{formik.errors.teamH6}</div> : null}
            </div>

            <div className="row jcenter">
              <input
                type="text"
                className="form-control w30 p2 tcenter"
                id="floatingInput1"
                value={formik.values.rankA7}
                placeholder=""
                name="rankA7"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.rankA7 && formik.errors.rankA7 ? <div className='errors'>{formik.errors.rankA7}</div> : null}
            
              <select
                className="form-control w140 p2"
                name="teamA7"
                value={formik.values.teamA7}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                {schools.map(({school}, index) => (
                  <option key={index} value={index}>{school}</option>
                ))}
              </select>
              {formik.touched.teamA7 && formik.errors.teamA7 ? <div className='errors'>{formik.errors.teamA7}</div> : null}
            
              <select
                className="form-control w30 p2 tcenter"
                name="location7"
                value={formik.values.location7}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                <option value='at'>at</option>
                <option value='vs'>vs</option>
              </select>
              {formik.touched.location7 && formik.errors.location7 ? <div className='errors'>{formik.errors.location7}</div> : null}
            
              <input
                type="text"
                className="form-control w30 p2 tcenter"
                id="floatingInput1"
                value={formik.values.rankH7}
                placeholder=""
                name="rankH7"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.rankH7 && formik.errors.rankH7 ? <div className='errors'>{formik.errors.rankH7}</div> : null}
            
              <select
                className="form-control w140 p2"
                name="teamH7"
                value={formik.values.teamH7}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                {schools.map(({school}, index) => (
                  <option key={index} value={index}>{school}</option>
                ))}
              </select>
              {formik.touched.teamH7 && formik.errors.teamH7 ? <div className='errors'>{formik.errors.teamH7}</div> : null}
            </div>

            <div className="row jcenter">
              <input
                type="text"
                className="form-control w30 p2 tcenter"
                id="floatingInput1"
                value={formik.values.rankA8}
                placeholder=""
                name="rankA8"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.rankA8 && formik.errors.rankA8 ? <div className='errors'>{formik.errors.rankA8}</div> : null}
            
              <select
                className="form-control w140 p2"
                name="teamA8"
                value={formik.values.teamA8}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                {schools.map(({school}, index) => (
                  <option key={index} value={index}>{school}</option>
                ))}
              </select>
              {formik.touched.teamA8 && formik.errors.teamA8 ? <div className='errors'>{formik.errors.teamA8}</div> : null}
            
              <select
                className="form-control w30 p2 tcenter"
                name="location8"
                value={formik.values.location8}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                <option value='at'>at</option>
                <option value='vs'>vs</option>
              </select>
              {formik.touched.location8 && formik.errors.location8 ? <div className='errors'>{formik.errors.location8}</div> : null}
            
              <input
                type="text"
                className="form-control w30 p2 tcenter"
                id="floatingInput1"
                value={formik.values.rankH8}
                placeholder=""
                name="rankH8"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.rankH8 && formik.errors.rankH8 ? <div className='errors'>{formik.errors.rankH8}</div> : null}
            
              <select
                className="form-control w140 p2"
                name="teamH8"
                value={formik.values.teamH8}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                {schools.map(({school}, index) => (
                  <option key={index} value={index}>{school}</option>
                ))}
              </select>
              {formik.touched.teamH8 && formik.errors.teamH8 ? <div className='errors'>{formik.errors.teamH8}</div> : null}
            </div>

            <div className="row jcenter">
              <input
                type="text"
                className="form-control w30 p2 tcenter"
                id="floatingInput1"
                value={formik.values.rankA9}
                placeholder=""
                name="rankA9"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.rankA9 && formik.errors.rankA9 ? <div className='errors'>{formik.errors.rankA9}</div> : null}
            
              <select
                className="form-control w140 p2"
                name="teamA9"
                value={formik.values.teamA9}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                {schools.map(({school}, index) => (
                  <option key={index} value={index}>{school}</option>
                ))}
              </select>
              {formik.touched.teamA9 && formik.errors.teamA9 ? <div className='errors'>{formik.errors.teamA9}</div> : null}
            
              <select
                className="form-control w30 p2 tcenter"
                name="location9"
                value={formik.values.location9}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                <option value='at'>at</option>
                <option value='vs'>vs</option>
              </select>
              {formik.touched.location9 && formik.errors.location9 ? <div className='errors'>{formik.errors.location9}</div> : null}
            
              <input
                type="text"
                className="form-control w30 p2 tcenter"
                id="floatingInput1"
                value={formik.values.rankH9}
                placeholder=""
                name="rankH9"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.rankH9 && formik.errors.rankH9 ? <div className='errors'>{formik.errors.rankH9}</div> : null}
            
              <select
                className="form-control w140 p2"
                name="teamH9"
                value={formik.values.teamH9}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                {schools.map(({school}, index) => (
                  <option key={index} value={index}>{school}</option>
                ))}
              </select>
              {formik.touched.teamH9 && formik.errors.teamH9 ? <div className='errors'>{formik.errors.teamH9}</div> : null}
            </div>

            <div className="cfdsubmit">
              <button id="btn-login" type="submit" className="btn btn-dark btn-sm">Submit</button>
            </div>
          </form>
        :
          <div>Loading...</div>
        }
      </div>
    )
  }
}

export default GameSelection