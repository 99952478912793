import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Auth from "../Auth";
import Popup from "reactjs-popup"

const Logout = ({
  handleLogout
}) => {

  const navigate = useNavigate();

  useEffect(() => {
    Auth.logout(() => {
      setWarning(true);
    });
  },[])

  const [warning, setWarning] = useState(false);

  const Warning = () => {
    return(
      <Popup
        open={warning}
        className="addplayer-popup"
        onClose={() => navigate("/#/", handleLogout())} 
        modal
      >
        {close => (
          <div className="fcmodal">
            <button className="close" onClick={close}>
              X
            </button>
            <div className="header cdeepskyblue bold">SUCCESS</div>
            <div className="content">
              {' '}
              <span className="nooutline cdeepskyblue">You successfully logged out</span>
            </div>
          </div>
        )}
      </Popup>
    )
  }

  return (

    <div>
      <Warning/>
      B-Bye
    </div>
  )
}

export default Logout