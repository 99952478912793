import React from 'react'
import { useTable, useSortBy, useResizeColumns, useFlexLayout } from 'react-table'

const defaultPropGetter = () => ({})

function PredictionTable(
  { columns, 
    data,
    idata,
    mdata,
    submitters,
    server,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  }) {

  var pNames = ""
  for(var i=0; i<submitters.length; i++){
    pNames = pNames + submitters[i]
    if(i < submitters.length - 1){
      pNames = pNames + ', '
    }
  }

    // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
    },
    useSortBy,
    useFlexLayout,
    useResizeColumns
  )

  // console.log("columns: ", columns)
  // console.log("data: ", data)
  // console.log("rows: ", rows)
  // console.log("headerGroups: ", headerGroups)


  // Render the UI for your table
  return (
    <>
      <div {...getTableProps()} className="table">
        <div className="header">
          {headerGroups.map((headerGroup, index) => (
            <div
              {...headerGroup.getHeaderGroupProps({
                // style: { paddingRight: '15px' },
              })}
              className="tr"
            >
              {headerGroup.headers.map(column => (
                <div {...column.getHeaderProps([
                  {
                    className: column.hclass,
                    style: column.hstyle,
                  },
                  getColumnProps(column),
                  getHeaderProps(column),
                  column.getSortByToggleProps()
                ])} /* className="th" */>
                  {column.render('Header',{idata:idata, mdata:mdata})}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  {/* Use column.getResizerProps to hook up the events correctly */}
                  {column.canResize && (
                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${
                        column.isResizing ? 'isResizing' : ''
                      }`}
                    />
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="tbody">
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <div {...row.getRowProps()} className="tr">
                {row.cells.map((cell, m) => {
                    //console.log("m",m, "data", data[i])
                    let cName="td noconfidence"
                    if(m===1 && data[i].confidence0===1) {
                      cName="td confidence"
                    }
                    if(m===2 && data[i].confidence1===1) {
                      cName="td confidence"
                    }
                    if(m===3 && data[i].confidence2===1) {
                      cName="td confidence"
                    }
                    if(m===4 && data[i].confidence3===1) {
                      cName="td confidence"
                    }
                    if(m===5 && data[i].confidence4===1) {
                      cName="td confidence"
                    }
                    if(m===6 && data[i].confidence5===1) {
                      cName="td confidence"
                    }
                    if(m===7 && data[i].confidence6===1) {
                      cName="td confidence"
                    }
                    if(m===8 && data[i].confidence7===1) {
                      cName="td confidence"
                    }
                    if(m===9 && data[i].confidence8===1) {
                      cName="td confidence"
                    }
                    if(m===10 && data[i].confidence9===1) {
                      cName="td confidence"
                    }
                    return (<div id={m} className={cName} {...cell.getCellProps(
                      [
                        {
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        getCellProps(cell),
                      ])}
                      >{cell.render('Cell')}</div>
                    )
                })}
              </div>
            )
          })}
        </div>
      </div>
      {server !== "CLOSED" && 
          <div id="Participants" className="tableText mtop20">
            <div className="tcenter">PREDICTIONS SUBMITTED THIS WEEK BY:</div>
            <div className="tcenter">{pNames}</div>
          </div>
        }
    </>
  )
}

export default PredictionTable