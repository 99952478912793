import React, { useState, useEffect } from 'react'
import { useTable, useSortBy, useResizeColumns, useFlexLayout } from 'react-table'
import { useNavigate } from "react-router-dom"
import useAxios from 'axios-hooks'
import Popup from "reactjs-popup"

// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue)

  const onChange = e => {
    setValue(e.target.value)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value)
  }

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return <input className="noborder" value={value} onChange={onChange} onBlur={onBlur} />
}

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
}

const defaultPropGetter = () => ({})

function Table(
  { columns, 
    data,
    schools,
    updateMyData,
    skipPageReset,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  }) {

    // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
    schools,
    defaultColumn,
    // use the skipPageReset option to disable page resetting temporarily
    autoResetPage: !skipPageReset,
    // updateMyData isn't part of the API, but
    // anything we put into these options will
    // automatically be available on the instance.
    // That way we can call this function from our
    // cell renderer!
    updateMyData,
    },
    useSortBy,
    useFlexLayout,
    useResizeColumns
  )

  const ToolTip = ( { schoolrend, cellrend } ) => {
    //console.log("team name and logo", team, src)
    return (
      <Popup
        trigger={
          <span>{cellrend}</span>
        }
        className="team-popup"
        position='bottom center'
        on={['hover']}
        //arrow={position !== 'center center'}
      ><span className="nooutline">{schoolrend}</span>
      </Popup>
    );
  }

  // console.log("columns: ", columns)
  // console.log("data: ", data)
  // console.log("rows: ", rows)
  // console.log("headerGroups: ", headerGroups)

  // Render the UI for your table
  return (
    <>
      <div {...getTableProps()} className="table mtopbottom5">
        <div className="header">
          {headerGroups.map((headerGroup, index) => (
            <div
              {...headerGroup.getHeaderGroupProps({
                // style: { paddingRight: '15px' },
              })}
              className="tr"
            >
              {headerGroup.headers.map(column => (
                <div {...column.getHeaderProps([
                  {
                    className: column.hclass,
                    style: column.hstyle,
                  },
                  getColumnProps(column),
                  getHeaderProps(column),
                  column.getSortByToggleProps()
                ])} /* className="th" */>
                  {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  {/* Use column.getResizerProps to hook up the events correctly */}
                  {column.canResize && (
                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${
                        column.isResizing ? 'isResizing' : ''
                      }`}
                    />
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="tbody">
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <div {...row.getRowProps()} className="tr">
                {row.cells.map(cell => {
                  return (
                    <div
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        getCellProps(cell),
                      ])}>{ !(cell.column.Header).match('Gm')  ? cell.render('Cell')
                        : <ToolTip schoolrend={schools[parseInt(cell.value)].school} cellrend={ cell.render('Cell') }/> }
                      
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

function PicksTable({ columns, picks, schools }) {

  const [data, setData] = useState(() => picks)
  const [originalData] = useState(data)
  const [skipPageReset, setSkipPageReset] = useState(false)

  const navigate = useNavigate();

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true)
    setData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          }
        }
        return row
      })
    )
  }

  // After data chagnes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  React.useEffect(() => {
    setSkipPageReset(false)
  }, [data])

  // Let's add a data resetter/randomizer to help
  // illustrate that flow...
  const resetData = () => setData(originalData)

  const [{ data: picksData, loading: isPending, error }, getPicksInfo] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/editpicks`,
      method: 'POST'
    },
    { 
      manual: true,
      useCache: false
    }
  )

  const handleClick = () => {

    async function getPost() {
      try {
        const response = await getPicksInfo( { data } )
        //console.log("response", response.data)

      } catch (e) {
        //console.log("error in useEffect Login", e)
      }
    }    
    getPost()
  }

  
  return (
    <>
      <button className="btn btn-dark btn-sm f12" onClick={resetData}>Reset Data</button>
      <Table
        columns={columns}
        data={data}
        schools={schools}
        updateMyData={updateMyData}
        skipPageReset={skipPageReset}
      />
      <button className="btn btn-dark btn-sm f12" onClick={handleClick}>Update Data</button>
    </>
  )
}

export default PicksTable