import React from 'react'
import { useTable } from 'react-table'

const defaultPropGetter = () => ({})

function MatchupsTable({ 
  columns,
  data,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
 }) {
  
    // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  // console.log("columns: ", columns)
  //  console.log("data: ", data)
  //  console.log("rows: ", rows)
  // console.log("headerGroups: ", headerGroups)

  // Render the UI for your table
  return (
    <div className="fcTable">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps([
                  {
                    className: column.hclass,
                    style: column.hstyle,
                  },
                  getColumnProps(column),
                  getHeaderProps(column),
                ])}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, m) => {
                  return <td id={m} {...cell.getCellProps([
                    {
                      className: cell.column.className,
                      style: cell.column.style,
                    },
                    getColumnProps(cell.column),
                    getCellProps(cell),
                  ])}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default MatchupsTable