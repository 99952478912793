import React from "react";
import { useTable, useSortBy } from "react-table";

export default function CFDPollTable({ columns, data, poll, week, season }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
            {
                id: 'rank',
                desc: false
            }
        ]
    }
    },
    useSortBy
  );

  // console.log("extras", poll, week, season)
  // console.log("data", data)
  // console.log("rows", rows)
  // console.log("hgroups", headerGroups)
  // Render the UI for your table
  return (
    <>
      <table className="pollTable" {...getTableProps()}>
        <thead>
          <tr>
            <td colSpan="3" className="ctomato">{ poll }</td>
          </tr>
          <tr>
            <td colSpan="3">{ season } &nbsp; &nbsp; Week: { week }</td>
          </tr>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(
                    {
                      style: column.hstyle,
                    },
                  column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? "sort-desc"
                        : "sort-asc"
                      : ""
                  }
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps([
                      {
                        className: cell.column.className,
                        style: cell.column.style,
                      },
                    ])}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
