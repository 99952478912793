import React from 'react'

function CFDLineTable({ data }) {

  //console.log("data: ", data)

  // Render the UI for your table
  return (
    <div>
      <div className="explain">Home team is on the bottom</div>
      { data.length > 0 &&
        <div className="boxscores">

          {data.map((gData, index) => {
            return(
              <div className="linesbox">

                <div className="scoresheader">
                  <div className="scorescolumn">
                    <div className="scoresclock">{ gData.season }</div>
                  </div>
                  <div className="scorescolumnr">
                    <div className="linesseason">{ gData.seasonType } season</div>
                  </div>
                </div>
                
                <div className="scoresrow lefty">
                  <div className="scorescolumn">
                    <div className="longright">{ gData.awayTeam }</div>
                    <div className="scoresConferenceL">({ gData.awayConference })</div>
                  </div>
                  <div className="scorescolumn scoresPoints">{ gData.awayScore }</div>
                </div>

                <div className="scoresrow lefty">
                  <div className="scorescolumn">
                    <div className="longright">{ gData.homeTeam }</div>
                    <div className="scoresConferenceL">({ gData.homeConference })</div>
                  </div>
                  <div className="scorescolumn scoresPoints">{ gData.homeScore }</div>
                </div>
                {gData.lines.map((gData2, index2) => {
                  return(
                    <div className="linesbottom">
                      <div className="linescolumn1">
                        <div className="w120 tright f11 ctomato capitalize">{ gData2.provider }</div>
                      </div>
                      <div className="linescolumn2">
                        <div className="f11 cpapayawhip">Line: { gData2.formattedSpread } (Open { gData2.spreadOpen })</div>
                        <div className="f11 cpapayawhip">Over/Under:&nbsp;{ gData2.overUnder } (Open&nbsp;{ gData2.overUnderOpen })</div>
                        <div className="f11 cpapayawhip">Moneyline: Home { gData2.homeMoneyline } &nbsp; Away&nbsp;{ gData2.awayMoneyline }</div>
                      </div> 
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      }
    </div>
  )
}

export default CFDLineTable