import React, { useState } from 'react'
import { useNavigate } from "react-router-dom"
import useAxios from 'axios-hooks'
import Popup from 'reactjs-popup'
import { useFormik } from 'formik'
import * as Yup from 'yup'

var md5 = require('md5')

const niceware = require('niceware')

const validationSchema = Yup.object( {
  email: Yup.string()
    .email('Invalid email format')
    .required('Required')
} )

const PassReset = () => {

  const history = useNavigate()

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    onSubmit: values => {
      //console.log("submitted", values)

      var passphrase = niceware.generatePassphrase(2)
      var codepass = md5(passphrase[0])

          
      async function getPass() {
        try {
          const response = await SendMail( { params: { email: values.email, pass: passphrase, code: codepass } })
          //console.log("returned response")
          setMessage("Reset Password Email Sent")
          setWarning(true)
        } catch (e) {
          console.log("response", passData, isPending, error)
          console.log("error in reset password", e)
        }
      }    
      getPass()
      
    },
    validationSchema
  })

  const [warning, setWarning] = useState(false)
  const [message, setMessage] = useState('')

  const Warning = () => {
    return(
      <Popup
        open={warning}
        className="addplayer-popup"
        onClose={() => history.push("/#/")} 
        modal
      >
        {close => (
          <div className="fcmodal">
            <button className="close" onClick={close}>
              X
            </button>
            <div className="header cdeepskyblue bold">SUCCESS</div>
            <div className="content">
              {' '}
              <span className="nooutline cdeepskyblue">{message}</span>
            </div>
          </div>
        )}
      </Popup>
    )
  }


  const [{ data: passData, loading: isPending, error: error }, SendMail] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/passreset`,
      method: 'POST'
    },
    { 
      manual: true,
      userCache: false
    }
  )


  return (

    <div>
      <Warning/>

      <form id="loginform" className="row g-3 needs-validation" onSubmit={formik.handleSubmit}>
        
        <div className="reset">
          <div className="form-control">
            <label htmlFor="floatingInput">Enter Your Email</label>
            <input
              type="email"
              className="form-control"
              id="floatingInput"
              value={formik.values.email}
              placeholder="email@email.com"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {formik.touched.email && formik.errors.email ? <div className='errors'>{formik.errors.email}</div> : null}
          </div>
        </div>
        
        <div className="cfdsubmit">
          <button className="btn btn-dark btn-sm" type="submit">Reset Password</button>
        </div>
      </form>
    </div>
  )
}

export default PassReset