import React, { useState, useEffect } from 'react'
import CFDRecordsTable from './CFDRecordsTable';
import useAxios from 'axios-hooks'
import { useFormik } from 'formik';
import * as Yup from 'yup';


const CFDRecords = ( { serverInfo } ) => {

  const [recordsData, setRecordsData] = useState([]);
  const [schools, setSchools] = useState([])

  const validationSchema = Yup.object( {
    team: Yup.string()
      .required('Required'),
    year: Yup.number()
      .min(1869)
      .max(serverInfo.season)
      .required('Required')
  } )

  const formik = useFormik({
    initialValues: {
      team: '',
      year: ''
    },
    onSubmit: values => {

      async function getPost() {
        try {
          const response = await getInfo( { params: { team: values.team, year: values.year } } )
          //console.log("response", response.data)
          setRecordsData(response.data)

        } catch (e) {
          console.log("error in useEffect Login", error)
        }
      }    
      getPost()
    },
    validationSchema
  })

  const [{ data: schoolData, loading: getLoading, error: getError }, getSchools] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/getschools`,
    },
    {
      manual: true,
      useCache:false
    }
  )

  const [{ data: cfdData, loading: isPending, error }, getInfo] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/cfd/records`
    },
    { 
      manual: true,
      useCache: false
    }
  )

  const setup = () => {

    async function getSchoolData() {
      try {
        const response = await getSchools()
        //console.log("response", response)
        setSchools(response.data)

      } catch (e) {
        console.log("getSchools error called", error)
      }
    }    
    getSchoolData()
  }

  useEffect(() => {
    setup()
  }, []);

  
  if(schools.length<1) {
    return(<div>LOADING...</div>)
  } else {
    return (

      <div className="cfd">
        { recordsData.length > 0 && <CFDRecordsTable data={recordsData} /> }

        <div className="explain jcenter f16">Data goes back to 1869</div>
        <div className="explain f16">Select Team and Year</div>
          
        <form className="needs-validation  jcenter mtop20" onSubmit={formik.handleSubmit}>

          <div className="row jcenter">
            
            <div className="form-control w180 mright5">
              <label>Team:</label>
              <select
                className="form-control"
                name="team"
                value={formik.values.team}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <option value=''>Select Team</option>
                {schools.map(({school}, index) => (
                <option key={index} value={school}>{school}</option>
                ))}
              </select>
              {formik.touched.team && formik.errors.team ? <div className='errors'>{formik.errors.team}</div> : null}
            </div>
          
            <div className="form-control w80 mleft5">
              <label>Year :</label>
              <input
                type="text"
                className="form-control"
                id="floatingInput1"
                value={formik.values.year}
                placeholder="2022"
                name="year"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.year && formik.errors.year ? <div className='errors'>{formik.errors.year}</div> : null}
            </div>
          </div>
          
          <div className="cfdsubmit">
            <button id="btn-login" type="submit" className="btn btn-dark btn-sm">Submit</button>
          </div>
        
        </form>
      </div>
    )
  }
}

export default CFDRecords