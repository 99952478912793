import React, { useEffect } from 'react'
import Layout from './Layout'
import './styles/App.scss'
import 'bootstrap/dist/css/bootstrap.css'

//console.log("env", process.env.REACT_APP_HOST)

function App() {
  useEffect(() => {
    document.title = "Fool's Football Challenge"
  }, [])

  return (
    <Layout/>
  );
}

export default App;
