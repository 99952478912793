import React from 'react'
import { IoMdAmericanFootball } from "react-icons/io";
import { ProgressBar } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function CFDDrivesTable({ data }) {

  //console.log("data: ", data)

  // Render the UI for your table
  return (
    <div className="boxscores">
      { data.length > 0 &&
        
        <div className="playbyplay bgBeige">
          {data[0].isHomeOffense ? 
            <div className="bold">{data[0].defense} ({data[0].defenseConference}) at {data[0].offense} ({data[0].offenseConference})</div>
          :
            <div className="bold">{data[0].offense} ({data[0].offenseConference}) at {data[0].defense} ({data[0].defenseConference})</div>
          }

          {data.map((gData, index) => {
            return(
              <>
                <div className="drives">
                  {gData.isHomeOffense ?
                    <div className="cRed bold">{gData.startDefenseScore} {gData.defense}  {gData.offense} {gData.startOffenseScore}</div>
                  :
                    <div className="cRed bold">{gData.startOffenseScore} {gData.offense}  {gData.defense} {gData.startDefenseScore}</div>
                  }
                  <div><span className="bold">Time: { gData.startTime.minutes }:{ (gData.startTime.seconds).toLocaleString('en-US', {minimumIntegerDigits: 2}) } (Q{ gData.startPeriod })</span> -- <span className="bold">{ gData.offense } <IoMdAmericanFootball /></span> at { gData.startYardsToGoal>50 ? gData.offense : gData.defense }'s { gData.startYardsToGoal>50 ? 100-gData.startYardsToGoal : gData.startYardsToGoal } yardline </div>
                  <div>Drive Summary: <span className="cBlue">{ gData.plays } plays for { gData.yards }</span> yards to { gData.endYardsToGoal>50 ? gData.offense : gData.defense }'s { gData.endYardsToGoal>50 ? 100-gData.endYardsToGoal : gData.endYardsToGoal } yardline, resulting in a <span className="bold cBlue">{ gData.driveResult }</span></div>
                  <div className="progressBar">
                    
                    { gData.yards > 0 ?
                      <ProgressBar>
                        <ProgressBar striped variant="secondary" now={ 100-gData.startYardsToGoal } label={ 100-gData.startYardsToGoal +" yardLine"} key={1} />
                        <ProgressBar variant="success" now={ gData.yards } label={gData.yards+" yds"} key={2}/>
                      </ProgressBar>
                    :
                      <ProgressBar>
                        <ProgressBar striped variant="secondary" now={ 100-gData.startYardsToGoal + gData.yards } label={ 100-gData.startYardsToGoal +" yardLine"} key={1} />
                        <ProgressBar variant="danger" now={ Math.abs(gData.yards) } label={gData.yards+" yds"} key={2}/>
                      </ProgressBar>
                    }
                  </div>
                </div>
              </>
            )
          })} 
        </div>
      }
    </div>
  )
}

export default CFDDrivesTable