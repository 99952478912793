import React, { useMemo, useState, useEffect } from 'react'
import useAxios from 'axios-hooks'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CFDPollTable from './CFDPollTable'

const CFDPolls = ( { serverInfo } ) => {

  const [polls, setPollsData] = useState([]);

  const validationSchema = Yup.object( {
    week: Yup.number(),
    year: Yup.number()
      .required('Required')
  } )

  const formik = useFormik({
    initialValues: {
      year: '',
      week: ''
    },
    onSubmit: values => {

      async function getPost() {
        //console.log("values", values)
        try {
          const response = await getPolls ( { params: { week: values.week, year: values.year } } )
          //console.log("response", response.data)
          setPollsData(response.data)
  
        } catch (e) {
          console.log("error in CFDPolls", error)
        }
      }
      getPost()
    },
    validationSchema
  })

  const [{ data: pollsData, loading: getLoading, error: error }, getPolls] = useAxios(
    {
      url: `${process.env.REACT_APP_HOST}/cfd/polls`,
    },
    {
      manual: true,
      useCache:false
    }
  )

  const columns = useMemo(
    () => [
      {
        Header: "RK",
        id: "rank",
        hstyle: {
          fontSize: 10,
        },
        accessor: "rank",
        style: {
          textAlign: 'right',
        }
      },
      {
        Header: "TEAM",
        accessor: "school",
        hstyle: {
          textAlign: 'left',
          paddingLeft: '5px',
          fontSize: 10,
        },
        style: {
          textAlign: 'left',
          paddingLeft: '5px',
        },
        className: "tleft pl5",
        Cell: (cellProps) => {
          return(
            cellProps.row.original.firstPlaceVotes > 0 ?
              <span>{cellProps.value} <span className="cgray">({cellProps.row.original.firstPlaceVotes})</span></span>
            :
              <span>{cellProps.value}</span>
          )
        }
      },
      {
        Header: "POINTS",
        accessor: "points",
        hstyle: {
          fontSize: 10,
          textAlign: 'right',
          paddingRight: '3px',
        },
        style: {
          textAlign: 'right',
          paddingRight: '3px',
        },
        className: "tright cgray",
        Cell: (cellProps) => {
          return(
            cellProps.value !== 0 ?
              <span>{cellProps.value}</span>
            :
              <span></span>
          )
        }
      }
    ]
  );


  return(

    <div className="fcolumn">
      <div className="polls">
        { polls.length !== 0 &&
          <>
            {polls.map((weekdata, i) => {
              //console.log("polls", polls)
              return (
                weekdata.polls.map((pdata, j) => {
                  if(pdata.poll === "Coaches Poll" || pdata.poll === "AP Top 25" || pdata.poll === "Playoff Committee Rankings" || pdata.poll === "BCS Standings") {
                    return(
                      <CFDPollTable columns={columns} data={pdata.ranks} poll={pdata.poll} week={weekdata.week} season={weekdata.season}/>
                    )
                  }
                })
              )
            })}
          </>
        }
      </div>

      <div className="cfdForm">

        <div className="explain jcenter mtop20">Data goes back to 1869</div>
        <div className="explain">Select Year (week is optonal)</div>
          
        <form className="needs-validation jcenter mtop20" onSubmit={formik.handleSubmit}>

          <div className="row jcenter">
            
            <div className="form-control w80 mright5">
              <label>Year :</label>
              <input
                type="text"
                className="form-control"
                id="floatingInput1"
                value={formik.values.year}
                placeholder="2022"
                name="year"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.year && formik.errors.year ? <div className='errors'>{formik.errors.year}</div> : null}
            </div>
          
            <div className="form-control w80 mleft5">
              <label>Week :</label>
              <input
                type="text"
                className="form-control"
                id="floatingInput1"
                value={formik.values.week}
                placeholder="1"
                name="week"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.week && formik.errors.week ? <div className='errors'>{formik.errors.week}</div> : null}
            </div>
          </div>
          
            <div className="cfdsubmit">
              <button id="btn-login" type="submit" className="btn btn-dark btn-sm">Submit</button>
            </div>
        
        </form>
      </div>
    </div>
  )
}

export default CFDPolls